import { useState, useCallback } from 'react';

export function useProjectDragAndDrop({ projects, onReorderProjects }) {
    const [draggedProject, setDraggedProject] = useState(null);
    const [draggedOverProject, setDraggedOverProject] = useState(null);

    const handleDragStart = useCallback((e, project) => {
        setDraggedProject(project);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', project.id);
        e.target.style.opacity = '0.5';
    }, []);

    const handleDragOver = useCallback((e, project) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
        setDraggedOverProject(project);
    }, []);

    const handleDrop = useCallback((e, targetProject) => {
        e.preventDefault();
        if (!draggedProject || draggedProject.id === targetProject.id) return;

        const newProjects = [...projects];
        const draggedIndex = newProjects.findIndex(project => project.id === draggedProject.id);
        const targetIndex = newProjects.findIndex(project => project.id === targetProject.id);

        newProjects.splice(draggedIndex, 1);
        newProjects.splice(targetIndex, 0, draggedProject);

        const updatedProjects = newProjects.map((project, index) => ({
            ...project,
            order: index
        }));

        onReorderProjects(updatedProjects);
        setDraggedProject(null);
        setDraggedOverProject(null);
    }, [draggedProject, projects, onReorderProjects]);

    const handleDragEnd = useCallback((e) => {
        e.target.style.opacity = '1';
        setDraggedProject(null);
        setDraggedOverProject(null);
    }, []);

    const getDragStyles = useCallback((project) => {
        if (draggedProject?.id === project.id) {
            return "opacity-50";
        }
        if (draggedOverProject?.id === project.id) {
            const draggedIndex = projects.findIndex(p => p.id === draggedProject?.id);
            const targetIndex = projects.findIndex(p => p.id === project.id);
            return draggedIndex < targetIndex ? "border-b-2 border-blue-500" : "border-t-2 border-blue-500";
        }
        return "";
    }, [draggedProject, draggedOverProject, projects]);

    return {
        handleDragStart,
        handleDragOver,
        handleDrop,
        handleDragEnd,
        getDragStyles
    };
} 
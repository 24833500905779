import { useState } from 'react';
import SettingsModal from '../../Settings/SettingsModal';

const SettingsButton = () => {
    const [isSpinning, setIsSpinning] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = () => {
        setIsSpinning(true);
        setIsModalOpen(true);
        // Reset spinning animation after it completes
        setTimeout(() => setIsSpinning(false), 1000);
    };

    return (
        <>
            <button
                onClick={handleClick}
                className="w-8 h-8 sm:w-10 sm:h-10 rounded base-button transition duration-150 ease-in-out flex items-center justify-center flash-button"
                title="Settings"
            >
                <span className={`material-symbols-outlined text-lg sm:text-xl ${isSpinning ? 'animate-spin-once' : ''}`}>
                    settings
                </span>
            </button>
            <SettingsModal 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
            />
        </>
    );
};

export default SettingsButton;

import React, { useState, useEffect } from 'react';
import { Popper, ClickAwayListener } from '@mui/material';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

const defaultFavoriteIcons = ['work', 'pets', 'science', 'bloodtype', 'pill', 'people', 'circle', 'family_home'];

// Cache for the fetched icons
let cachedIcons = null;

// Function to fetch icons that can be reused
const fetchIconsList = async () => {
    if (cachedIcons) return cachedIcons;
    
    try {
        const response = await fetch('https://raw.githubusercontent.com/google/material-design-icons/master/variablefont/MaterialSymbolsOutlined%5BFILL%2CGRAD%2Copsz%2Cwght%5D.codepoints');
        const text = await response.text();
        cachedIcons = text
            .split('\n')
            .filter(line => line.trim())
            .map(line => line.split(' ')[0]);
        return cachedIcons;
    } catch (error) {
        console.error('Failed to fetch icons:', error);
        return defaultFavoriteIcons;
    }
};

const selectFavoriteIcons = createSelector(
    [(state) => state.settings.settings.favorite_icons],
    (favorite_icons) => {
        return favorite_icons?.length ? favorite_icons : defaultFavoriteIcons;
    }
);

function IconSelector({ anchorEl, open, onClose, onSelect, currentIcon }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [allIcons, setAllIcons] = useState(defaultFavoriteIcons); // Start with defaults
    const [filteredIcons, setFilteredIcons] = useState(defaultFavoriteIcons);
    const [loading, setLoading] = useState(false); // Start false since we have defaults

    const favoriteIcons = useSelector(selectFavoriteIcons);

    // Fetch icons in background without blocking UI
    useEffect(() => {
        let mounted = true;
        
        const loadIcons = async () => {
            setLoading(true);
            const icons = await fetchIconsList();
            if (mounted) {
                setAllIcons(icons);
                setFilteredIcons(searchQuery ? 
                    icons.filter(icon => icon.toLowerCase().includes(searchQuery.toLowerCase())) 
                    : icons
                );
                setLoading(false);
            }
        };

        if (open) {
            loadIcons();
        }

        return () => {
            mounted = false;
        };
    }, [open, searchQuery]);

    // Update filtered icons whenever search query changes
    useEffect(() => {
        if (!searchQuery) {
            setFilteredIcons(allIcons);
            return;
        }
        
        const filtered = allIcons.filter(icon => 
            icon.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredIcons(filtered);
    }, [searchQuery, allIcons]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSelect = (icon) => {
        onSelect(icon);
        onClose();
    };

    const handleClearSearch = () => {
        setSearchQuery('');
    };

    return (
        <Popper 
            open={open} 
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{ zIndex: 9999 }}
            modifiers={[
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        boundary: document.body,
                        altAxis: true,
                        padding: 8
                    },
                },
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
                    },
                },
            ]}
        >
            <ClickAwayListener onClickAway={onClose}>
                <div className="bg-[var(--surface-bg)] border border-[var(--surface-border)] rounded-lg shadow-lg p-4 w-[300px] max-h-[80vh] overflow-y-auto">
                    <div className="mb-4 relative">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearch}
                            placeholder="Search icons..."
                            className="w-full px-3 py-2 bg-[var(--input-bg)] border border-[var(--input-border)] rounded-md focus:outline-none focus:border-[var(--input-border-hover)] pr-8"
                        />
                        {searchQuery && (
                            <button
                                onClick={handleClearSearch}
                                className="absolute right-2 top-1/2 -translate-y-1/2 text-[var(--text-secondary)] hover:text-[var(--text-primary)]"
                            >
                                <span className="material-symbols-outlined text-[20px]">close</span>
                            </button>
                        )}
                    </div>
                    
                    {!searchQuery && (
                        <>
                            <div className="text-[var(--text-secondary)] mb-2">Favorites</div>
                            <div className="grid grid-cols-5 gap-2 mb-4">
                                {favoriteIcons.map((icon) => (
                                    <button
                                        key={`favorite-${icon}`}
                                        onClick={() => handleSelect(icon)}
                                        className={`p-2 rounded-md hover:bg-[var(--button-hover-bg)] flex items-center justify-center ${
                                            currentIcon === icon ? 'bg-[var(--primary-button)] hover:bg-[var(--primary-button-hover)]' : ''
                                        }`}
                                        title={icon}
                                    >
                                        <span className="material-symbols-outlined text-[20px]">{icon}</span>
                                    </button>
                                ))}
                            </div>
                            <div className="text-[var(--text-secondary)] mb-2">All Icons</div>
                        </>
                    )}

                    <div className="grid grid-cols-5 gap-2 max-h-[180px] overflow-y-auto">
                        {loading ? (
                            <div className="col-span-5 text-center py-4 text-[var(--text-secondary)]">Loading icons...</div>
                        ) : (
                            filteredIcons.map((icon) => (
                                <button
                                    key={icon}
                                    onClick={() => handleSelect(icon)}
                                    className={`p-2 rounded-md hover:bg-[var(--button-hover-bg)] flex items-center justify-center ${
                                        currentIcon === icon ? 'bg-[var(--primary-button)] hover:bg-[var(--primary-button-hover)]' : ''
                                    }`}
                                    title={icon}
                                >
                                    <span className="material-symbols-outlined text-[20px]">{icon}</span>
                                </button>
                            ))
                        )}
                    </div>
                </div>
            </ClickAwayListener>
        </Popper>
    );
}

export default IconSelector; 
import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addProject, deleteProject, reorderProjects, reorderProjectsThunk, selectSortedProjects, fetchProjects } from '../../../store/projectsSlice';
import { useProjectDragAndDrop } from '../../../hooks/useProjectDragAndDrop';
import { Popper, Chip, ClickAwayListener, Paper } from '@mui/material';
import { updateSetting } from '../../../store/settingsSlice';

// Import PROJECT_STATUSES from projectsSlice
import { PROJECT_STATUSES } from '../../../store/projectsSlice';

function ProjectsSidebar({ onCollapse }) {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const projects = useSelector(selectSortedProjects);
    const settings = useSelector(state => state.settings.settings);
    
    const [deleteConfirmId, setDeleteConfirmId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('INCOMPLETE');
    const [iconFilter, setIconFilter] = useState('');
    const [statusAnchorEl, setStatusAnchorEl] = useState(null);
    const [iconAnchorEl, setIconAnchorEl] = useState(null);
    const [sortDirection, setSortDirection] = useState(settings?.projectsSortDirection || 'off');

    const statusPriority = useMemo(() => ({
        'IN_PROGRESS': 1,
        'IN_REVIEW': 2,
        'READY_FOR_GO_LIVE': 3,
        'NOT_STARTED': 4,
        'COMPLETED': 5
    }), []);

    const {
        handleDragStart,
        handleDragOver,
        handleDrop,
        handleDragEnd,
        getDragStyles
    } = useProjectDragAndDrop({
        projects,
        onReorderProjects: (reorderedProjects) => {
            const projectsWithOrder = reorderedProjects.map((project, index) => ({
                ...project,
                order: index
            }));
            dispatch(reorderProjects(projectsWithOrder));
            dispatch(reorderProjectsThunk(projectsWithOrder))
                .unwrap()
                .catch(error => {
                    console.error('Failed to save project order:', error);
                    dispatch(fetchProjects());
                });
        }
    });

    const handleCreateProject = async () => {
        const result = await dispatch(addProject({
            name: 'Untitled Project',
            status: 'NOT_STARTED',
            icon: 'folder',
            notes: ''
        }));
        if (result.payload?.id) {
            navigate(`/projects/${result.payload.id}`);
        }
        onCollapse();
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        if (deleteConfirmId === id) {
            dispatch(deleteProject(id));
            setDeleteConfirmId(null);
            if (projectId === id) {
                if (projects.length > 1) {
                    const nextProject = projects.find(p => p.id !== id);
                    if (nextProject) {
                        navigate(`/projects/${nextProject.id}`);
                    }
                } else {
                    navigate('/projects');
                }
            }
        } else {
            setDeleteConfirmId(id);
        }
    };

    const handleCancelDelete = (e) => {
        e.stopPropagation();
        setDeleteConfirmId(null);
    };

    const filteredProjects = useMemo(() => {
        let filtered = projects.filter(project => {
            const matchesSearch = project.name.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesIcon = !iconFilter || project.icon === iconFilter;
            
            const matchesStatus = statusFilter === 'ALL' ? true :
                statusFilter === 'INCOMPLETE' ? project.status !== 'COMPLETED' :
                project.status === statusFilter;

            return matchesSearch && matchesStatus && matchesIcon;
        });

        if (sortDirection !== 'off') {
            filtered = [...filtered].sort((a, b) => {
                const statusDiff = statusPriority[a.status] - statusPriority[b.status];
                
                if (statusDiff === 0) {
                    return sortDirection === 'asc' ? a.order - b.order : b.order - a.order;
                }
                
                return sortDirection === 'asc' ? statusDiff : -statusDiff;
            });
        }

        return filtered;
    }, [projects, sortDirection, searchTerm, iconFilter, statusFilter, statusPriority]);

    const uniqueIcons = [...new Set(projects.map(p => p.icon))];
    const uniqueStatuses = Object.keys(PROJECT_STATUSES);

    const handleSortDirectionChange = () => {
        const newDirection = sortDirection === 'off' ? 'asc' : sortDirection === 'asc' ? 'desc' : 'off';
        setSortDirection(newDirection);
        dispatch(updateSetting({ key: 'projectsSortDirection', value: newDirection }));
    };

    useEffect(() => {
        if (settings?.projectsSortDirection) {
            setSortDirection(settings.projectsSortDirection);
        }
    }, [settings?.projectsSortDirection]);

    return (
        <div className="space-y-2">
            <div className="space-y-2">
                <input
                    type="text"
                    placeholder="Search projects..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full px-3 py-2 text-sm bg-[var(--surface-bg)] focus:border-[var(--text-primary)] focus:outline-none rounded-md text-[var(--text-secondary)] placeholder-[var(--text-disabled)]"
                />

                <div className="flex justify-end">
                    <button
                        onClick={handleSortDirectionChange}
                        className={`px-2 py-1 text-xs ${sortDirection === 'off' ? 'bg-[var(--surface-bg)] hover:bg-[var(--surface-bg-lighter)]' : 'bg-[var(--surface-bg-lighter)]'} rounded-md flex items-center gap-1 ${sortDirection === 'off' ? 'text-[var(--text-muted)] hover:text-[var(--text-secondary)]' : 'text-[var(--text-secondary)]'} flash-button`}
                        title={sortDirection === 'off' ? "Sort by Status" : sortDirection === 'asc' ? "Sort by Status (Ascending)" : "Sort by Status (Descending)"}
                    >
                        <span className="material-symbols-outlined text-[16px]">
                            {sortDirection === 'off' ? "sort" : sortDirection === 'asc' ? "arrow_upward" : "arrow_downward"}
                        </span>
                        Sort by status
                    </button>
                </div>

                <div className="flex gap-2 flex-wrap">
                    <Chip
                        icon={statusFilter === 'INCOMPLETE' || statusFilter === 'ALL' ? null : 
                            <div 
                                className="w-3 h-3 rounded-full" 
                                style={{ 
                                    backgroundColor: `var(--status-${statusFilter.toLowerCase().replace(/_/g, '-')})` 
                                }}
                            />
                        }
                        label={statusFilter === 'INCOMPLETE' ? 'Incomplete' : 
                               statusFilter === 'ALL' ? 'All Statuses' : 
                               PROJECT_STATUSES[statusFilter].label}
                        onClick={(e) => setStatusAnchorEl(e.currentTarget)}
                        sx={{ 
                            color: 'white',
                            border: '1px solid gray',
                            '& .MuiChip-label': { 
                                display: 'block' 
                            },
                            '& .MuiChip-icon': {
                                marginLeft: '8px'
                            }
                        }}
                    />

                    {iconFilter ? (
                        <Chip
                            icon={<span className="material-symbols-outlined" style={{ color: 'white' }}>{iconFilter}</span>}
                            onDelete={() => setIconFilter('')}
                            sx={{ 
                                border: '1px solid white',
                                '& .MuiChip-label': { 
                                    display: 'none' 
                                },
                                '& .MuiChip-deleteIcon': {
                                    color: '#ababab',
                                    fontSize: '16px',
                                    marginLeft: '8px',
                                    marginRight: '4px',
                                    '&:hover': {
                                        color: '#ef4444'
                                    }
                                },
                                '& .MuiChip-icon': {
                                    marginLeft: '8px'
                                }
                            }}
                        />
                    ) : (
                        <Chip
                            label="All Icons"
                            onClick={(e) => setIconAnchorEl(e.currentTarget)}
                            sx={{ 
                                color: 'white',
                                border: '1px solid gray'
                            }}
                        />
                    )}
                </div>

                <Popper 
                    open={Boolean(statusAnchorEl)} 
                    anchorEl={statusAnchorEl} 
                    placement="bottom-start"
                    sx={{ zIndex: 1300 }}
                >
                    <ClickAwayListener onClickAway={() => setStatusAnchorEl(null)}>
                        <Paper 
                            className="p-2 flex flex-col gap-2 border border-[var(--surface-border)]" 
                            sx={{ 
                                bgcolor: 'var(--input-bg)',
                                boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.4), 0 2px 4px -2px rgb(0 0 0 / 0.4)'
                            }}
                        >
                            <div
                                onClick={() => {
                                    setStatusFilter('ALL');
                                    setStatusAnchorEl(null);
                                }}
                                className="flex items-center gap-2 px-2 py-1 rounded cursor-pointer hover:bg-[#323232]"
                            >
                                <span className="text-sm text-gray-200">All Statuses</span>
                            </div>
                            <div
                                onClick={() => {
                                    setStatusFilter('INCOMPLETE');
                                    setStatusAnchorEl(null);
                                }}
                                className="flex items-center gap-2 px-2 py-1 rounded cursor-pointer hover:bg-[#323232]"
                            >
                                <span className="text-sm text-gray-200">Incomplete</span>
                            </div>
                            {uniqueStatuses.map(status => {
                                const statusKey = status.toLowerCase().replace(/_/g, '-');
                                const statusColorVar = `var(--status-${statusKey})`;
                                
                                return (
                                    <div
                                        key={status}
                                        onClick={() => {
                                            setStatusFilter(status);
                                            setStatusAnchorEl(null);
                                        }}
                                        className="flex items-center gap-2 px-2 py-1 rounded cursor-pointer hover:bg-[#323232]"
                                    >
                                        <div
                                            className="w-4 h-4 rounded-full"
                                            style={{ backgroundColor: statusColorVar }}
                                        />
                                        <span className="text-sm text-gray-200">{PROJECT_STATUSES[status].label}</span>
                                    </div>
                                );
                            })}
                        </Paper>
                    </ClickAwayListener>
                </Popper>

                <Popper 
                    open={Boolean(iconAnchorEl)} 
                    anchorEl={iconAnchorEl} 
                    placement="bottom-start"
                    sx={{ zIndex: 1300 }}
                >
                    <ClickAwayListener onClickAway={() => setIconAnchorEl(null)}>
                        <Paper 
                            className="p-2 grid grid-cols-4 gap-2 border border-[var(--surface-border)]"
                            sx={{ 
                                bgcolor: 'var(--input-bg)',
                                boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.4), 0 2px 4px -2px rgb(0 0 0 / 0.4)'
                            }}
                        >
                            {uniqueIcons.length > 0 ? (
                                uniqueIcons.map(icon => (
                                    <div
                                        key={icon}
                                        onClick={() => {
                                            setIconFilter(icon);
                                            setIconAnchorEl(null);
                                        }}
                                        className="p-2 rounded cursor-pointer hover:bg-[var(--surface-hover)] flex items-center justify-center"
                                        title={icon}
                                    >
                                        <span className="material-symbols-outlined text-[var(--text-secondary)]">{icon}</span>
                                    </div>
                                ))
                            ) : (
                                <div className="p-2 text-[var(--text-disabled)] text-sm">None</div>
                            )}
                        </Paper>
                    </ClickAwayListener>
                </Popper>

                <button
                    onClick={handleCreateProject}
                    className="w-full px-3 py-2 text-sm bg-[var(--primary-button)] hover:bg-[var(--primary-button-hover)] rounded-md flex items-center gap-2 justify-center"
                >
                    <span className="material-symbols-outlined text-[20px]">add</span>
                    New Project
                </button>
            </div>

            <div className="space-y-1">
                {filteredProjects.map(project => (
                    <div
                        key={project.id}
                        draggable
                        onDragStart={(e) => handleDragStart(e, project)}
                        onDragOver={(e) => handleDragOver(e, project)}
                        onDrop={(e) => handleDrop(e, project)}
                        onDragEnd={handleDragEnd}
                        className={`relative cursor-move ${getDragStyles(project)}`}
                    >
                        <div className={`
                            flex items-center justify-between rounded-md
                            ${projectId === project.id 
                                ? 'bg-[var(--selection-bg)] text-[var(--text-primary)]'
                                : 'text-[var(--text-secondary)] hover:bg-[var(--surface-bg)]'
                            }
                        `}>
                            <button
                                onClick={() => navigate(`/projects/${project.id}`)}
                                className="flex-1 px-3 py-2 text-left text-sm"
                            >
                                <div className="sidebar-item-content">
                                    <span className="material-symbols-outlined text-[20px]">
                                        {project.icon}
                                    </span>
                                    <span className="break-all">
                                        {project.name}
                                    </span>
                                    {project.status && (
                                        <span 
                                            className="status-indicator" 
                                            style={{ 
                                                backgroundColor: `var(--status-${project.status.toLowerCase().replace(/_/g, '-')})` 
                                            }} 
                                        />
                                    )}
                                </div>
                            </button>
                            {deleteConfirmId === project.id ? (
                                <div className="flex -space-x-2 pr-2">
                                    <button
                                        onClick={(e) => handleDelete(e, project.id)}
                                        className="px-2 py-2 text-[var(--error)] hover:text-[var(--error-hover)]"
                                    >
                                        <span className="material-symbols-outlined text-[20px]">
                                            check
                                        </span>
                                    </button>
                                    <button
                                        onClick={handleCancelDelete}
                                        className="px-2 py-2 text-[var(--text-muted)] hover:text-[var(--text-secondary)]"
                                    >
                                        <span className="material-symbols-outlined text-[20px]">
                                            close
                                        </span>
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={(e) => handleDelete(e, project.id)}
                                    className="px-2 py-2 text-[var(--error)] hover:text-[var(--error-hover)]"
                                >
                                    <span className="material-symbols-outlined text-[20px]">
                                        delete
                                    </span>
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ProjectsSidebar; 
import React from 'react';
import { PROJECT_STATUSES } from '../../store/projectsSlice';
import './StatusSelector.css';

const StatusSelector = ({ currentStatus, onStatusChange }) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  
  const isReviewBranch = (key) => key === 'IN_REVIEW';
  const mainStatuses = Object.entries(PROJECT_STATUSES)
    .filter(([key]) => !isReviewBranch(key));
  
  // Find the index among main statuses (excluding review)
  const currentMainIndex = mainStatuses.findIndex(([key]) => key === currentStatus);
  
  // Calculate progress width to align with node centers
  const getProgressWidth = () => {
    const totalSegments = mainStatuses.length - 1;
    
    // Check if we're on mobile using state
    const isMobile = windowWidth <= 600;
    
    if (isMobile) {
      // For mobile, calculate height instead of width
      if (isReviewBranch(currentStatus)) {
        return '40%'; // Position for review status
      }
      const mainIndex = mainStatuses.findIndex(([key]) => key === currentStatus);
      if (mainIndex >= 0) {
        return `${(mainIndex / totalSegments) * 100}%`;
      }
      return '0';
    }
    
    // Desktop layout
    const maxWidth = windowWidth <= 768 ? 'calc(100% - 120px)' :
                    windowWidth <= 1024 ? 'calc(100% - 128px)' :
                    'calc(100% - 136px)';
                    
    if (isReviewBranch(currentStatus)) {
      return `calc(${maxWidth} * 0.25)`; // Review at 25% of max width
    }
    
    if (currentMainIndex >= 0) {
      if (currentMainIndex === 0) return '0';
      if (currentMainIndex === totalSegments) return maxWidth;
      return `calc(${maxWidth} * ${currentMainIndex / totalSegments})`;
    }
    return '0';
  };

  // Add window resize listener
  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getProgressStyle = () => {
    const isMobile = windowWidth <= 600;
    
    if (isMobile) {
      // Calculate height based on current status
      let height = '0%';
      
      if (isReviewBranch(currentStatus)) {
        height = '20%';
      } else {
        const mainIndex = mainStatuses.findIndex(([key]) => key === currentStatus);
        if (mainIndex >= 0) {
          const heightMap = {
            0: '0%',     // Not Started
            1: '40%',    // In Progress
            2: '60%',    // Ready for Go Live
            3: '80%'     // Complete
          };
          height = heightMap[mainIndex] || '0%';
        }
      }
      
      return {
        height,
        width: '2px'
      };
    }
    
    // Desktop layout
    return {
      '--progress-width': getProgressWidth()
    };
  };

  const handleClick = (event, key) => {
    event.stopPropagation();
    onStatusChange(key);
  };

  return (
    <div className="status-selector">
      <div className="timeline-base" />
      <div 
        className={`timeline-progress ${
          currentStatus === 'NOT_STARTED' ? 'status-not-started' :
          currentStatus === 'IN_PROGRESS' ? 'status-in-progress' :
          currentStatus === 'IN_REVIEW' ? 'status-in-review' :
          currentStatus === 'READY_FOR_GOLIVE' ? 'status-ready-for-golive' :
          'status-completed'
        }`}
        style={getProgressStyle()}
      />
      <div className="status-buttons">
        {mainStatuses.map(([key, { label }]) => (
          <button
            key={key}
            className={`status-button main-branch ${
              currentStatus === key ? 'active' : ''
            } ${
              key === 'NOT_STARTED' ? 'status-not-started' :
              key === 'IN_PROGRESS' ? 'status-in-progress' :
              key === 'IN_REVIEW' ? 'status-in-review' :
              key === 'READY_FOR_GOLIVE' ? 'status-ready-for-golive' :
              'status-completed'
            }`}
          >
            <span 
              className="status-node" 
              onClick={(e) => handleClick(e, key)}
            />
            <span 
              className="status-label"
              onClick={(e) => handleClick(e, key)}
            >
              {label}
            </span>
          </button>
        ))}
        {/* Review branch button */}
        {Object.entries(PROJECT_STATUSES)
          .filter(([key]) => isReviewBranch(key))
          .map(([key, { label }]) => (
            <button
              key={key}
              className={`status-button review-branch ${
                currentStatus === key ? 'active' : ''
              } status-in-review`}
            >
              <span 
                className="status-node"
                onClick={(e) => handleClick(e, key)}
              />
              <span 
                className="status-label"
                onClick={(e) => handleClick(e, key)}
              >
                {label}
              </span>
            </button>
          ))}
      </div>
    </div>
  );
};

export default StatusSelector; 
import { useSelector, useDispatch } from 'react-redux';
import {
  addMeeting,
  deleteMeeting,
  editMeeting,
} from '../store/meetingsSlice';

export function useMeetings() {
  const dispatch = useDispatch();
  const meetings = useSelector(state => state.meetings.items);

  const handlers = {
    handleAddMeeting: (name, date, icon = 'calendar') => {
      dispatch(addMeeting(name, date, icon));
    },
    handleDeleteMeeting: (meetingId) => {
      dispatch(deleteMeeting(meetingId));
    },
    handleEditMeeting: (meetingId, updates) => {
      dispatch(editMeeting({ meetingId, updates }));
    }
  };

  return {
    meetings,
    ...handlers
  };
} 
import React from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import DashboardTabs from './DashboardTabs';
import { useDashboardProps } from '../../App';

function DashboardContent() {
    const { activeTab, collapseHeader, onTabChange } = useDashboardProps();
    const navigate = useNavigate();
    const location = useLocation();

    // Update navigation when tab changes, but only if necessary
    React.useEffect(() => {
        const tabPaths = {
            'projects': '/projects',
            'weekly-planner': '/weekly-planner',
            'meetings': '/meetings',
            'recurring-tasks': '/recurring-tasks'
        };

        const targetPath = tabPaths[activeTab];
        if (targetPath && !location.pathname.includes(targetPath)) {
            navigate(targetPath, { replace: true }); // Use replace to avoid adding to history
        }
    }, [activeTab, navigate, location.pathname]);

    return (
        <main className={`
            bg-[var(--app-bg)]
            text-[var(--text-primary)]
            font-sans 
            flex-1 
            overflow-auto 
            ${collapseHeader ? 'pt-14' : 'pt-24'}
            pb-8
            min-w-[300px]
            ml-[60px]
        `}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <DashboardTabs 
                    activeTab={activeTab} 
                    onTabChange={onTabChange} 
                />
                <div className="bg-[var(--surface-bg)] shadow-lg rounded-lg border border-[var(--surface-border)] overflow-hidden">
                    <div className="p-4 sm:p-6 overflow-x-hidden">
                        <Outlet />
                    </div>
                </div>
            </div>
        </main>
    );
}

export default DashboardContent; 
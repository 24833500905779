import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSetting, fetchThemeData, fetchSettings } from '../../../store/settingsSlice';
import { presetThemes, darkTheme } from '../../../themes/themeInit';
import { themeApi } from '../../../services/api';

const ThemeSettings = () => {
    const dispatch = useDispatch();
    const settings = useSelector((state) => state.settings.settings);
    const themeData = useSelector((state) => state.settings.themeData);
    const [customThemeName, setCustomThemeName] = useState('');
    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const [currentColors, setCurrentColors] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState(null);

    const colorGroups = useMemo(() => ({
        "Base Colors": [
            { key: "app-bg", label: "Application Background" },
        ],
        "Component Surface Colors": [
            { key: "surface-bg", label: "Surface Background" },
            { key: "surface-bg-lighter", label: "Surface Light Background" },
            { key: "surface-bg-darker", label: "Surface Dark Background" },
            { key: "surface-bg-transparent", label: "Surface Transparent Background" },
            { key: "surface-border", label: "Surface Border" }
        ],
        "Interactive Elements": [
            { key: "primary-button", label: "Primary Button" },
            { key: "primary-button-hover", label: "Primary Button Hover State" },
            { key: "primary-button-active", label: "Primary Button Active State" },
            { key: "button-bg", label: "Secondary Button" },
            { key: "button-hover-bg", label: "Secondary Button Hover State" }
        ],
        "Text Input Elements": [
            { key: "input-bg", label: "Input Background" },
            { key: "input-border", label: "Input Border" },
            { key: "input-border-hover", label: "Input Border Hover State" },
            { key: "focus-ring", label: "InputFocus Ring" }
        ],
        "Text Colors": [
            { key: "text-primary", label: "Primary Text Color" },
            { key: "text-secondary", label: "Secondary Text Color" },
            { key: "text-muted", label: "Muted Text Color" },
            { key: "text-disabled", label: "Disabled Text Color" },
            { key: "selection-bg", label: "Text Selection Background" }
        ],
        "Feedback Colors": [
            { key: "error", label: "Error" },
            { key: "error-hover", label: "Error Hover" }
        ],
        "Selection Sliders": [
            { key: "slider-bg", label: "Slider Background" },
            { key: "slider-track", label: "Slider Track" },
            { key: "slider-text", label: "Slider Label" },
            { key: "slider-text-hover", label: "Slider Label Hover State" },
            { key: "slider-text-active", label: "Slider Label Active State" },
        ],
        "Scrollbars": [
            { key: "scrollbar-thumb", label: "Scrollbar Thumb" },
            { key: "scrollbar-thumb-hover", label: "Scrollbar Thumb Hover State" }
        ],
        "Project Status Colors": [
            { key: "status-not-started", label: "'Not Started' Status" },
            { key: "status-in-progress", label: "'In Progress' Status" },
            { key: "status-in-review", label: "'In Review' Status" },
            { key: "status-ready", label: "'Ready for Go Live' Status" },
            { key: "status-completed", label: "'Completed' Status" }
        ]
    }), []);

    // Load saved settings and custom themes on mount
    useEffect(() => {
        const loadInitialData = async () => {
            setLoading(true);
            try {
                await Promise.all([
                    dispatch(fetchSettings()),
                    dispatch(fetchThemeData())
                ]);
            } finally {
                setLoading(false);
            }
        };

        loadInitialData();
    }, [dispatch]);

    // Handle initial theme setup and changes from Redux
    useEffect(() => {
        if (settings?.selected_theme !== undefined) {
            const themeToUse = settings.selected_theme || 'dark'; // Default to dark if undefined/null
            setSelectedTheme(themeToUse);
            
            // Apply the correct theme colors
            const themeToApply = themeToUse
                ? (themeData.userTheme?.theme || presetThemes[themeToUse] || darkTheme)
                : darkTheme;
                
            if (themeToApply && Object.keys(themeToApply).length > 0) {
                setCurrentColors(themeToApply);
                // Update CSS variables
                Object.entries(themeToApply).forEach(([key, value]) => {
                    document.documentElement.style.setProperty(`--${key}`, value);
                });
            }
        }
    }, [settings?.selected_theme, themeData.userTheme]);

    const handleColorChange = async (key, value) => {
        // Ensure the value is a valid color
        let formattedValue = value;
        if (!value.startsWith('#') && !value.startsWith('rgb') && !value.startsWith('rgba')) {
            formattedValue = `#${value.replace(/[^0-9a-fA-F]/g, '')}`;
        }
        
        const newColors = { ...currentColors, [key]: formattedValue };
        
        try {
            // Save to backend first to ensure persistence
            await themeApi.saveUserTheme({
                theme: newColors,
                selectedTheme: selectedTheme
            });

            // Only update local state and UI after successful save
            setCurrentColors(newColors);
            document.documentElement.style.setProperty(`--${key}`, formattedValue);
            
            // Update Redux store last
            await dispatch(updateSetting({ key: `theme_${key}`, value: formattedValue }));
        } catch (error) {
            console.error('Error saving theme:', error);
            throw error;
        }
    };

    const applyTheme = async (theme, themeName = null) => {
        setLoading(true);
        const previousTheme = { ...currentColors };
        const previousSelectedTheme = selectedTheme;

        try {
            const newColors = { ...theme };
            
            // Save to backend first to ensure persistence
            await themeApi.saveUserTheme({
                theme: newColors,
                selectedTheme: themeName
            });

            // Update local state and CSS variables after successful save
            setSelectedTheme(themeName);
            setCurrentColors(newColors);
            Object.entries(newColors).forEach(([key, value]) => {
                document.documentElement.style.setProperty(`--${key}`, value);
            });

            // Update Redux store last
            await Promise.all([
                ...Object.entries(newColors).map(([key, value]) => 
                    dispatch(updateSetting({ key: `theme_${key}`, value }))
                ),
                dispatch(updateSetting({ key: 'selected_theme', value: themeName }))
            ]);

            // Refresh theme data to ensure consistency
            await dispatch(fetchThemeData());
        } catch (error) {
            console.error('Error applying theme:', error);
            // Revert all states on error
            setSelectedTheme(previousSelectedTheme);
            setCurrentColors(previousTheme);
            
            // Revert CSS variables
            Object.entries(previousTheme).forEach(([key, value]) => {
                document.documentElement.style.setProperty(`--${key}`, value);
            });
            throw error; // Let the UI handle the error if needed
        } finally {
            setLoading(false);
        }
    };

    const handleSaveCustomTheme = async () => {
        if (!customThemeName) return;
        
        setLoading(true);
        try {
            // Save to backend
            await themeApi.saveCustomTheme(customThemeName, currentColors);
            
            // Refresh theme data
            await dispatch(fetchThemeData());
            
            setCustomThemeName('');
            setShowSaveDialog(false);
        } catch (error) {
            console.error('Error saving custom theme:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteCustomTheme = async (name) => {
        setLoading(true);
        try {
            await themeApi.deleteCustomTheme(name);
            // Refresh theme data
            await dispatch(fetchThemeData());
        } catch (error) {
            console.error('Error deleting custom theme:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateCustomTheme = async (name) => {
        setLoading(true);
        try {
            // Save current colors to the existing theme name
            await themeApi.saveCustomTheme(name, currentColors);
            
            // Refresh theme data
            await dispatch(fetchThemeData());
            
        } catch (error) {
            console.error('Error updating custom theme:', error);
        } finally {
            setLoading(false);
        }
    };

    const getColorValue = (key) => {
        return currentColors[key] || '#000000';
    };

    return (
        <div className="p-6 min-h-full space-y-8">
            {loading && (
                <div className="fixed inset-0 bg-[var(--surface-bg-transparent)] flex items-center justify-center z-50">
                    <div className="animate-spin rounded-full h-8 w-8 border-2 border-[var(--primary-button)] border-t-transparent"></div>
                </div>
            )}

            {/* Theme Presets */}
            <div className="space-y-4 border border-[var(--surface-border)] rounded-lg p-4">
                <h4 className="text-sm font-bold text-[var(--text-primary)] text-left">Theme Presets</h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {Object.entries(presetThemes).map(([name, theme]) => (
                        <button
                            key={name}
                            onClick={() => applyTheme(theme, name)}
                            className={`theme-preview-button p-4 rounded-lg border-2 transition-all duration-200 ${
                                selectedTheme === name 
                                    ? 'border-[var(--primary-button)] shadow-lg scale-[1.02]' 
                                    : 'border-[var(--surface-border)] hover:border-[var(--primary-button-hover)] hover:scale-[1.01]'
                            }`}
                            style={{
                                background: theme['surface-bg'],
                                color: theme['text-primary']
                            }}
                        >
                            <div className="flex flex-col gap-2">
                                <div className="flex items-center gap-2">
                                    <span className="text-sm capitalize">{name}</span>
                                    {selectedTheme === name && (
                                        <span className="material-symbols-outlined text-sm text-[var(--primary-button)] animate-bounce">
                                            check_circle
                                        </span>
                                    )}
                                </div>
                                <div className="grid grid-cols-4 gap-1">
                                    {Object.entries(theme).slice(0, 8).map(([key, color]) => (
                                        <div
                                            key={key}
                                            className="w-4 h-4 rounded shadow-sm"
                                            style={{ background: color }}
                                        />
                                    ))}
                                </div>
                            </div>
                        </button>
                    ))}
                </div>
            </div>

            {/* Custom Themes */}
            <div className="space-y-4 border border-[var(--surface-border)] rounded-lg p-4">
                <div className="flex items-center justify-between">
                    <h4 className="text-sm font-bold text-[var(--text-primary)] text-left">Custom Themes</h4>
                    <button
                        onClick={() => setShowSaveDialog(true)}
                        className="base-button px-3 py-1.5 rounded text-sm flex items-center gap-2"
                    >
                        <span className="material-symbols-outlined text-sm">add</span>
                        Save Theme
                    </button>
                </div>

                {showSaveDialog && (
                    <div className="flex items-center gap-2">
                        <input
                            type="text"
                            value={customThemeName}
                            onChange={(e) => setCustomThemeName(e.target.value)}
                            placeholder="Theme name"
                            className="flex-1 px-3 py-1.5 rounded bg-[var(--input-bg)] border border-[var(--input-border)] focus:border-[var(--input-border-hover)] focus:outline-none"
                        />
                        <button
                            onClick={handleSaveCustomTheme}
                            className="base-button px-3 py-1.5 rounded text-sm"
                        >
                            Save
                        </button>
                        <button
                            onClick={() => setShowSaveDialog(false)}
                            className="base-button px-3 py-1.5 rounded text-sm"
                        >
                            Cancel
                        </button>
                    </div>
                )}

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {Object.entries(themeData.customThemes).map(([name, theme]) => (
                        <div
                            key={name}
                            className={`theme-preview-button p-4 rounded-lg border-2 transition-all duration-200 flex flex-col min-h-[180px] ${
                                selectedTheme === name 
                                    ? 'border-[var(--primary-button)] shadow-lg scale-[1.02]' 
                                    : 'border-[var(--surface-border)] hover:border-[var(--primary-button-hover)] hover:scale-[1.01]'
                            }`}
                            style={{
                                background: theme['surface-bg'],
                                color: theme['text-primary']
                            }}
                        >
                            <button
                                onClick={() => applyTheme(theme, name)}
                                className="w-full flex-1 text-left"
                            >
                                <div className="flex flex-col gap-2 h-full">
                                    <div className="flex items-start gap-2 min-h-[24px]">
                                        <span className="text-sm break-all pr-6">{name}</span>
                                        {selectedTheme === name && (
                                            <span className="material-symbols-outlined text-sm text-[var(--primary-button)] animate-bounce flex-shrink-0 absolute right-4">
                                                check_circle
                                            </span>
                                        )}
                                    </div>
                                    <div className="grid grid-cols-4 gap-1 mt-auto">
                                        {Object.entries(theme).slice(0, 8).map(([key, color]) => (
                                            <div
                                                key={key}
                                                className="w-4 h-4 rounded"
                                                style={{ background: color }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </button>
                            <div className="flex items-center justify-end gap-1 mt-3 pt-3 border-t border-[var(--surface-border)]">
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleUpdateCustomTheme(name);
                                    }}
                                    className="w-8 h-8 rounded flex items-center justify-center hover:bg-[var(--surface-bg-darker)] text-[var(--text-muted)] hover:text-[var(--primary-button)]"
                                    title="Save current colors to this theme"
                                >
                                    <span className="material-symbols-outlined text-[20px]">save</span>
                                </button>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteCustomTheme(name);
                                    }}
                                    className="w-8 h-8 rounded flex items-center justify-center hover:bg-[var(--surface-bg-darker)] text-[var(--text-muted)] hover:text-[var(--error)]"
                                    title="Delete theme"
                                >
                                    <span className="material-symbols-outlined text-[20px]">delete</span>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Color Customization */}
            <div className="space-y-4 border border-[var(--surface-border)] rounded-lg p-4">
                <h4 className="text-sm font-bold text-[var(--text-primary)] text-left">Color Customization</h4>
                <div className="space-y-6">
                    {Object.entries(colorGroups).map(([groupName, colors]) => (
                        <div key={groupName} className="space-y-3">
                            <h4 className="text-sm font-bold text-[var(--text-primary)] text-left">{groupName}</h4>
                            <div className="grid grid-cols-1 gap-2">
                                {colors.map(({ key, label }) => (
                                    <div key={key} 
                                        className="flex flex-col sm:flex-row sm:items-center sm:justify-between py-2 px-3 rounded bg-[var(--surface-bg-darker)] hover:bg-[var(--button-hover-bg)] transition-colors gap-2"
                                    >
                                        <label className="text-[var(--text-secondary)] text-sm">
                                            {label}
                                        </label>
                                        <div className="flex items-center gap-2">
                                            <input 
                                                type="color" 
                                                value={getColorValue(key)}
                                                onChange={(e) => handleColorChange(key, e.target.value)}
                                                className="w-6 h-6 rounded cursor-pointer border border-[var(--surface-border)] [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none"
                                            />
                                            <input 
                                                type="text" 
                                                value={getColorValue(key)}
                                                onChange={(e) => handleColorChange(key, e.target.value)}
                                                className="w-20 px-2 py-0.5 text-xs rounded bg-[var(--input-bg)] border border-[var(--input-border)] text-[var(--text-primary)]"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ThemeSettings; 
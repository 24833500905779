import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import api from '../services/api';

export const fetchTodos = createAsyncThunk(
    'todos/fetchTodos',
    async (_, { dispatch }) => {
        const [todosResponse, settingsResponse] = await Promise.all([
            api.get('/todos'),
            api.get('/settings')
        ]);

        // If there are saved day labels in settings, update them
        if (settingsResponse.data.dayLabels) {
            dispatch(updateDayLabels(settingsResponse.data.dayLabels));
        }

        return todosResponse.data;
    }
);

export const addTodo = createAsyncThunk(
    'todos/addTodo',
    async (todo, { dispatch }) => {
        const tempId = uuidv4();
        const newTodo = {
            id: tempId,
            text: todo.text,
            completed: false,
            urgent: false,
            source: todo.source || todo.location,
            location: todo.location,
            meetingId: todo.location.startsWith('meeting-') ? todo.location.split('meeting-')[1] : null,
            createdAt: new Date().toISOString(),
            movements: [],
            ...todo,
            _tempId: tempId // Store the temporary ID to help with updates
        };

        try {
            // Optimistic update
            dispatch(addTodoLocal(newTodo));
            
            // Make API call
            const response = await api.post('/todos', newTodo);
            
            // Update the local todo with the server's ID
            dispatch(updateTodoId({ oldId: tempId, newTodo: response.data }));
            return response.data;
        } catch (error) {
            // Revert optimistic update
            console.error('Failed to add todo:', error);
            dispatch(removeTodo(tempId));
            throw error;
        }
    }
);

export const updateTodo = createAsyncThunk(
    'todos/updateTodo',
    async (todo) => {
        const response = await api.put(`/todos/${todo.id}`, todo);
        return response.data;
    }
);

export const deleteTodo = createAsyncThunk(
    'todos/deleteTodo',
    async (id, { dispatch, getState }) => {
        // Store the original todo and its index before deletion
        const state = getState();
        const todoIndex = state.todos.items.findIndex(todo => todo.id === id);
        if (todoIndex === -1) return id;
        const todoToDelete = state.todos.items[todoIndex];

        try {
            // Optimistic update
            dispatch(removeTodo(id));
            
            // If the todo has a _tempId, it means it hasn't been synced with the server yet
            // So we don't need to make the API call
            if (todoToDelete._tempId) {
                return id;
            }
            
            // Make API call
            await api.delete(`/todos/${id}`);
            return id;
        } catch (error) {
            // Don't revert if the todo is already gone (404)
            if (error?.response?.status === 404) {
                return id;
            }
            
            // For other errors, revert the optimistic update
            console.error('Failed to delete todo:', error);
            dispatch(restoreTodo({ todo: todoToDelete, index: todoIndex }));
            throw error;
        }
    }
);

export const moveTodoWithAPI = createAsyncThunk(
    'todos/moveTodoWithAPI',
    async ({ todoId, newLocation }, { getState, dispatch }) => {
        const state = getState();
        const todo = state.todos.items.find(t => t.id === todoId);
        if (!todo) return;

        const createMovement = {
            source: todo.source || todo.location,
            destination: newLocation,
            timestamp: new Date().toISOString()
        };

        // Moving from project to weekly
        if (todo.location.startsWith('project-') && newLocation === 'weekly') {
            try {
                // First check if this todo is already linked to a weekly todo
                const projectTodo = state.todos.items.find(t => t.id === todo.id);
                if (projectTodo.weeklyTodoId) {
                    // If it has a weeklyTodoId, find that todo
                    const linkedWeeklyTodo = state.todos.items.find(t => t.id === projectTodo.weeklyTodoId);
                    if (linkedWeeklyTodo) {
                        // The linked todo exists, just return it
                        return linkedWeeklyTodo;
                    }
                }

                // No valid linked todo found, create a new one
                const weeklyTodo = {
                    ...todo,
                    id: uuidv4(),
                    source: todo.location,
                    location: 'weekly',
                    projectId: todo.id,
                    movements: [...(todo.movements || []), createMovement]
                };

                // Create weekly todo first
                const weeklyResponse = await api.post('/todos', weeklyTodo);
                const createdWeeklyTodo = weeklyResponse.data;

                // Update project todo with the link
                const updatedProjectTodo = {
                    ...projectTodo,
                    hasWeeklyLink: true,
                    weeklyTodoId: createdWeeklyTodo.id,
                    movements: [...(projectTodo.movements || []), createMovement]
                };

                // Update project todo in backend
                const projectResponse = await api.put(`/todos/${todo.id}`, updatedProjectTodo);

                // Update both todos in state atomically
                dispatch({
                    type: 'todos/updateMultipleTodos',
                    payload: {
                        updated: [projectResponse.data],
                        added: [createdWeeklyTodo]
                    }
                });

                return createdWeeklyTodo;
            } catch (error) {
                console.error('Error moving todo to weekly:', error);
                throw error;
            }
        }
        // Moving from weekly back to project
        else if (todo.location === 'weekly' && newLocation.startsWith('project-')) {
            try {
                // Create a new project todo
                const projectTodo = {
                    ...todo,
                    id: uuidv4(),
                    source: todo.location,
                    location: newLocation,
                    weeklyTodoId: todo.id,
                    movements: [...(todo.movements || []), createMovement]
                };

                // Create project todo
                const projectResponse = await api.post('/todos', projectTodo);
                const createdProjectTodo = projectResponse.data;

                // Update weekly todo with the link
                const updatedWeeklyTodo = {
                    ...todo,
                    hasProjectLink: true,
                    projectId: createdProjectTodo.id,
                    movements: [...(todo.movements || []), createMovement]
                };

                // Update weekly todo in backend
                const weeklyResponse = await api.put(`/todos/${todo.id}`, updatedWeeklyTodo);

                // Update both todos in state atomically
                dispatch({
                    type: 'todos/updateMultipleTodos',
                    payload: {
                        updated: [weeklyResponse.data],
                        added: [createdProjectTodo]
                    }
                });

                return createdProjectTodo;
            } catch (error) {
                console.error('Error moving todo to project:', error);
                throw error;
            }
        }
        // Default case - just update location and source
        else {
            const updatedTodo = {
                ...todo,
                source: todo.location,
                location: newLocation,
                movements: [...(todo.movements || []), createMovement]
            };
            const response = await api.put(`/todos/${todoId}`, updatedTodo);
            // Update state with the server response
            dispatch(updateTodoFromServer(response.data));
            return response.data;
        }
    }
);

export const editTodoWithAPI = createAsyncThunk(
    'todos/editTodoWithAPI',
    async ({ todoId, text }, { dispatch, getState }) => {
        try {
            const state = getState();
            const todo = state.todos.items.find(t => t.id === todoId);
            if (!todo) return;

            // Optimistically update local state first
            dispatch(editTodo({ todoId, text }));

            // Make the API call with the full todo object
            const response = await api.put(`/todos/${todoId}`, {
                ...todo,
                text
            });
            
            return response.data;
        } catch (error) {
            // Revert the local update if API call fails
            console.error('Failed to update todo text:', error);
            // Revert the optimistic update
            const originalTodo = getState().todos.items.find(t => t.id === todoId);
            if (originalTodo) {
                dispatch(editTodo({ todoId, text: originalTodo.text }));
            }
            throw error;
        }
    }
);

export const clearCompletedTodosWithAPI = createAsyncThunk(
    'todos/clearCompletedTodosWithAPI',
    async (location, { getState, dispatch }) => {
        try {
            const state = getState();
            const completedTodos = state.todos.items.filter(todo => 
                todo.location === location && todo.completed
            );
            
            // Optimistic update
            dispatch(clearCompletedTodos(location));
            
            // Delete all completed todos in parallel
            await Promise.all(
                completedTodos.map(todo => 
                    api.delete(`/todos/${todo.id}`)
                )
            );

            // Return the ids of deleted todos
            return completedTodos.map(todo => todo.id);
        } catch (error) {
            console.error('Failed to clear completed todos:', error);
            // Revert optimistic update by refetching
            dispatch(fetchTodos());
            throw error;
        }
    }
);

export const reorderTodosWithAPI = createAsyncThunk(
    'todos/reorderTodosWithAPI',
    async ({ location, reorderedTodos }, { dispatch }) => {
        try {
            // Optimistically update the UI
            dispatch(reorderTodos({ location, reorderedTodos }));

            // Make the API call with the correct endpoint path
            const response = await api.post('/todos/bulk', {
                operation: 'reorder',
                todos: reorderedTodos,
                location
            });

            return response.data;
        } catch (error) {
            console.error('Failed to reorder todos:', error);
            // Revert the optimistic update by refetching todos
            dispatch(fetchTodos());
            throw error;
        }
    }
);

// Add new thunk for updating todo days
export const updateTodoDays = createAsyncThunk(
    'todos/updateTodoDays',
    async ({ todoId, days }, { dispatch, getState }) => {
        try {
            const state = getState();
            const todo = state.todos.items.find(t => t.id === todoId);
            if (!todo) return;

            // Optimistic update
            dispatch(updateTodoDaysLocal({ todoId, days }));

            // Make API call
            const response = await api.put(`/todos/${todoId}`, { ...todo, days });
            return response.data;
        } catch (error) {
            // Revert optimistic update
            console.error('Failed to update todo days:', error);
            const state = getState();
            const todo = state.todos.items.find(t => t.id === todoId);
            if (todo) {
                dispatch(updateTodoDaysLocal({ todoId, days: todo.days || [] }));
            }
            throw error;
        }
    }
);

export const convertTodoToPing = createAsyncThunk(
    'todos/convertTodoToPing',
    async (todoId, { getState, dispatch }) => {
        try {
            const state = getState();
            const todo = state.todos.items.find(t => t.id === todoId);
            if (!todo) return;

            const pingTodo = {
                ...todo,
                location: 'ping',
                source: todo.source || todo.location,
                movements: [
                    ...(todo.movements || []),
                    {
                        source: todo.location,
                        destination: 'ping',
                        timestamp: new Date().toISOString()
                    }
                ]
            };

            // Optimistic update
            dispatch(updateTodoFromServer(pingTodo));

            // Make API call
            const response = await api.put(`/todos/${todoId}`, pingTodo);
            return response.data;
        } catch (error) {
            // Revert optimistic update
            console.error('Failed to convert todo to ping:', error);
            const state = getState();
            const originalTodo = state.todos.items.find(t => t.id === todoId);
            if (originalTodo) {
                dispatch(updateTodoFromServer(originalTodo));
            }
            throw error;
        }
    }
);

export const selectAllTodos = state => state.todos.items;
export const selectAllProjects = state => state.projects.items;

export const selectPings = createSelector(
    [selectAllTodos],
    (todos) => todos.filter(todo => todo.location === 'ping')
);

export const selectSourceDisplay = createSelector(
    [selectAllProjects, 
     (_, source) => source],
    (projects, source) => {
        if (source?.startsWith('project-')) {
            const projectId = source.replace('project-', '');
            const project = projects.find(p => p.id === projectId);
            return project ? {
                text: `Project: ${project.name}`,
                icon: project.icon || 'folder',
                status: project.status,
                statusKey: project.status?.toLowerCase().replace(/_/g, '-')
            } : null;
        }
        return null;
    }
);

const todosSlice = createSlice({
    name: 'todos',
    initialState: {
        items: [],
        loading: false,
        error: null,
        dayLabels: {
            sunday: 'Su',
            monday: 'M',
            tuesday: 'T',
            wednesday: 'W',
            thursday: 'Th',
            friday: 'F',
            saturday: 'S'
        }
    },
    reducers: {
        toggleTodoComplete: (state, action) => {
            const todo = state.items.find(todo => todo.id === action.payload);
            if (todo) {
                todo.completed = !todo.completed;
            }
        },
        toggleTodoUrgent: (state, action) => {
            const todo = state.items.find(todo => todo.id === action.payload);
            if (todo) {
                todo.urgent = !todo.urgent;
            }
        },
        editTodo: (state, action) => {
            const { todoId, text } = action.payload;
            const todo = state.items.find(todo => todo.id === todoId);
            if (todo) {
                todo.text = text;
            }
        },
        reorderTodos: (state, action) => {
            const { location, reorderedTodos } = action.payload;
            const otherTodos = state.items.filter(todo => todo.location !== location);
            state.items = [...otherTodos, ...reorderedTodos];
            // Could add API call here if order needs to be persisted
        },
        moveTodo: (state, action) => {
            const { todoId, newLocation, weeklyTodo } = action.payload;
            const todo = state.items.find(todo => todo.id === todoId);
            if (todo) {
                // Moving from project to weekly
                if (todo.location.startsWith('project-') && newLocation === 'weekly' && weeklyTodo) {
                    state.items.push(weeklyTodo);
                }
                // Moving from weekly back to project
                else if (todo.location === 'weekly' && newLocation.startsWith('project-')) {
                    if (todo.projectId) {
                        state.items = state.items.filter(t => t.id !== todoId);
                    }
                }
                // Default case - just update location
                else {
                    const createMovement = {
                        source: todo.source || todo.location,
                        destination: newLocation,
                        timestamp: new Date().toISOString()
                    };
                    if (!todo.movements) {
                        todo.movements = [];
                    }
                    todo.movements.push(createMovement);
                    todo.location = newLocation;
                }
            }
        },
        clearCompletedTodos: (state, action) => {
            const location = action.payload;
            state.items = state.items.filter(todo => 
                todo.location !== location || !todo.completed
            );
        },
        updateTodoFromServer: (state, action) => {
            const index = state.items.findIndex(todo => todo.id === action.payload.id);
            if (index !== -1) {
                state.items[index] = action.payload;
            }
        },
        updateDayLabels: (state, action) => {
            state.dayLabels = { ...state.dayLabels, ...action.payload };
        },
        toggleTodoDay: (state, action) => {
            const { todoId, day } = action.payload;
            const todo = state.items.find(todo => todo.id === todoId);
            if (todo) {
                // If the day is already selected, remove it; otherwise, set it as the only day
                todo.days = todo.days?.includes(day) ? [] : [day];
            }
        },
        updateMultipleTodos: (state, action) => {
            const { updated, added } = action.payload;
            
            // Update existing todos
            updated.forEach(todo => {
                const index = state.items.findIndex(t => t.id === todo.id);
                if (index !== -1) {
                    state.items[index] = todo;
                }
            });
            
            // Add new todos
            added.forEach(todo => {
                if (!state.items.find(t => t.id === todo.id)) {
                    state.items.push(todo);
                }
            });
        },
        completePing: (state, action) => {
            state.items = state.items.filter(todo => todo.id !== action.payload);
        },
        removeTodo: (state, action) => {
            state.items = state.items.filter(todo => todo.id !== action.payload);
        },
        updateTodoDaysLocal: (state, action) => {
            const { todoId, days } = action.payload;
            const todo = state.items.find(todo => todo.id === todoId);
            if (todo) {
                todo.days = days;
            }
        },
        addTodoLocal: (state, action) => {
            state.items.push(action.payload);
        },
        restoreTodo: (state, action) => {
            const { todo, index } = action.payload;
            // Insert the todo back at its original position
            state.items.splice(index, 0, todo);
        },
        updateTodoId: (state, action) => {
            const { oldId, newTodo } = action.payload;
            const index = state.items.findIndex(todo => todo.id === oldId);
            if (index !== -1) {
                state.items[index] = newTodo;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTodos.fulfilled, (state, action) => {
                state.items = action.payload;
            })
            .addCase(addTodo.fulfilled, (state, action) => {
                // The todo has already been updated with the server's ID
                // by the updateTodoId reducer, so we don't need to do anything here
            })
            .addCase(updateTodo.fulfilled, (state, action) => {
                const index = state.items.findIndex(todo => todo.id === action.payload.id);
                if (index !== -1) {
                    state.items[index] = action.payload;
                }
            })
            .addCase(deleteTodo.fulfilled, (state, action) => {
                state.items = state.items.filter(todo => todo.id !== action.payload);
            })
            .addCase(clearCompletedTodosWithAPI.fulfilled, (state, action) => {
                const deletedIds = new Set(action.payload);
                state.items = state.items.filter(todo => !deletedIds.has(todo.id));
            })
            .addCase(reorderTodosWithAPI.fulfilled, (state, action) => {
                // Update with the server response if needed
                const location = action.meta.arg.location;
                const otherTodos = state.items.filter(todo => todo.location !== location);
                state.items = [...otherTodos, ...action.payload];
            })
            .addCase(updateTodoDays.fulfilled, (state, action) => {
                const index = state.items.findIndex(todo => todo.id === action.payload.id);
                if (index !== -1) {
                    state.items[index] = action.payload;
                }
            })
            .addCase(convertTodoToPing.fulfilled, (state, action) => {
                const index = state.items.findIndex(todo => todo.id === action.payload.id);
                if (index !== -1) {
                    state.items[index] = action.payload;
                }
            });
    }
});

export const {
    toggleTodoComplete,
    toggleTodoUrgent,
    editTodo,
    reorderTodos,
    moveTodo,
    clearCompletedTodos,
    updateTodoFromServer,
    updateDayLabels,
    toggleTodoDay,
    updateMultipleTodos,
    completePing,
    removeTodo,
    updateTodoDaysLocal,
    addTodoLocal,
    restoreTodo,
    updateTodoId
} = todosSlice.actions;

export default todosSlice.reducer; 
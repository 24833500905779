import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { reorderTodosWithAPI } from '../store/todosSlice';

export function useTodoDragAndDrop({ todos, onReorderTodos }) {
    const [draggedTodo, setDraggedTodo] = useState(null);
    const [draggedOverTodo, setDraggedOverTodo] = useState(null);
    const dispatch = useDispatch();

    const handleDragStart = useCallback((e, todo) => {
        if (e.target.closest('.editing')) {
            e.preventDefault();
            return;
        }
        
        setDraggedTodo(todo);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', todo.id);
        e.target.style.opacity = '0.5';
    }, []);

    const handleDragOver = useCallback((e, todo) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
        setDraggedOverTodo(todo);
    }, []);

    const handleDrop = useCallback(async (e, targetTodo) => {
        e.preventDefault();
        if (!draggedTodo || draggedTodo.id === targetTodo.id) return;

        const newTodos = [...todos];
        const draggedIndex = newTodos.findIndex(todo => todo.id === draggedTodo.id);
        const targetIndex = newTodos.findIndex(todo => todo.id === targetTodo.id);

        newTodos.splice(draggedIndex, 1);
        newTodos.splice(targetIndex, 0, draggedTodo);

        const updatedTodos = newTodos.map((todo, index) => ({
            ...todo,
            order: index
        }));

        try {
            await dispatch(reorderTodosWithAPI({
                location: draggedTodo.location,
                reorderedTodos: updatedTodos
            })).unwrap();
        } catch (error) {
            console.error('Error reordering todos:', error);
        }

        setDraggedTodo(null);
        setDraggedOverTodo(null);
    }, [draggedTodo, todos, dispatch]);

    const handleDragEnd = useCallback((e) => {
        e.target.style.opacity = '1';
        setDraggedTodo(null);
        setDraggedOverTodo(null);
    }, []);

    const getDragStyles = useCallback((todo) => {
        if (draggedTodo?.id === todo.id) {
            return "opacity-50";
        }
        if (draggedOverTodo?.id === todo.id) {
            const draggedIndex = todos.findIndex(t => t.id === draggedTodo?.id);
            const targetIndex = todos.findIndex(t => t.id === todo.id);
            return draggedIndex < targetIndex ? "border-b-2 border-blue-500" : "border-t-2 border-blue-500";
        }
        return "";
    }, [draggedTodo, draggedOverTodo, todos]);

    return {
        handleDragStart,
        handleDragOver,
        handleDrop,
        handleDragEnd,
        getDragStyles
    };
} 
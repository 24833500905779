import { useSelector, useDispatch } from 'react-redux';
import {
  setRecurringTasks,
  setActiveRecurringTask,
  addRecurringTask,
  deleteRecurringTask,
  updateRecurringTask,
  completeRecurringTask,
  updateLastCompleted,
  calculateTaskDates,
  reorderRecurringTasksThunk,
  reorderTasks,
  selectSortedRecurringTasks,
  fetchRecurringTasks,
} from '../store/recurringTasksSlice';

export const useRecurringTasks = () => {
  const dispatch = useDispatch();
  const tasks = useSelector(selectSortedRecurringTasks);
  const activeTask = useSelector(state => state.recurringTasks.activeRecurringTask);

  const handleUpdateTask = (taskId, updates) => {
    // Serialize any Date objects and ensure numeric fields are numbers
    const serializedUpdates = Object.keys(updates).reduce((acc, key) => {
      if (updates[key] instanceof Date) {
        acc[key] = updates[key].toISOString();
      } else if (key === 'dayOfWeek' || key === 'dateOfMonth') {
        // Ensure these are stored as numbers or null
        acc[key] = updates[key] !== null ? Number(updates[key]) : null;
      } else if (key === 'preparationTime' || key === 'gracePeriod') {
        // Handle empty strings and ensure numeric values for these fields
        const value = updates[key];
        if (value === '' || value === null || value === undefined) {
          acc[key] = 0;
        } else {
          // Ensure we're working with valid numbers only
          const numValue = Number(value);
          acc[key] = isNaN(numValue) ? 0 : numValue;
        }
      } else {
        acc[key] = updates[key];
      }
      return acc;
    }, {});

    // For numeric fields, only dispatch if the value has actually changed
    const currentTask = tasks.find(t => t.id === taskId);
    if (currentTask) {
      const hasNumericChanges = ['preparationTime', 'gracePeriod'].some(field => {
        return serializedUpdates[field] !== undefined && 
               serializedUpdates[field] !== currentTask[field];
      });

      // Always dispatch if it's a title update or if there are numeric changes or forceRecalculate
      if (serializedUpdates.title !== undefined || hasNumericChanges || updates.forceRecalculate) {
        return dispatch(updateRecurringTask({ taskId, updates: serializedUpdates })).unwrap();
      }
    }

    return Promise.resolve(currentTask);
  };

  const handleCompleteTask = (taskId, completionDate) => {
    // Ensure completionDate is a Date object
    const dateObj = completionDate instanceof Date ? 
      completionDate : 
      new Date(completionDate);

    return dispatch(completeRecurringTask({ 
      taskId, 
      completionDate: dateObj.toISOString() 
    })).unwrap();
  };

  const handleUpdateLastCompleted = (taskId, date) => {
    const serializedDate = date instanceof Date ? date.toISOString() : date;
    dispatch(updateLastCompleted({ taskId, date: serializedDate }));
  };

  const handleReorderTasks = (reorderedTasks) => {
    const tasksWithOrder = reorderedTasks.map((task, index) => ({
      ...task,
      order: index
    }));
    dispatch(reorderTasks(tasksWithOrder));
    dispatch(reorderRecurringTasksThunk(tasksWithOrder))
      .unwrap()
      .catch(error => {
        console.error('Failed to save task order:', error);
        dispatch(fetchRecurringTasks());
      });
  };

  return {
    tasks,
    activeTask,
    calculateTaskDates,
    handleAddTask: (task) => dispatch(addRecurringTask(task)),
    handleUpdateTask,
    handleDeleteTask: (taskId) => dispatch(deleteRecurringTask(taskId)),
    handleSetActiveTask: (task) => dispatch(setActiveRecurringTask(task)),
    handleCompleteTask,
    handleUpdateLastCompleted,
    handleSetTasks: (tasks) => dispatch(setRecurringTasks(tasks)),
    handleReorderTasks,
  };
}; 
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addTodo,
  toggleTodoComplete,
  toggleTodoUrgent,
  deleteTodo,
  editTodo,
  reorderTodos,
  clearCompletedTodosWithAPI,
  moveTodoWithAPI
} from '../store/todosSlice';

export function useTodoList(location) {
  const dispatch = useDispatch();
  const allTodos = useSelector(state => state.todos.items);
  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const [selectedTodoForMove, setSelectedTodoForMove] = useState(null);

  // Filter todos by their current location
  const locationTodos = allTodos.filter(todo => todo.location === location);

  const moveActions = [
    {
      icon: 'drive_file_move',
      label: 'Move to...',
      onClick: (todo) => {
        setSelectedTodoForMove(todo);
        setMoveModalOpen(true);
      }
    }
  ];

  const handlers = {
    handleAddTodo: (todo) => {
      const meetingId = location.startsWith('meeting-') 
        ? location.replace('meeting-', '') 
        : undefined;
      
      dispatch(addTodo({ 
        ...todo, 
        location,
        meetingId,
        movements: []
      }));
    },
    handleToggleComplete: (todoId) => dispatch(toggleTodoComplete(todoId)),
    handleToggleUrgent: (todoId) => dispatch(toggleTodoUrgent(todoId)),
    handleDelete: (todoId) => dispatch(deleteTodo(todoId)),
    handleEdit: (todoId, newText) => dispatch(editTodo({ todoId, text: newText })),
    handleReorderTodos: (reorderedTodos) => dispatch(reorderTodos({ location, reorderedTodos })),
    handleMove: (todoId, newLocation) => {
      dispatch(moveTodoWithAPI({ todoId, newLocation }));
      setMoveModalOpen(false);
      setSelectedTodoForMove(null);
    },
    handleClearCompleted: () => dispatch(clearCompletedTodosWithAPI(location))
  };

  return {
    todos: locationTodos,
    allTodos,
    moveModalOpen,
    setMoveModalOpen,
    selectedTodoForMove,
    setSelectedTodoForMove,
    moveActions,
    handlers
  };
} 
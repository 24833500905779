import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import api from '../services/api';

const API_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3001/api';

export const PROJECT_STATUSES = {
    NOT_STARTED: { label: 'Not Started', color: '#6c757d' },
    IN_REVIEW: { label: 'In Review', color: '#ffc107' },
    IN_PROGRESS: { label: 'In Progress', color: '#007bff' },
    READY_FOR_GOLIVE: { label: 'Ready for Go-Live', color: '#17a2b8' },
    COMPLETED: { label: 'Completed', color: '#28a745' }
};

export const selectSortedProjects = createSelector(
    state => state.projects.items,
    state => state.settings.settings?.projectsSortDirection || 'off',
    (items, sortDirection) => {
        const statusPriority = {
            'IN_PROGRESS': 1,
            'IN_REVIEW': 2,
            'READY_FOR_GOLIVE': 3,
            'NOT_STARTED': 4,
            'COMPLETED': 5
        };

        if (sortDirection === 'off') {
            return [...items].sort((a, b) => (a.order || 0) - (b.order || 0));
        }

        return [...items].sort((a, b) => {
            const statusDiff = (statusPriority[a.status] || 999) - (statusPriority[b.status] || 999);
            if (sortDirection === 'desc') {
                return statusDiff !== 0 ? -statusDiff : (a.order || 0) - (b.order || 0);
            }
            return statusDiff !== 0 ? statusDiff : (a.order || 0) - (b.order || 0);
        });
    }
);

export const fetchProjects = createAsyncThunk(
    'projects/fetchProjects',
    async () => {
        const response = await api.get(`${API_URL}/projects`);
        const projectsWithOrder = response.data.map((project, index) => ({
            ...project,
            order: project.order ?? index
        }));
        return projectsWithOrder.sort((a, b) => a.order - b.order);
    }
);

export const addProject = createAsyncThunk(
    'projects/addProject',
    async (project, { getState, dispatch }) => {
        const state = getState();
        const sortedProjects = [...state.projects.items].sort((a, b) => (a.order || 0) - (b.order || 0));
        const maxOrder = sortedProjects.length > 0 ? Math.max(...sortedProjects.map(p => p.order || 0)) : -1;
        const newOrder = maxOrder + 1;
        
        // Create optimistic project with temporary ID
        const tempId = `temp-${Date.now()}`;
        const optimisticProject = {
            ...project,
            id: tempId,
            order: newOrder
        };
        
        // Add optimistic project to state
        dispatch(optimisticAddProject(optimisticProject));
        
        try {
            // Make API call
            const response = await api.post(`${API_URL}/projects`, {
                ...project,
                order: newOrder
            });
            
            // Return both the temp ID and the real project data
            return { ...response.data, order: newOrder, tempId };
        } catch (error) {
            // Let the reducer know which optimistic project to remove
            error.tempId = tempId;
            throw error;
        }
    }
);

export const updateProject = createAsyncThunk(
    'projects/updateProject',
    async (project) => {
        const response = await api.put(`${API_URL}/projects/${project.id}`, project);
        return response.data;
    }
);

export const deleteProject = createAsyncThunk(
    'projects/deleteProject',
    async (id, { getState, dispatch }) => {
        // Store the project before deleting it
        const state = getState();
        const project = state.projects.items.find(p => p.id === id);
        
        // Optimistically remove the project
        dispatch(optimisticDeleteProject(id));
        
        try {
            await api.delete(`${API_URL}/projects/${id}`);
            return id;
        } catch (error) {
            // Pass the project in meta so we can restore it on error
            error.project = project;
            throw error;
        }
    }
);

export const reorderProjectsThunk = createAsyncThunk(
    'projects/reorderProjectsThunk',
    async (projects) => {
        try {
            // Send the entire array of projects with their new orders to be updated in one request
            const response = await api.post(`${API_URL}/projects/reorder`, {
                projects: projects.map((project, index) => ({
                    id: project.id,
                    order: index
                }))
            });
            return response.data;
        } catch (error) {
            console.error('Failed to reorder projects:', error);
            throw error;
        }
    }
);

export const updateProjectNotesAsync = createAsyncThunk(
    'projects/updateProjectNotesAsync',
    async ({ projectId, notes, notesHeight }) => {
        const response = await api.put(`${API_URL}/projects/${projectId}`, { 
            notes, 
            notesHeight 
        });
        return response.data;
    }
);

export const updateProjectStatusAsync = createAsyncThunk(
    'projects/updateProjectStatusAsync',
    async ({ projectId, status }) => {
        const response = await api.put(`${API_URL}/projects/${projectId}`, { status });
        return response.data;
    }
);

const projectsSlice = createSlice({
    name: 'projects',
    initialState: {
        items: [],
        activeProject: null
    },
    reducers: {
        setActiveProject: (state, action) => {
            state.activeProject = action.payload;
        },
        updateProjectNotesState: (state, action) => {
            const { projectId, notes, notesHeight } = action.payload;
            const project = state.items.find(p => p.id === projectId);
            if (project) {
                if (notes !== undefined) project.notes = notes;
                if (notesHeight !== undefined) project.notesHeight = notesHeight;
            }
        },
        editProject: (state, action) => {
            const { projectId, name } = action.payload;
            const project = state.items.find(p => p.id === projectId);
            if (project) {
                project.name = name;
            }
        },
        updateProjectIcon: (state, action) => {
            const { projectId, icon } = action.payload;
            const project = state.items.find(p => p.id === projectId);
            if (project) {
                project.icon = icon;
            }
        },
        updateProjectStatus: (state, action) => {
            const { projectId, status } = action.payload;
            const project = state.items.find(p => p.id === projectId);
            if (project) {
                project.status = status;
            }
        },
        reorderProjects: (state, action) => {
            state.items = action.payload;
        },
        optimisticAddProject: (state, action) => {
            state.items.push(action.payload);
            state.items.sort((a, b) => (a.order || 0) - (b.order || 0));
        },
        optimisticDeleteProject: (state, action) => {
            state.items = state.items.filter(project => project.id !== action.payload);
            if (state.activeProject?.id === action.payload) {
                state.activeProject = null;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.items = action.payload;
            })
            .addCase(addProject.fulfilled, (state, action) => {
                // Replace the optimistic project with the real one
                state.items = state.items.map(project => 
                    project.id === action.payload.tempId ? action.payload : project
                );
            })
            .addCase(addProject.rejected, (state, action) => {
                // Remove the optimistic project on failure
                state.items = state.items.filter(project => project.id !== action.meta.arg.tempId);
            })
            .addCase(updateProject.fulfilled, (state, action) => {
                const index = state.items.findIndex(project => project.id === action.payload.id);
                if (index !== -1) {
                    state.items[index] = action.payload;
                }
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                // No need to update state here since we already removed it optimistically
            })
            .addCase(deleteProject.rejected, (state, action) => {
                // Revert the optimistic delete on failure
                if (action.meta.arg.project) {
                    state.items.push(action.meta.arg.project);
                    state.items.sort((a, b) => (a.order || 0) - (b.order || 0));
                }
            })
            .addCase(reorderProjectsThunk.fulfilled, (state, action) => {
                state.items = action.payload;
            })
            .addCase(updateProjectNotesAsync.rejected, (state, action) => {
                const { projectId, notes, notesHeight } = action.meta.arg;
                const project = state.items.find(p => p.id === projectId);
                if (project) {
                    project.notes = notes;
                    project.notesHeight = notesHeight;
                }
            });
    }
});

export const {
    setActiveProject,
    updateProjectNotesState,
    editProject,
    updateProjectIcon,
    updateProjectStatus,
    reorderProjects,
    optimisticAddProject,
    optimisticDeleteProject
} = projectsSlice.actions;

export default projectsSlice.reducer; 
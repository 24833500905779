import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';

function DashboardTabs({ activeTab, onTabChange }) {
    const tabsRef = useRef([]);
    const [tabIndicatorStyle, setTabIndicatorStyle] = useState({});

    const tabs = useMemo(() => [
        { id: 'projects', label: 'Projects' },
        { id: 'weekly-planner', label: 'Weekly Planner' },
        { id: 'meetings', label: 'Meetings' },
        { id: 'recurring-tasks', label: 'Recurring Tasks' }
    ], []);

    const updateTabIndicator = useCallback(() => {
        const activeTabIndex = tabs.findIndex(tab => tab.id === activeTab);
        const activeTabElement = tabsRef.current[activeTabIndex];
        if (activeTabElement) {
            setTabIndicatorStyle({
                left: `${activeTabElement.offsetLeft}px`,
                width: `${activeTabElement.offsetWidth}px`,
            });
        }
    }, [activeTab, tabs]);

    useEffect(() => {
        updateTabIndicator();
        
        // Add resize event listener
        window.addEventListener('resize', updateTabIndicator);
        
        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', updateTabIndicator);
        };
    }, [updateTabIndicator]);

    return (
        <div className="relative border-b border-[var(--surface-border)] mb-4">
            <div className="flex">
                {tabs.map((tab, index) => (
                    <button
                        key={tab.id}
                        ref={(el) => (tabsRef.current[index] = el)}
                        onClick={() => onTabChange(tab.id)}
                        className={`py-2 px-4 text-sm font-medium flash-button ${
                            activeTab === tab.id ? 'text-[var(--primary-button)]' : 'text-[var(--text-muted)]'
                        } focus:outline-none`}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            <div
                className="absolute bottom-0 left-0 h-0.5 bg-[var(--primary-button)] transition-all duration-300"
                style={tabIndicatorStyle}
            />
        </div>
    );
}

export default DashboardTabs; 
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../services/api';

export const fetchMeetings = createAsyncThunk(
    'meetings/fetchMeetings',
    async () => {
        const response = await api.get('/meetings');
        return response.data;
    }
);

export const addMeeting = createAsyncThunk(
    'meetings/addMeeting',
    async (meeting) => {
        const meetingData = {
            title: meeting.name || '',
            name: meeting.name || '',
            date: meeting.date || new Date().toISOString(),
            notes: meeting.notes || '',
            projectId: meeting.projectId || null,
            status: meeting.status || 'scheduled',
            weeklyPlannerEventId: meeting.weeklyPlannerEventId,
            ...meeting
        };
        
        console.log('Sending meeting data:', meetingData);
        const response = await api.post('/meetings', meetingData);
        return response.data;
    }
);

export const updateMeeting = createAsyncThunk(
    'meetings/updateMeeting',
    async (meeting) => {
        const response = await api.put(`/meetings/${meeting.id}`, meeting);
        return response.data;
    }
);

export const deleteMeeting = createAsyncThunk(
    'meetings/deleteMeeting',
    async (id) => {
        await api.delete(`/meetings/${id}`);
        return id;
    }
);

export const updateMeetingNotesAsync = createAsyncThunk(
    'meetings/updateMeetingNotesAsync',
    async ({ meetingId, notes, notesHeight }) => {
        const response = await api.put(`/meetings/${meetingId}`, { notes, notesHeight });
        return { ...response.data, meetingId };
    }
);

export const summarizeMeetingNotes = createAsyncThunk(
    'meetings/summarizeMeetingNotes',
    async (meetingId) => {
        const response = await api.post(`/meetings/${meetingId}/summarize`);
        return { meetingId, summary: response.data.summary };
    }
);

const meetingsSlice = createSlice({
    name: 'meetings',
    initialState: {
        items: [],
        summarizing: false,
        summaryError: null
    },
    reducers: {
        setMeetingNotesState: (state, action) => {
            const { meetingId, notes, notesHeight } = action.payload;
            const meeting = state.items.find(m => m.id === meetingId);
            if (meeting) {
                if (notes !== undefined) meeting.notes = notes;
                if (notesHeight !== undefined) meeting.notesHeight = notesHeight;
            }
        },
        editMeeting: (state, action) => {
            const { meetingId, updates } = action.payload;
            const meeting = state.items.find(m => m.id === meetingId);
            if (meeting) {
                Object.assign(meeting, updates);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMeetings.fulfilled, (state, action) => {
                state.items = action.payload.map(meeting => ({
                    ...meeting,
                    notes: meeting.notes || '',
                    notesHeight: meeting.notesHeight || null
                }));
            })
            .addCase(addMeeting.fulfilled, (state, action) => {
                state.items.push({
                    ...action.payload,
                    notes: action.payload.notes || '',
                    notesHeight: action.payload.notesHeight || null
                });
            })
            .addCase(updateMeeting.fulfilled, (state, action) => {
                const index = state.items.findIndex(meeting => meeting.id === action.payload.id);
                if (index !== -1) {
                    state.items[index] = {
                        ...action.payload,
                        notes: action.payload.notes || '',
                        notesHeight: action.payload.notesHeight || null
                    };
                }
            })
            .addCase(deleteMeeting.fulfilled, (state, action) => {
                state.items = state.items.filter(meeting => meeting.id !== action.payload);
            })
            .addCase(updateMeetingNotesAsync.rejected, (state, action) => {
                // On error, revert to the values from the action meta
                const { meetingId, notes, notesHeight } = action.meta.arg;
                const meeting = state.items.find(m => m.id === meetingId);
                if (meeting) {
                    meeting.notes = notes;
                    meeting.notesHeight = notesHeight;
                }
            })
            .addCase(summarizeMeetingNotes.pending, (state) => {
                state.summarizing = true;
                state.summaryError = null;
            })
            .addCase(summarizeMeetingNotes.fulfilled, (state) => {
                state.summarizing = false;
            })
            .addCase(summarizeMeetingNotes.rejected, (state, action) => {
                state.summarizing = false;
                state.summaryError = action.error.message;
            });
    }
});

export const {
    setMeetingNotesState,
    editMeeting
} = meetingsSlice.actions;

export default meetingsSlice.reducer; 
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { updateDayLabels } from '../../../store/todosSlice';
import { debounce } from 'lodash';
import api from '../../../services/api';

const DateSettings = () => {
    const dispatch = useDispatch();
    const dayLabels = useSelector((state) => state.todos.dayLabels);
    const [localLabels, setLocalLabels] = useState(dayLabels);

    useEffect(() => {
        setLocalLabels(dayLabels);
    }, [dayLabels]);

    // Create a stable reference to the debounced function
    const debouncedUpdateRef = useRef(
        debounce(async (newLabels, fallbackLabels, setLabels) => {
            try {
                await api.patch('/settings/dayLabels', {
                    value: newLabels
                });
            } catch (error) {
                console.error('Failed to update day label:', error);
                setLabels(fallbackLabels);
            }
        }, 500)
    );

    // Cleanup on unmount
    useEffect(() => {
        const currentDebouncedFn = debouncedUpdateRef.current;
        return () => {
            currentDebouncedFn.cancel();
        };
    }, []);

    const handleLabelChange = (day, value) => {
        const newLabels = {
            ...localLabels,
            [day]: value
        };
        setLocalLabels(newLabels);
        dispatch(updateDayLabels({ [day]: value }));

        // Call the debounced function with current values
        debouncedUpdateRef.current(newLabels, dayLabels, setLocalLabels);
    };

    const days = [
        { key: 'sunday', label: 'Sunday' },
        { key: 'monday', label: 'Monday' },
        { key: 'tuesday', label: 'Tuesday' },
        { key: 'wednesday', label: 'Wednesday' },
        { key: 'thursday', label: 'Thursday' },
        { key: 'friday', label: 'Friday' },
        { key: 'saturday', label: 'Saturday' }
    ];

    return (
        <div className="p-4 sm:p-6 space-y-6">
            <div className="space-y-4 border border-[var(--surface-border)] rounded-lg p-3 sm:p-4">
                <h4 className="text-sm font-bold text-[var(--text-primary)] text-left">Day Labels</h4>
                <p className="text-sm text-[var(--text-secondary)]">
                    Customize how days of the week are displayed in todo items.
                </p>
                
                <div className="space-y-3">
                    {days.map(({ key, label }) => (
                        <div key={key} 
                            className="flex flex-col sm:flex-row sm:items-center sm:justify-between py-2 px-3 rounded bg-[var(--surface-bg-darker)] hover:bg-[var(--button-hover-bg)] transition-colors gap-2"
                        >
                            <label className="text-[var(--text-secondary)] text-sm">
                                {label}
                            </label>
                            <input 
                                type="text" 
                                value={localLabels[key]}
                                onChange={(e) => handleLabelChange(key, e.target.value)}
                                className="w-24 px-2 py-1 text-sm rounded bg-[var(--input-bg)] border border-[var(--input-border)] focus:border-[var(--input-border-hover)] focus:outline-none"
                                placeholder="Su"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DateSettings;
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { styled } from '@mui/material/styles';

// Style the pickers to maintain custom input appearance
export const StyledMobileDatePicker = styled(MobileDatePicker)(({ theme }) => ({
    '& .MuiInputBase-root': {
        color: 'var(--text-primary)',
        backgroundColor: 'var(--input-bg)',
        border: '1px solid var(--surface-border)',
        borderRadius: '0.375rem',
        fontSize: '0.875rem',
        padding: '0.5rem 0.75rem',
        height: '36px',
        transition: 'border-color 0.2s',
        '&:hover': {
            borderColor: 'var(--input-border-hover)',
        },
        '&.Mui-focused': {
            borderColor: 'var(--primary-button)',
            outline: 'none',
            boxShadow: 'none',
        },
        '& input': {
            padding: '0',
        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    // Add picker popup styling
    '& .MuiPaper-root': {
        backgroundColor: 'var(--input-bg)',
        color: 'var(--text-primary)',
    },
    '& .MuiPickersDay-root': {
        color: 'var(--text-primary)',
        '&:hover': {
            backgroundColor: 'var(--surface-bg-lighter)',
        },
        '&.Mui-selected': {
            backgroundColor: 'var(--primary-button)',
            color: 'white',
            '&:hover': {
                backgroundColor: 'var(--primary-button-hover)',
            },
        },
        '&.Mui-selected.Mui-selected': {
            backgroundColor: 'var(--primary-button)',
            color: 'white',
        },
    },
    '& .MuiPickersCalendarHeader-root': {
        color: 'var(--text-primary)',
    },
    '& .MuiPickersDay-today': {
        borderColor: 'var(--primary-button)',
    },
    '& .MuiPickersYear-root': {
        color: 'var(--text-primary)',
        '&:hover': {
            backgroundColor: 'var(--surface-bg-lighter)',
        },
        '&.Mui-selected': {
            backgroundColor: 'var(--primary-button)',
            color: 'white',
            '&:hover': {
                backgroundColor: 'var(--primary-button-hover)',
            },
        },
        '&.Mui-selected.Mui-selected': {
            backgroundColor: 'var(--primary-button)',
            color: 'white',
        },
    },
    '& .MuiPickersMonth-root': {
        color: 'var(--text-primary)',
        '&:hover': {
            backgroundColor: 'var(--surface-bg-lighter)',
        },
        '&.Mui-selected': {
            backgroundColor: 'var(--primary-button)',
            color: 'white',
            '&:hover': {
                backgroundColor: 'var(--primary-button-hover)',
            },
        },
        '&.Mui-selected.Mui-selected': {
            backgroundColor: 'var(--primary-button)',
            color: 'white',
        },
    },
    '& .MuiYearCalendar-root': {
        backgroundColor: 'var(--input-bg)',
    },
    '& .MuiMonthCalendar-root': {
        backgroundColor: 'var(--input-bg)',
    },
}));

export const StyledMobileDateTimePicker = styled(MobileDateTimePicker)(({ theme }) => ({
    '& .MuiInputBase-root': {
        color: 'var(--text-primary)',
        backgroundColor: 'var(--input-bg)',
        border: '1px solid var(--surface-border)',
        borderRadius: '0.375rem',
        fontSize: '0.875rem',
        padding: '0.5rem 0.75rem',
        height: '36px',
        transition: 'border-color 0.2s',
        '&:hover': {
            borderColor: 'var(--input-border-hover)',
        },
        '&.Mui-focused': {
            borderColor: 'var(--primary-button)',
            outline: 'none',
            boxShadow: 'none',
        },
        '& input': {
            padding: '0',
        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    // Add picker popup styling
    '& .MuiPaper-root': {
        backgroundColor: 'var(--input-bg)',
        color: 'var(--text-primary)',
    },
    '& .MuiPickersDay-root': {
        color: 'var(--text-primary)',
        '&:hover': {
            backgroundColor: 'var(--surface-bg-lighter)',
        },
        '&.Mui-selected': {
            backgroundColor: 'var(--primary-button)',
            '&:hover': {
                backgroundColor: 'var(--primary-button-hover)',
            },
        },
        '&.Mui-selected.Mui-selected': {
            backgroundColor: 'var(--primary-button)',
            color: 'white',
        },
    },
    '& .MuiPickersCalendarHeader-root': {
        color: 'var(--text-primary)',
    },
    '& .MuiPickersDay-today': {
        borderColor: 'var(--primary-button)',
    },
    '& .MuiClock-pin': {
        backgroundColor: 'var(--primary-button)',
    },
    '& .MuiClockPointer-root': {
        backgroundColor: 'var(--primary-button)',
        '& .MuiClockPointer-thumb': {
            backgroundColor: 'var(--primary-button)',
            borderColor: 'var(--primary-button)',
        },
    },
    '& .MuiClockNumber-root': {
        color: 'var(--text-primary)',
        '&.Mui-selected': {
            backgroundColor: 'var(--primary-button)',
        },
    },
    '& .MuiPickersYear-root': {
        color: 'var(--text-primary)',
        '&:hover': {
            backgroundColor: 'var(--surface-bg-lighter)',
        },
        '&.Mui-selected': {
            backgroundColor: 'var(--primary-button)',
            color: 'white',
            '&:hover': {
                backgroundColor: 'var(--primary-button-hover)',
            },
        },
        '&.Mui-selected.Mui-selected': {
            backgroundColor: 'var(--primary-button)',
            color: 'white',
        },
    },
    '& .MuiPickersMonth-root': {
        color: 'var(--text-primary)',
        '&:hover': {
            backgroundColor: 'var(--surface-bg-lighter)',
        },
        '&.Mui-selected': {
            backgroundColor: 'var(--primary-button)',
            color: 'white',
            '&:hover': {
                backgroundColor: 'var(--primary-button-hover)',
            },
        },
        '&.Mui-selected.Mui-selected': {
            backgroundColor: 'var(--primary-button)',
            color: 'white',
        },
    },
    '& .MuiYearCalendar-root': {
        backgroundColor: 'var(--input-bg)',
    },
    '& .MuiMonthCalendar-root': {
        backgroundColor: 'var(--input-bg)',
    },
    '& .MuiPickersYear-root.Mui-selected': {
        backgroundColor: 'var(--primary-button)',
        color: 'white',
        '&:hover': {
            backgroundColor: 'var(--primary-button-hover)',
        },
    },
    '& .MuiPickersYear-yearButton.Mui-selected': {
        backgroundColor: 'var(--primary-button)',
        color: 'white',
        '&:hover': {
            backgroundColor: 'var(--primary-button-hover)',
        },
    },
}));

// Update the slot props to include dialog styling
export const dateTimePickerSlotProps = {
    textField: {
        size: "small"
    },
    dialog: {
        sx: {
            "& .MuiDialog-paper": {
                backgroundColor: "var(--input-bg)",
            },
            "& .MuiDialogActions-root": {
                backgroundColor: "var(--input-bg)",
                borderTop: "1px solid var(--surface-border)",
            },
            "& .MuiButtonBase-root": {
                color: "var(--text-primary)",
                "&:hover": {
                    backgroundColor: "var(--surface-bg-lighter)",
                },
            },
            "& .MuiPickersLayout-root": {
                backgroundColor: "var(--input-bg)",
                color: "var(--text-primary)",
            },
            "& .MuiPickersToolbar-root": {
                backgroundColor: "var(--input-bg)",
                color: "var(--text-primary)",
            },
            "& .MuiPickersDay-root": {
                color: "var(--text-primary)",
                "&:hover": {
                    backgroundColor: "var(--surface-bg-lighter)",
                },
                "&.Mui-selected": {
                    backgroundColor: "var(--primary-button)",
                    color: "white",
                    "&:hover": {
                        backgroundColor: "var(--primary-button-hover)",
                    },
                },
                "&.Mui-selected.Mui-selected": {
                    backgroundColor: "var(--primary-button)",
                    color: "white",
                },
            },
            "& .MuiPickersMonth-root": {
                color: "var(--text-primary)",
                "&:hover": {
                    backgroundColor: "var(--surface-bg-lighter)",
                },
                "&.Mui-selected": {
                    backgroundColor: "var(--primary-button)",
                    color: "white",
                },
                "&.Mui-selected.Mui-selected": {
                    backgroundColor: "var(--primary-button)",
                    color: "white",
                },
            },
            "& .MuiPickersYear-root": {
                color: "var(--text-primary)",
                "&:hover": {
                    backgroundColor: "var(--surface-bg-lighter)",
                },
                "&.Mui-selected": {
                    backgroundColor: "var(--primary-button)",
                    color: "white",
                    "&:hover": {
                        backgroundColor: "var(--primary-button-hover)",
                    },
                },
                "&.Mui-selected.Mui-selected": {
                    backgroundColor: "var(--primary-button)",
                    color: "white",
                },
            },
            "& .MuiPickersTabsLayout-root": {
                backgroundColor: "var(--input-bg)",
                "& .MuiTab-root": {
                    color: "var(--text-primary)",
                },
                "& .Mui-selected": {
                    color: "var(--primary-button)",
                },
            },
            "& .MuiTabs-indicator": {
                backgroundColor: "var(--primary-button)",
            },
            "& .MuiClock-pin": {
                backgroundColor: "var(--primary-button)",
            },
            "& .MuiClockPointer-root": {
                backgroundColor: "var(--primary-button)",
                "& .MuiClockPointer-thumb": {
                    backgroundColor: "var(--primary-button)",
                    borderColor: "var(--primary-button)",
                },
            },
            "& .MuiClockNumber-root": {
                color: "var(--text-primary)",
                "&.Mui-selected": {
                    backgroundColor: "var(--primary-button)",
                    color: "white",
                },
            },
            "& .MuiYearCalendar-root": {
                backgroundColor: "var(--input-bg)",
            },
            "& .MuiMonthCalendar-root": {
                backgroundColor: "var(--input-bg)",
            },
            "& .MuiPickersLayout-contentWrapper": {
                backgroundColor: "var(--input-bg)",
            },
            "& .MuiTabs-root": {
                backgroundColor: "var(--input-bg)",
                borderBottom: "1px solid var(--surface-border)",
            },
            "& .MuiTab-root": {
                color: "var(--text-primary)",
                opacity: 0.7,
                "&.Mui-selected": {
                    color: "var(--primary-button)",
                    opacity: 1,
                },
            },
            "& .MuiPickersLayout-actionBar": {
                backgroundColor: "var(--input-bg)",
                borderTop: "1px solid var(--surface-border)",
            },
            // Make sure the view background is consistent
            "& .MuiDateCalendar-root, & .MuiYearCalendar-root, & .MuiMonthCalendar-root": {
                backgroundColor: "var(--input-bg)",
            },
            "& .MuiPickersYear-root.Mui-selected, & .MuiPickersYear-yearButton.Mui-selected": {
                backgroundColor: "var(--primary-button)",
                color: "white",
                "&:hover": {
                    backgroundColor: "var(--primary-button-hover)",
                },
            },
        },
    },
};

export const getCommonPickerStyles = (width = 'auto') => ({
    sx: { 
        '& .MuiInputAdornment-root': {
            marginLeft: '0',
            marginRight: '0'
        },
        '& .MuiOutlinedInput-root': {
            paddingRight: '8px',
            width: width === 'auto' ? '95px' : 'auto',
            minWidth: width === 'auto' ? '95px' : '180px'
        },
        '& .MuiOutlinedInput-input': {
            width: 'auto'
        }
    }
}); 
import React, { useState, useEffect, useRef } from 'react';
import WeeklyPlannerSidebar from './permutations/WeeklyPlannerSidebar';
import ProjectsSidebar from './permutations/ProjectsSidebar';
import MeetingsSidebar from './permutations/MeetingsSidebar';
import RecurringTasksSidebar from './permutations/RecurringTasksSidebar';

function Sidebar({ activeTab }) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const sidebarRef = useRef(null);
  const prevTabRef = useRef(activeTab);

  useEffect(() => {
    // Set initial state based on active tab
    if (activeTab === 'weekly-planner') {
      setIsCollapsed(true);
      localStorage.setItem('sidebarCollapsed', 'true');
    } else {
      setIsCollapsed(false);
      localStorage.setItem('sidebarCollapsed', 'false');
    }
    
    prevTabRef.current = activeTab;

    const handleClickOutside = (event) => {
      if (event.target.closest('.MuiPopover-root, .MuiPopper-root, .MuiDialog-root, .MuiPaper-root, [data-modal]')) {
        return;
      }

      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsCollapsed(true);
        localStorage.setItem('sidebarCollapsed', 'true');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeTab]);

  const handleToggleCollapse = () => {
    const newState = !isCollapsed;
    setIsCollapsed(newState);
    localStorage.setItem('sidebarCollapsed', newState);
  };

  const collapseSidebar = () => {
    setIsCollapsed(true);
    localStorage.setItem('sidebarCollapsed', 'true');
  };

  const getPermutationContent = () => {
    switch (activeTab) {
      case 'weekly-planner':
        return {
          title: 'Weekly Planner',
          content: <WeeklyPlannerSidebar onCollapse={collapseSidebar} />
        };
      case 'projects':
        return {
          title: 'Projects',
          content: <ProjectsSidebar onCollapse={collapseSidebar} />
        };
      case 'meetings':
        return {
          title: 'Meetings',
          content: <MeetingsSidebar onCollapse={collapseSidebar} />
        };
      case 'recurring-tasks':
        return {
          title: 'Recurring Tasks',
          content: <RecurringTasksSidebar onCollapse={collapseSidebar} />
        };
      default:
        return { title: '', content: null };
    }
  };

  const { title, content } = getPermutationContent();

  return (
    <div 
      ref={sidebarRef}
      className={`sidebar ${isCollapsed ? 'collapsed' : 'expanded'}`}
    >
      <div className="flex flex-col h-full">
        <div className="p-4 flex-shrink-0">
          <div className={`flex items-center mb-4 ${isCollapsed ? 'justify-center' : 'justify-between'}`}>
            {!isCollapsed && <h2 className="text-xl font-semibold text-[var(--text-primary)] font-sans text-center w-full">{title}</h2>}
            <button
              onClick={handleToggleCollapse}
              className="text-[var(--text-muted)] hover:bg-[var(--surface-bg)] hover:bg-opacity-50 p-2 rounded transition duration-150 ease-in-out flash-button"
            >
              <span className="material-symbols-outlined">
                {isCollapsed ? 'chevron_right' : 'chevron_left'}
              </span>
            </button>
          </div>
        </div>
        {!isCollapsed && (
          <div className="sidebar-content flex-1 overflow-y-auto px-4">
            {content}
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar; 
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditableText from './EditableText';
import api from '../../services/api';
import { 
    toggleTodoUrgent as toggleUrgent, 
    toggleTodoComplete, 
    updateTodoFromServer, 
    editTodoWithAPI,
    toggleTodoDay,
    updateTodoDays
} from '../../store/todosSlice';

function TodoItem({ 
    todo, 
    onToggleComplete, 
    onToggleUrgent, 
    onDelete, 
    onEdit, 
    customActions = [],
    draggable = false,
    onDragStart,
    onDragOver,
    onDrop,
    onDragEnd,
    dragStyles = "",
    allTodos = []
}) {
    const [isEditing, setIsEditing] = useState(false);
    const [isDaysExpanded, setIsDaysExpanded] = useState(false);
    const daysRef = useRef(null);
    const projects = useSelector(state => state.projects.items);
    const meetings = useSelector(state => state.meetings.items);
    const dispatch = useDispatch();

    // Close days selector when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (daysRef.current && !daysRef.current.contains(event.target)) {
                setIsDaysExpanded(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const getSourceDisplay = (source) => {
        if (source?.startsWith('project-')) {
            const projectId = source.replace('project-', '');
            const project = projects.find(p => p.id === projectId);
            return project ? {
                text: `Project: ${project.name}`,
                icon: project.icon || 'folder',
                status: project.status,
                statusKey: project.status?.toLowerCase().replace(/_/g, '-')
            } : {
                text: 'Project',
                icon: 'folder'
            };
        }

        if (source?.startsWith('meeting-')) {
            const meetingId = source.replace('meeting-', '');
            const meeting = meetings.find(m => m.id === meetingId);
            return meeting ? {
                text: `Meeting: ${meeting.name || 'Untitled'}`,
                icon: 'calendar_month',
                tooltip: meeting.date ? new Date(meeting.date).toLocaleDateString() : ''
            } : {
                text: 'Meeting',
                icon: 'calendar_month'
            };
        }

        if (source === 'weekly') {
            return {
                text: 'Weekly',
                icon: 'calendar_view_week'
            };
        }

        return null;
    };

    const lastMovement = todo.movements?.length > 0 
        ? todo.movements[todo.movements.length - 1] 
        : null;

    const sourceToDisplay = todo.source || (lastMovement ? lastMovement.source : null);
    const sourceDisplay = sourceToDisplay && getSourceDisplay(sourceToDisplay);
    const isInProjectsView = todo.location?.startsWith('project-');

    const handleUrgentToggle = async () => {
        try {
            // Dispatch the action first for optimistic update
            dispatch(toggleUrgent(todo.id));
            
            // Make the API call using the configured api service
            await api.patch(`/todos/${todo.id}`, {
                urgent: !todo.urgent
            });
        } catch (error) {
            // Revert the optimistic update if the API call fails
            dispatch(toggleUrgent(todo.id));
            console.error('Failed to update urgent status:', error);
        }
    };

    const handleCompleteToggle = async () => {
        try {
            // Dispatch the action first for optimistic update
            dispatch(toggleTodoComplete(todo.id));
            
            // Make the API call using the configured api service
            const response = await api.patch(`/todos/${todo.id}`, {
                completed: !todo.completed
            });

            // Update related todos if any
            if (response.data.related) {
                response.data.related.forEach(relatedTodo => {
                    dispatch(updateTodoFromServer(relatedTodo));
                });
            }
        } catch (error) {
            // Revert the optimistic update if the API call fails
            dispatch(toggleTodoComplete(todo.id));
            console.error('Failed to update completed status:', error);
        }
    };

    const handleDayToggle = async (day) => {
        try {
            // If clicking the already selected day, deselect it
            const newDays = todo.days?.includes(day) ? [] : [day];
            
            // Optimistic update
            dispatch(toggleTodoDay({ todoId: todo.id, day }));
            
            // Update in backend
            dispatch(updateTodoDays({ todoId: todo.id, days: newDays }));
        } catch (error) {
            console.error('Failed to update todo days:', error);
            // Revert optimistic update
            dispatch(toggleTodoDay({ todoId: todo.id, day }));
        }
    };

    const dayLabels = useSelector(state => state.todos.dayLabels);
    const days = [
        { key: 'sunday', label: dayLabels.sunday },
        { key: 'monday', label: dayLabels.monday },
        { key: 'tuesday', label: dayLabels.tuesday },
        { key: 'wednesday', label: dayLabels.wednesday },
        { key: 'thursday', label: dayLabels.thursday },
        { key: 'friday', label: dayLabels.friday },
        { key: 'saturday', label: dayLabels.saturday }
    ];

    const getSelectedDayLabel = () => {
        if (!todo.days?.length) return 'Day';
        const selectedDay = days.find(d => d.key === todo.days[0]);
        return selectedDay?.label || 'Day';
    };

    return (
        <div 
            className={`flex flex-col sm:flex-row items-start sm:items-center gap-2 p-2 border rounded-md ${
                todo.urgent ? 'bg-red-900/20 border-red-700/20' : 'bg-[var(--surface-bg-darker)] border-[var(--surface-border)]'
            } group ${dragStyles} ${isEditing ? 'editing' : ''}`}
            draggable={draggable && !isEditing}
            onDragStart={(e) => onDragStart(e, todo)}
            onDragOver={(e) => onDragOver(e, todo)}
            onDrop={(e) => onDrop(e, todo)}
            onDragEnd={onDragEnd}
        >
            {/* First row: checkbox, source badge, and text */}
            <div className={`flex ${
                sourceDisplay && !isInProjectsView && 
                ((todo.location === 'weekly' && sourceToDisplay !== 'weekly') || 
                (todo.location.startsWith('project-') && !sourceToDisplay?.startsWith('project-')))
                ? "flex-col sm:flex-row" 
                : "flex-row"
            } flex-1 w-full gap-2 min-w-0`}>
                <div className="flex items-center gap-2 shrink-0">
                    <input
                        type="checkbox"
                        checked={todo.completed}
                        onChange={handleCompleteToggle}
                        className="w-5 h-5 rounded appearance-none border-2 border-[var(--border-dark)] bg-[var(--input-bg)] checked:bg-[var(--input-bg)] checked:border-[var(--border-dark)] hover:border-[var(--border-light)] transition-colors cursor-pointer relative checked:after:content-['✓'] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:text-[var(--text-muted)] checked:after:text-sm shrink-0"
                    />
                    
                    {/* Source Badge */}
                    {sourceDisplay && !isInProjectsView && (
                        ((todo.location === 'weekly' && sourceToDisplay !== 'weekly') || 
                        (todo.location.startsWith('project-') && !sourceToDisplay?.startsWith('project-'))) && (
                            <span 
                                className="flex items-center gap-1 text-[10px] sm:text-xs px-1.5 sm:px-2 py-0.5 sm:py-1 rounded-full bg-[var(--surface-bg-lighter)] border border-[var(--surface-border)] text-[var(--text-disabled)] max-w-[200px] shrink"
                                title={`${sourceDisplay.text}${sourceDisplay.tooltip ? ` (${sourceDisplay.tooltip})` : ''}`}
                            >
                                <span className="material-symbols-outlined text-[12px] sm:text-sm shrink-0">
                                    {sourceDisplay.icon}
                                </span>
                                <span className="truncate">
                                    {sourceDisplay.text}
                                </span>
                                {sourceDisplay.status && (
                                    <div 
                                        className="w-2 h-2 rounded-full shrink-0"
                                        style={{ 
                                            backgroundColor: `var(--status-${sourceDisplay.statusKey})` 
                                        }}
                                    />
                                )}
                            </span>
                        )
                    )}
                </div>

                <div className="flex-1 min-w-0">
                    <EditableText 
                        text={todo.text}
                        completed={todo.completed}
                        urgent={todo.urgent}
                        onEdit={(newText) => {
                            dispatch(editTodoWithAPI({ todoId: todo.id, text: newText }))
                                .catch(error => {
                                    console.error('Failed to update todo:', error);
                                });
                        }}
                        onEditStateChange={setIsEditing}
                    />
                </div>
            </div>

            {/* Second row: days and actions */}
            <div className="flex items-center gap-2 sm:ml-auto">
                {/* Day Selection Buttons - Only show in weekly view */}
                {todo.location === 'weekly' && (
                    <div 
                        ref={daysRef}
                        className="relative"
                    >
                        <div className="sm:hidden">
                            <button
                                onClick={() => setIsDaysExpanded(!isDaysExpanded)}
                                className={`min-w-6 h-6 px-1 text-xs rounded-full flex items-center justify-center transition-colors ${
                                    todo.days?.length
                                        ? `${todo.urgent ? 'bg-red-500' : 'bg-primary'} text-white`
                                        : `${todo.urgent 
                                            ? 'bg-red-900/20' 
                                            : 'bg-[var(--surface-border)]'} text-[var(--text-disabled)]`
                                }`}
                            >
                                {getSelectedDayLabel()}
                            </button>
                            {isDaysExpanded && (
                                <div className="absolute z-10 top-8 -left-2 bg-[var(--surface-bg)] border border-[var(--surface-border)] rounded-md p-2 shadow-lg">
                                    <div className="flex gap-1">
                                        {days.map(({ key, label }) => (
                                            <button
                                                key={key}
                                                onClick={() => {
                                                    handleDayToggle(key);
                                                    setIsDaysExpanded(false);
                                                }}
                                                className={`min-w-6 h-6 px-1 text-xs rounded-full flex items-center justify-center transition-colors ${
                                                    todo.days?.includes(key)
                                                        ? `${todo.urgent ? 'bg-red-500' : 'bg-primary'} text-white`
                                                        : `${todo.urgent 
                                                            ? 'bg-red-900/20' 
                                                            : 'bg-[var(--surface-border)]'} text-[var(--text-disabled)]`
                                                }`}
                                                title={key.charAt(0).toUpperCase() + key.slice(1)}
                                            >
                                                {label}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="hidden sm:flex flex-wrap gap-1">
                            {days.map(({ key, label }) => (
                                <button
                                    key={key}
                                    onClick={() => handleDayToggle(key)}
                                    className={`min-w-6 h-6 px-1 text-xs rounded-full flex items-center justify-center transition-colors ${
                                        todo.days?.includes(key)
                                            ? `${todo.urgent ? 'bg-red-500' : 'bg-primary'} text-white`
                                            : `${todo.urgent 
                                                ? 'bg-red-900/20' 
                                                : 'bg-[var(--surface-border)]'} text-[var(--text-disabled)]`
                                    }`}
                                    title={key.charAt(0).toUpperCase() + key.slice(1)}
                                >
                                    {label}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
                
                {/* Action Buttons */}
                <div className="flex gap-1 transition-opacity">
                    {/* Custom Actions */}
                    <div className="flex sm:gap-1">
                        {customActions.map((action, index) => {
                            // For project todos moving to weekly, show a simpler UI
                            const isProjectToWeeklyMove = action.icon === 'drive_file_move' && 
                                todo.location.startsWith('project-');
                                
                            const isInWeekly = isProjectToWeeklyMove && 
                                allTodos.some(t => 
                                    t.location === 'weekly' && 
                                    t.projectId === todo.id
                                );

                            // For weekly todos moving to project, show a simpler UI
                            const isWeeklyToProjectMove = action.icon === 'drive_file_move' && 
                                todo.location === 'weekly';

                            const isInProject = isWeeklyToProjectMove && todo.projectId;

                            // Determine if we should show a checkmark
                            const showCheckmark = isInWeekly || isInProject;

                            return (
                                <button
                                    key={index}
                                    onClick={() => !showCheckmark && action.onClick(todo)}
                                    className={`p-1 rounded ${
                                        showCheckmark 
                                            ? 'text-[var(--text-disabled)]'  // Remove hover effect for linked state
                                            : todo.urgent 
                                                ? 'hover:bg-red-900/30 text-[var(--text-disabled)] hover:text-[var(--text-secondary)]' 
                                                : 'hover:bg-[var(--button-hover-bg)] text-[var(--text-disabled)] hover:text-[var(--text-secondary)]'
                                    }`}
                                    title={showCheckmark ? 'Already linked' : action.label}
                                >
                                    <span className="material-symbols-outlined text-xl">
                                        {showCheckmark ? 'check' : action.icon}
                                    </span>
                                </button>
                            );
                        })}

                        {/* Urgent and Delete buttons */}
                        <button
                            onClick={handleUrgentToggle}
                            className={`p-1 rounded ${
                                todo.urgent 
                                    ? 'hover:bg-red-900/30 text-red-500 hover:text-red-400' 
                                    : 'hover:bg-[var(--button-hover-bg)] text-[var(--text-disabled)] hover:text-[var(--text-secondary)]'
                            }`}
                            title="Toggle Urgency"
                        >
                            <span className="material-symbols-outlined text-xl">priority_high</span>
                        </button>
                        
                        <button
                            onClick={() => onDelete(todo.id)}
                            className={`p-1 rounded ${
                                todo.urgent 
                                    ? 'hover:bg-red-900/30 text-[var(--text-disabled)] hover:text-[var(--text-secondary)]' 
                                    : 'hover:bg-[var(--button-hover-bg)] text-[var(--text-disabled)] hover:text-[var(--text-secondary)]'
                            }`}
                            title="Delete"
                        >
                            <span className="material-symbols-outlined text-xl">delete</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TodoItem; 
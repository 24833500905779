import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';

function AutoResizingTextarea({ 
    value, 
    onChange, 
    className = '', 
    placeholder = '', 
    savedHeight = null,
    onHeightChange = () => {},
    ...props 
}) {
    const textareaRef = useRef(null);
    const [isManuallyResized, setIsManuallyResized] = useState(false);
    const [manualHeight, setManualHeight] = useState(savedHeight);
    const resizeObserverRef = useRef(null);
    const previousHeightRef = useRef(null);
    const isResizingRef = useRef(false);

    // Function to calculate and set the appropriate height
    const adjustHeight = useCallback(() => {
        const textarea = textareaRef.current;
        if (!textarea || isResizingRef.current) return;

        // Calculate height constants
        const computedStyle = window.getComputedStyle(textarea);
        const lineHeight = parseInt(computedStyle.lineHeight);
        const paddingTop = parseInt(computedStyle.paddingTop);
        const paddingBottom = parseInt(computedStyle.paddingBottom);
        const minHeight = lineHeight + paddingTop + paddingBottom; // 1 line
        const maxAutoHeight = (lineHeight * 10) + paddingTop + paddingBottom; // 10 lines

        // Reset height to auto to get the correct scrollHeight
        textarea.style.height = 'auto';
        const contentHeight = textarea.scrollHeight;
        
        // Switch back to auto-expanding if content exceeds current manual height
        if (isManuallyResized && 
            manualHeight && 
            contentHeight > manualHeight) {
            setIsManuallyResized(false);
            setManualHeight(null);
            onHeightChange(null);
            return; // Let the next render handle the auto-height
        }

        // If manually resized and still in manual mode, maintain that height
        if (isManuallyResized && manualHeight) {
            textarea.style.height = `${manualHeight}px`;
            if (previousHeightRef.current !== manualHeight) {
                onHeightChange(manualHeight);
                previousHeightRef.current = manualHeight;
            }
            return;
        }

        // Calculate new height based on content
        const newHeight = Math.min(contentHeight, maxAutoHeight);
        const finalHeight = Math.max(newHeight, minHeight);
        textarea.style.height = `${finalHeight}px`;
        
        // Notify if height changed in auto mode
        if (previousHeightRef.current !== finalHeight) {
            onHeightChange(isManuallyResized ? finalHeight : null);
            previousHeightRef.current = finalHeight;
        }
    }, [isManuallyResized, manualHeight, onHeightChange]);

    // Create the resize handler
    const resizeHandler = useCallback((entries) => {
        if (!isResizingRef.current) return;
        
        const entry = entries[0];
        if (!entry) return;

        const textarea = textareaRef.current;
        if (!textarea) return;

        const newHeight = entry.contentRect.height;
        if (newHeight !== previousHeightRef.current) {
            setIsManuallyResized(true);
            setManualHeight(newHeight);
            onHeightChange(newHeight);
            previousHeightRef.current = newHeight;
        }
    }, [setIsManuallyResized, setManualHeight, onHeightChange]);

    // Create debounced version of the handler
    const debouncedResizeHandler = useMemo(
        () => debounce(resizeHandler, 16),
        [resizeHandler]
    );

    // Clean up debounced handler on unmount
    useEffect(() => {
        return () => {
            debouncedResizeHandler.cancel();
        };
    }, [debouncedResizeHandler]);

    // Handle manual resize
    useEffect(() => {
        const textarea = textareaRef.current;
        if (!textarea) return;

        // Create ResizeObserver to detect manual resizes
        resizeObserverRef.current = new ResizeObserver((entries) => {
            requestAnimationFrame(() => {
                debouncedResizeHandler(entries);
            });
        });

        resizeObserverRef.current.observe(textarea);

        return () => {
            if (resizeObserverRef.current) {
                resizeObserverRef.current.disconnect();
            }
        };
    }, [debouncedResizeHandler]);

    // Adjust height on content change
    useEffect(() => {
        if (textareaRef.current) {
            requestAnimationFrame(adjustHeight);
        }
    }, [value, isManuallyResized, manualHeight, adjustHeight]);

    // Set initial height from saved value
    useEffect(() => {
        if (savedHeight) {
            setIsManuallyResized(true);
            setManualHeight(savedHeight);
            previousHeightRef.current = savedHeight;
        }
    }, [savedHeight]);

    // Handle resize start
    const handleMouseDown = (e) => {
        // Check if the click is in the resize handle area (bottom-right corner)
        const textarea = textareaRef.current;
        if (!textarea) return;

        const rect = textarea.getBoundingClientRect();
        const isInResizeHandle = 
            e.clientX >= rect.right - 16 && // Within 16px of right edge
            e.clientY >= rect.bottom - 16;   // Within 16px of bottom edge

        if (isInResizeHandle) {
            isResizingRef.current = true;
            if (!isManuallyResized) {
                previousHeightRef.current = textarea.clientHeight;
            }
        }
    };

    // Handle resize end
    const handleMouseUp = () => {
        isResizingRef.current = false;
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    return (
        <textarea
            ref={textareaRef}
            value={value}
            onChange={onChange}
            className={`resize-y ${className}`}
            placeholder={placeholder}
            onMouseDown={handleMouseDown}
            style={{
                minHeight: '2.5rem', // Approximately 1 line
                maxHeight: '15rem', // Always max 10 lines
                height: manualHeight ? `${manualHeight}px` : 'auto'
            }}
            {...props}
        />
    );
}

export default AutoResizingTextarea; 
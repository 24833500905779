import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { exportStoreData, importStoreData } from '../../../store/backupUtils';

const BackupSettings = () => {
    const dispatch = useDispatch();
    const [importing, setImporting] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [error, setError] = useState(null);

    const handleExport = async () => {
        try {
            setExporting(true);
            setError(null);
            const blob = await exportStoreData();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `bearly-functional-backup-${new Date().toISOString().split('T')[0]}.json`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (err) {
            setError('Failed to export data: ' + err.message);
        } finally {
            setExporting(false);
        }
    };

    const handleImport = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            setImporting(true);
            setError(null);
            await importStoreData(file, dispatch);
        } catch (err) {
            setError('Failed to import data: ' + err.message);
        } finally {
            setImporting(false);
            // Reset the file input
            event.target.value = '';
        }
    };

    return (
        <div className="p-4 sm:p-6 space-y-6">
            {/* Export Section */}
            <div className="space-y-4 border border-[var(--surface-border)] rounded-lg p-3 sm:p-4">
                <h4 className="text-sm font-bold text-[var(--text-primary)] text-left">Export Data</h4>
                <p className="text-sm text-[var(--text-secondary)]">
                    Create a backup of all your data. These are stored in a .json file.
                </p>
                <button
                    onClick={handleExport}
                    disabled={exporting}
                    className="base-button w-full px-4 py-3 rounded flex items-center gap-2 justify-center"
                >
                    <span className="material-symbols-outlined">backup</span>
                    {exporting ? 'Exporting...' : 'Export Backup'}
                </button>
            </div>

            {/* Import Section */}
            <div className="space-y-4 border border-[var(--surface-border)] rounded-lg p-3 sm:p-4">
                <h4 className="text-sm font-bold text-[var(--text-primary)] text-left">Import Data</h4>
                <p className="text-sm text-[var(--text-secondary)]">
                    Restore your data from a previous backup file.
                </p>
                <div className="flex flex-col gap-2">
                    <label
                        htmlFor="backup-file"
                        className={`base-button w-full px-4 py-3 rounded flex items-center gap-2 justify-center cursor-pointer
                            ${importing ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        <span className="material-symbols-outlined">restore</span>
                        {importing ? 'Importing...' : 'Import Backup'}
                        <input
                            id="backup-file"
                            type="file"
                            accept=".json"
                            onChange={handleImport}
                            disabled={importing}
                            className="hidden"
                        />
                    </label>
                    <p className="text-xs text-[var(--text-muted)]">
                        Only .json backup files are supported
                    </p>
                </div>
            </div>

            {/* Error Display */}
            {error && (
                <div className="p-3 rounded bg-[var(--error)] bg-opacity-10 border border-[var(--error)] text-sm">
                    {error}
                </div>
            )}
        </div>
    );
};

export default BackupSettings; 
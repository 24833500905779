import { useState } from 'react';

export const useRecurringTaskDragAndDrop = ({ tasks, onReorderTasks }) => {
  const [draggedTask, setDraggedTask] = useState(null);
  const [dragOverTask, setDragOverTask] = useState(null);

  const handleDragStart = (e, task) => {
    setDraggedTask(task);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e, task) => {
    e.preventDefault();
    if (task.id !== draggedTask?.id) {
      setDragOverTask(task);
    }
  };

  const handleDrop = (e, dropTarget) => {
    e.preventDefault();
    
    if (!draggedTask || draggedTask.id === dropTarget.id) {
      return;
    }

    const reorderedTasks = [...tasks];
    const draggedIndex = tasks.findIndex(t => t.id === draggedTask.id);
    const dropIndex = tasks.findIndex(t => t.id === dropTarget.id);

    // Remove dragged item
    reorderedTasks.splice(draggedIndex, 1);
    // Insert at new position
    reorderedTasks.splice(dropIndex, 0, draggedTask);

    onReorderTasks(reorderedTasks);
    setDraggedTask(null);
    setDragOverTask(null);
  };

  const handleDragEnd = () => {
    setDraggedTask(null);
    setDragOverTask(null);
  };

  const getDragStyles = (task) => {
    if (!draggedTask) return '';

    if (task.id === draggedTask.id) {
      return 'opacity-50';
    }

    if (task.id === dragOverTask?.id) {
      const draggedIndex = tasks.findIndex(t => t.id === draggedTask.id);
      const dropIndex = tasks.findIndex(t => t.id === task.id);
      
      return dropIndex > draggedIndex 
        ? 'translate-y-[-2px] border-b-2 border-[var(--text-primary)]' 
        : 'translate-y-[2px] border-t-2 border-[var(--text-primary)]';
    }

    return '';
  };

  return {
    handleDragStart,
    handleDragOver,
    handleDrop,
    handleDragEnd,
    getDragStyles
  };
}; 
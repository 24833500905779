import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import TodoList from '../TodoList';
import { useTodoList } from '../../hooks/useTodoList';
import MoveToModal from '../TodoList/MoveToModal';
import RecurringTaskWeekly from '../RecurringTaskWeekly/RecurringTaskWeekly';
import PingList from './PingList';
import { useRecurringTasks } from '../../hooks/useRecurringTasks';
import { fetchWeeklyPlanner, updateWeeklyNotes, setWeeklyNotesState } from '../../store/weeklyPlannerSlice';
import { convertTodoToPing } from '../../store/todosSlice';
import AutoResizingTextarea from '../common/AutoResizingTextarea';

function WeeklyPlanner() {
    const dispatch = useDispatch();
    const { notes, notesHeight, status } = useSelector(state => state.weeklyPlanner);
    
    const { 
        todos, 
        handlers, 
        moveActions,
        moveModalOpen,
        setMoveModalOpen,
        selectedTodoForMove,
        allTodos
    } = useTodoList('weekly');

    const { tasks: recurringTasks } = useRecurringTasks();

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchWeeklyPlanner());
        }
    }, [status, dispatch]);

    // Create a stable debounced function using useRef
    const debouncedUpdate = React.useRef(
        debounce(async (notes, height) => {
            try {
                await dispatch(updateWeeklyNotes({ notes, notesHeight: height })).unwrap();
            } catch (error) {
                console.error('Failed to update notes:', error);
            }
        }, 1000)
    ).current;

    const handleNotesChange = (event) => {
        const newValue = event.target.value;
        // Update local state immediately
        dispatch(setWeeklyNotesState({ notes: newValue }));
        // Debounce the API call
        debouncedUpdate(newValue, notesHeight);
    };

    const handleNotesHeightChange = (height) => {
        // Update local state immediately
        dispatch(setWeeklyNotesState({ notesHeight: height }));
        // Debounce the API call
        debouncedUpdate(notes, height);
    };

    // Cleanup on unmount
    React.useEffect(() => {
        return () => {
            debouncedUpdate.cancel();
        };
    }, [debouncedUpdate]);

    const customActions = [
        {
            icon: 'notifications',
            label: 'Convert to Ping',
            onClick: (todo) => {
                dispatch(convertTodoToPing(todo.id));
            }
        }
    ];

    return (
        <div className="space-y-6">
            <h2 className="text-2xl font-semibold mb-4">Weekly Planner</h2>
            
            {/* Weekly Notes Section */}
            <div className="mb-6">
                <h3 className="text-lg font-medium mb-2 text-left">Notes</h3>
                <AutoResizingTextarea
                    value={notes || ''}
                    onChange={handleNotesChange}
                    onHeightChange={handleNotesHeightChange}
                    savedHeight={notesHeight}
                    className="w-full p-3 bg-[var(--input-bg)] border border-[var(--input-border)] focus:border-[var(--input-border-hover)] focus:outline-none rounded-md"
                    placeholder="Add your weekly notes here..."
                />
            </div>

            {/* Todo List Section */}
            <div className="space-y-6">
                <TodoList
                    todos={todos}
                    source="weekly"
                    onAddTodo={handlers.handleAddTodo}
                    onToggleComplete={handlers.handleToggleComplete}
                    onToggleUrgent={handlers.handleToggleUrgent}
                    onDelete={handlers.handleDelete}
                    onEdit={handlers.handleEdit}
                    onReorderTodos={handlers.handleReorderTodos}
                    onClearCompleted={handlers.handleClearCompleted}
                    customActions={[...moveActions, ...customActions]}
                    allTodos={allTodos}
                />

                {/* Ping List Section */}
                <PingList />

                {/* Recurring Tasks Section */}
                <RecurringTaskWeekly tasks={recurringTasks} />
            </div>

            {/* Move To Modal */}
            {moveModalOpen && selectedTodoForMove && (
                <MoveToModal
                    todo={selectedTodoForMove}
                    onMove={handlers.handleMove}
                    onClose={() => setMoveModalOpen(false)}
                />
            )}
        </div>
    );
}

export default WeeklyPlanner;

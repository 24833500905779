import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { Outlet, useOutletContext, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from './store';
import { fetchProjects } from './store/projectsSlice';
import { fetchMeetings } from './store/meetingsSlice';
import { fetchTodos } from './store/todosSlice';
import { fetchRecurringTasks } from './store/recurringTasksSlice';
import { fetchWeeklyPlanner } from './store/weeklyPlannerSlice';
import { fetchSettings } from './store/settingsSlice';
import { updateSetting } from './store/settingsSlice';
import { initializeTheme } from './themes/themeInit';
import api from './services/api';
import doodlesLoading from './images/doodles_loading.png'

function AppWithData({ children }) {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings.settings);
  const settingsStatus = useSelector(state => state.settings.status);
  const [isThemeLoading, setIsThemeLoading] = useState(true);

  useEffect(() => {
    // Only fetch data if user is authenticated
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(fetchProjects());
      dispatch(fetchMeetings());
      dispatch(fetchTodos());
      dispatch(fetchRecurringTasks());
      dispatch(fetchWeeklyPlanner());
      dispatch(fetchSettings());
    }
  }, [dispatch]);

  // Initialize theme when settings are loaded
  useEffect(() => {
    const loadTheme = async () => {
      try {
        setIsThemeLoading(true);
        await initializeTheme(settings?.selected_theme);
      } catch (error) {
        console.error('Error initializing theme:', error);
      } finally {
        setIsThemeLoading(false);
      }
    };

    // Load theme if settings are loaded or if we're not authenticated
    const token = localStorage.getItem('token');
    if (!token || settingsStatus !== 'loading') {
      loadTheme();
    }
  }, [settings?.selected_theme, settingsStatus]);

  // Create basic MUI theme
  const muiTheme = createTheme({
    palette: {
      mode: 'dark'
    }
  });

if (isThemeLoading && settingsStatus === 'loading') {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-[var(--app-bg)] font-sans">
        <img 
          src={doodlesLoading}
          alt="Loading" 
          className="w-24 h-24"
        />
        <p className="mb-4 text-xl sm:text-2xl font-bold">
          Loading&nbsp;
          <span className="animate-ellipsis">
              <span></span>
              <span></span>
              <span></span>
          </span>
        </p>
        <p className="text-sm">Bringing awesomeness and organization to your life</p>
      </div>
    );
 }

  return (
    <ThemeProvider theme={muiTheme}>
      {children}
    </ThemeProvider>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState(null);
  const [collapseHeader, setCollapseHeader] = useState(false);
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    // Clear all localStorage items
    localStorage.clear();
    
    // Reset Redux store state
    store.dispatch({ type: 'RESET_STATE' });
    
    // Reset local state
    setUsername(null);
    setIsAuthenticated(false);
    setCollapseHeader(false);

    // Redirect to login
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    const storedToken = localStorage.getItem('token');
    const storedCollapse = localStorage.getItem('collapseHeader');

    if (storedUsername && storedToken) {
      // Validate token first
      api.get('/auth/validate')
        .then(() => {
          setUsername(storedUsername);
          setIsAuthenticated(true);
          setCollapseHeader(storedCollapse === 'true');
          
          // Fetch fresh data for the user
          store.dispatch(fetchProjects());
          store.dispatch(fetchMeetings());
          store.dispatch(fetchTodos());
          store.dispatch(fetchRecurringTasks());
          store.dispatch(fetchWeeklyPlanner());
          store.dispatch(fetchSettings());
        })
        .catch(() => {
          // Token is invalid, clear everything and redirect to login
          handleLogout();
        });
    } else {
      // Redirect to login if not authenticated
      navigate('/login');
    }
  }, [handleLogout, navigate]);

  return (
    <Provider store={store}>
      <AppWithData>
        <AppWithSettings>
          {({ activeTab, handleTabChange }) => (
            <>
              <CssBaseline />
              <div className="App">
                {isAuthenticated && (
                  <div className="flex flex-col min-h-screen">
                    <Header 
                      onLogout={handleLogout}
                      collapseHeader={collapseHeader}
                      username={username}
                    />
                    <div className="flex flex-1">
                      <Sidebar 
                        activeTab={activeTab}
                        setActiveTab={handleTabChange}
                      />
                      <Outlet context={{
                        activeTab,
                        collapseHeader,
                        onTabChange: handleTabChange
                      }} />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </AppWithSettings>
      </AppWithData>
    </Provider>
  );
}

// Create a new component to handle the settings and tab persistence
function AppWithSettings({ children }) {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings.settings);
  const settingsStatus = useSelector(state => state.settings.status);
  // Initialize with null to indicate "not yet loaded" state
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (settingsStatus === 'succeeded' && settings) {
      // Only set the active tab once when settings load
      if (activeTab === null) {
        const savedTab = settings.lastActiveTab || 'weekly-planner';
        setActiveTab(savedTab);
        
        // Only update settings if there's no lastActiveTab
        if (!settings.lastActiveTab) {
          dispatch(updateSetting({ key: 'lastActiveTab', value: savedTab }));
        }
      }
    } else if (settingsStatus === 'failed' || !localStorage.getItem('token')) {
      // Fallback if settings fail to load or user is not authenticated
      setActiveTab('weekly-planner');
    }
  }, [settings, settingsStatus, dispatch, activeTab]);

  const handleTabChange = useCallback((tab) => {
    setActiveTab(tab);
    dispatch(updateSetting({ key: 'lastActiveTab', value: tab }));
  }, [dispatch]);

  // Don't render children until we have determined the initial active tab
  if (activeTab === null) {
    return null;
  }

  return children({
    activeTab,
    handleTabChange
  });
}

export default App;

export function useDashboardProps() {
  return useOutletContext();
}
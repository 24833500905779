import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addMeeting, deleteMeeting } from '../../../store/meetingsSlice';
import format from 'date-fns/format';

function MeetingsSidebar({ onCollapse }) {
    const navigate = useNavigate();
    const { meetingId } = useParams();
    const dispatch = useDispatch();
    const meetings = useSelector(state => state.meetings.items);

    const [searchTerm, setSearchTerm] = useState('');
    const [deleteConfirmId, setDeleteConfirmId] = useState(null);
    const [sortAscending, setSortAscending] = useState(true);

    const handleAddMeeting = async () => {
        const result = await dispatch(addMeeting({
            name: "New Meeting",
            date: new Date().toISOString(),
            notes: "",
            status: "scheduled"
        })).unwrap();
        navigate(`/meetings/${result.id}`);
        onCollapse();
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        if (deleteConfirmId === id) {
            dispatch(deleteMeeting(id));
            setDeleteConfirmId(null);
            if (meetingId === id) {
                if (meetings.length > 1) {
                    const nextMeeting = meetings.find(m => m.id !== id);
                    if (nextMeeting) {
                        navigate(`/meetings/${nextMeeting.id}`);
                    }
                } else {
                    navigate('/meetings');
                }
            }
        } else {
            setDeleteConfirmId(id);
        }
    };

    const handleCancelDelete = (e) => {
        e.stopPropagation();
        setDeleteConfirmId(null);
    };

    const filteredMeetings = meetings.filter(meeting => 
        meeting.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedAndFilteredMeetings = filteredMeetings
        .sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return sortAscending ? dateA - dateB : dateB - dateA;
        });

    return (
        <div className="space-y-2">
            <div className="space-y-2">
                <input
                    type="text"
                    placeholder="Search meetings..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full px-3 py-2 text-sm bg-[var(--surface-bg)] border border-[var(--surface-bg-lighter)] focus:border-[var(--text-primary)] focus:outline-none focus:ring-0 rounded-md text-[var(--text-secondary)] placeholder-[var(--text-disabled)]"
                />

                <div className="flex justify-end">
                    <button
                        onClick={() => setSortAscending(!sortAscending)}
                        className="px-2 py-1 text-xs bg-[var(--surface-bg)] hover:bg-[var(--surface-bg-lighter)] rounded-md flex items-center gap-1 text-[var(--text-muted)] hover:text-[var(--text-secondary)] flash-button"
                        title={sortAscending ? "Earliest to Latest" : "Latest to Earliest"}
                    >
                        <span className="material-symbols-outlined text-[16px]">
                            {sortAscending ? "arrow_upward" : "arrow_downward"}
                        </span>
                        Sort by date
                    </button>
                </div>

                <button
                    onClick={handleAddMeeting}
                    className="w-full px-3 py-2 text-sm bg-[var(--primary-button)] hover:bg-[var(--primary-button-hover)] rounded-md flex items-center gap-2 justify-center"
                >
                    <span className="material-symbols-outlined text-[20px]">add</span>
                    New Meeting
                </button>
            </div>

            <div className="space-y-1">
                {sortedAndFilteredMeetings.map((meeting) => (
                    <div
                        key={meeting.id}
                        className="relative"
                    >
                        <div className={`
                            flex items-center justify-between rounded-md
                            ${meetingId === meeting.id 
                                ? 'bg-[var(--selection-bg)] text-[var(--text-primary)]'
                                : 'text-[var(--text-secondary)] hover:bg-[var(--surface-bg)]'
                            }
                        `}>
                            <button
                                onClick={() => navigate(`/meetings/${meeting.id}`)}
                                className="flex-1 px-3 py-2 text-left text-sm"
                            >
                                <div className="sidebar-item-content">
                                    <span className="material-symbols-outlined text-[20px]">
                                        event
                                    </span>
                                    <span>{meeting.name}</span>
                                </div>
                                <div className="text-sm text-[var(--text-muted)] ml-7">
                                    {format(new Date(meeting.date), 'MMM dd, yyyy HH:mm')}
                                </div>
                            </button>
                            {deleteConfirmId === meeting.id ? (
                                <div className="flex -space-x-2 pr-2">
                                    <button
                                        onClick={(e) => handleDelete(e, meeting.id)}
                                        className="px-2 py-2 text-red-500 hover:text-red-400"
                                    >
                                        <span className="material-symbols-outlined text-[20px]">
                                            check
                                        </span>
                                    </button>
                                    <button
                                        onClick={handleCancelDelete}
                                        className="px-2 py-2 text-[var(--text-muted)] hover:text-[var(--text-secondary)]"
                                    >
                                        <span className="material-symbols-outlined text-[20px]">
                                            close
                                        </span>
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={(e) => handleDelete(e, meeting.id)}
                                    className="px-2 py-2 text-red-600 hover:text-red-400"
                                >
                                    <span className="material-symbols-outlined text-[20px]">
                                        delete
                                    </span>
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MeetingsSidebar; 
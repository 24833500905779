import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    completePing, 
    editTodoWithAPI, 
    toggleTodoComplete, 
    updateTodoFromServer,
    selectPings,
    selectAllTodos,
    selectAllProjects
} from '../../store/todosSlice';
import EditableText from '../TodoList/EditableText';
import api from '../../services/api';
import doodlesPinged from '../../images/doodles_pinged.png';

function PingList() {
    const dispatch = useDispatch();
    const pings = useSelector(selectPings);
    const allTodos = useSelector(selectAllTodos);
    const projects = useSelector(selectAllProjects);

    const getSourceDisplay = useMemo(() => (source) => {
        if (source?.startsWith('project-')) {
            const projectId = source.replace('project-', '');
            const project = projects.find(p => p.id === projectId);
            return project ? {
                text: `Project: ${project.name}`,
                icon: project.icon || 'folder',
                status: project.status,
                statusKey: project.status?.toLowerCase().replace(/_/g, '-')
            } : null;
        }
        return null;
    }, [projects]);

    const handleComplete = async (ping) => {
        try {
            // First, check if this ping is linked to a project todo
            if (ping.source?.startsWith('project-')) {
                const projectTodoId = ping.projectId;
                if (projectTodoId) {
                    const projectTodo = allTodos.find(t => t.id === projectTodoId);
                    if (projectTodo) {
                        // Mark the project todo as complete
                        dispatch(toggleTodoComplete(projectTodoId));
                        const response = await api.patch(`/todos/${projectTodoId}`, {
                            completed: true
                        });
                        if (response.data.related) {
                            response.data.related.forEach(relatedTodo => {
                                dispatch(updateTodoFromServer(relatedTodo));
                            });
                        }
                    }
                }
            }

            // Then delete the ping
            await api.delete(`/todos/${ping.id}`);
            dispatch(completePing(ping.id));
        } catch (error) {
            console.error('Failed to complete ping:', error);
        }
    };

    const handleEdit = (pingId, newText) => {
        dispatch(editTodoWithAPI({ todoId: pingId, text: newText }))
            .catch(error => {
                console.error('Failed to update ping text:', error);
            });
    };

    if (pings.length === 0) {
        return (
            <div className="space-y-4">
                <h3 className="text-lg font-medium text-left">Ping List</h3>
                <p className="text-[var(--text-disabled)] italic text-left flex items-center gap-2">
                    No pings! Doodles is waiting for new pings!
                    <img 
                        src={doodlesPinged} 
                        alt="Doodles is waiting" 
                        className="w-20 h-20 object-contain"
                    />
                </p>
            </div>
        );
    }

    return (
        <div className="space-y-4">
            <h3 className="text-lg font-medium text-left">Ping List</h3>
            <div className="space-y-2">
                {pings.map(ping => {
                    const sourceDisplay = getSourceDisplay(ping.source);
                    
                    return (
                        <div 
                            key={ping.id}
                            className="flex flex-col sm:flex-row items-start sm:items-center gap-2 p-2 border rounded-md bg-[var(--surface-bg-darker)] border-[var(--surface-border)]"
                        >
                            <div className={`flex ${sourceDisplay ? "flex-col sm:flex-row" : "flex-row"} flex-1 w-full gap-2 min-w-0`}>
                                <div className="flex items-center gap-2 shrink-0">
                                    <input
                                        type="checkbox"
                                        onChange={() => handleComplete(ping)}
                                        className="w-5 h-5 rounded appearance-none border-2 border-[var(--border-dark)] bg-[var(--input-bg)] checked:bg-[var(--input-bg)] checked:border-[var(--border-dark)] hover:border-[var(--border-light)] transition-colors cursor-pointer relative checked:after:content-['✓'] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:text-[var(--text-muted)] checked:after:text-sm shrink-0"
                                    />
                                    
                                    {sourceDisplay && (
                                        <span 
                                            className="flex items-center gap-1 text-[10px] sm:text-xs px-1.5 sm:px-2 py-0.5 sm:py-1 rounded-full bg-[var(--surface-bg-lighter)] border border-[var(--surface-border)] text-[var(--text-disabled)] max-w-[200px] shrink"
                                        >
                                            <span className="material-symbols-outlined text-[12px] sm:text-sm shrink-0">
                                                {sourceDisplay.icon}
                                            </span>
                                            <span className="truncate">
                                                {sourceDisplay.text}
                                            </span>
                                            {sourceDisplay.status && (
                                                <div 
                                                    className="w-2 h-2 rounded-full shrink-0"
                                                    style={{ 
                                                        backgroundColor: `var(--status-${sourceDisplay.statusKey})` 
                                                    }}
                                                />
                                            )}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-1 min-w-0">
                                    <EditableText 
                                        text={ping.text}
                                        onEdit={(newText) => handleEdit(ping.id, newText)}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default PingList; 
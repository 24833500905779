import React, { useState, useRef, useEffect } from 'react';

function EditableText({ text, completed, urgent, onEdit, onEditStateChange }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState(text);
    const inputRef = useRef(null);

    useEffect(() => {
        if (isEditing) {
            inputRef.current?.focus();
            inputRef.current?.select();
        }
    }, [isEditing]);

    useEffect(() => {
        onEditStateChange?.(isEditing);
    }, [isEditing, onEditStateChange]);

    // Auto-adjust height of textarea
    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.style.height = 'auto';
            inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
        }
    }, [isEditing, editedText]);

    const handleSubmit = () => {
        const trimmedText = editedText.trim();
        if (trimmedText && trimmedText !== text) {
            onEdit(trimmedText);
        } else {
            setEditedText(text);
        }
        setIsEditing(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit();
        } else if (e.key === 'Escape') {
            setEditedText(text);
            setIsEditing(false);
        }
    };

    if (isEditing) {
        return (
            <div className="editing w-full">
                <textarea
                    ref={inputRef}
                    value={editedText}
                    onChange={(e) => setEditedText(e.target.value)}
                    onBlur={handleSubmit}
                    onKeyDown={handleKeyDown}
                    rows={1}
                    className={`w-full p-1 rounded bg-[var(--input-bg)] border border-[var(--border-dark)] focus:border-[var(--border-light)] outline-none resize-none overflow-hidden ${
                        urgent ? 'hover:bg-red-900/30 focus:bg-red-900/30' : 'hover:bg-[var(--button-hover-bg)] focus:bg-[var(--button-hover-bg)]'
                    }`}
                />
            </div>
        );
    }

    return (
        <div className="w-full p-1 flex">
            <span
                onClick={() => setIsEditing(true)}
                className={`inline-block max-w-full rounded cursor-pointer px-1 text-left break-words ${
                    completed ? 'text-[var(--text-disabled)] line-through' : ''
                } ${
                    urgent 
                        ? 'hover:bg-red-900/30' 
                        : 'hover:bg-[var(--button-hover-bg)]'
                }`}
            >
                {text}
            </span>
        </div>
    );
}

export default EditableText; 
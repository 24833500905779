import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { 
  addRecurringTask, 
  deleteRecurringTask, 
  setActiveRecurringTask
} from '../../../store/recurringTasksSlice';
import { updateSetting } from '../../../store/settingsSlice';
import { useRecurringTaskDragAndDrop } from '../../../hooks/useRecurringTaskDragAndDrop';
import { useRecurringTasks } from '../../../hooks/useRecurringTasks';

function RecurringTasksSidebar({ onCollapse }) {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tasks: recurringTasks, handleReorderTasks } = useRecurringTasks();
  const activeTask = useSelector(state => state.recurringTasks.activeRecurringTask);
  const [deleteConfirmId, setDeleteConfirmId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (taskId && recurringTasks.length > 0) {
      const task = recurringTasks.find(t => t.id === taskId);
      if (task && (!activeTask || activeTask.id !== taskId)) {
        dispatch(setActiveRecurringTask(task));
      }
    }
  }, [taskId, recurringTasks, activeTask, dispatch]);

  const handleCreateTask = async () => {
    try {
      const newTask = await dispatch(addRecurringTask({
        title: 'New Task',
        scheduleMode: 'fixed',
        scheduleType: 'week',
        frequency: 'weekly',
        preparationTime: 0,
        gracePeriod: 0,
        dayOfWeek: new Date().getDay(),
        dateOfMonth: new Date().getDate(),
        status: 'NOT_STARTED',
        icon: 'calendar_month'
      })).unwrap();
      
      if (newTask) {
        await dispatch(setActiveRecurringTask(newTask));
        dispatch(updateSetting({ key: 'lastOpenedRecurringTaskId', value: newTask.id }));
        navigate(`/recurring-tasks/${newTask.id}`);
        onCollapse();
      }
    } catch (error) {
      console.error('Failed to create task:', error);
    }
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    if (deleteConfirmId === id) {
      dispatch(deleteRecurringTask(id));
      setDeleteConfirmId(null);
      if (activeTask?.id === id) {
        const nextTask = recurringTasks.find(t => t.id !== id);
        if (nextTask) {
          dispatch(setActiveRecurringTask(nextTask));
          dispatch(updateSetting({ key: 'lastOpenedRecurringTaskId', value: nextTask.id }));
          navigate(`/recurring-tasks/${nextTask.id}`);
        } else {
          dispatch(setActiveRecurringTask(null));
          dispatch(updateSetting({ key: 'lastOpenedRecurringTaskId', value: null }));
          navigate('/recurring-tasks');
        }
      }
    } else {
      setDeleteConfirmId(id);
    }
  };

  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setDeleteConfirmId(null);
  };

  const handleSelectTask = (task) => {
    dispatch(setActiveRecurringTask(task));
    dispatch(updateSetting({ key: 'lastOpenedRecurringTaskId', value: task.id }));
    navigate(`/recurring-tasks/${task.id}`);
  };

  const filteredTasks = recurringTasks.filter(task => 
    task.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const {
    handleDragStart,
    handleDragOver,
    handleDrop,
    handleDragEnd,
    getDragStyles
  } = useRecurringTaskDragAndDrop({
    tasks: recurringTasks,
    onReorderTasks: handleReorderTasks
  });

  return (
    <div className="space-y-2">
      <div className="space-y-2">
        <input
          type="text"
          placeholder="Search tasks..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-3 py-2 text-sm bg-[var(--surface-bg)] border border-[var(--surface-bg-lighter)] focus:border-[var(--text-primary)] focus:outline-none rounded-md text-[var(--text-secondary)] placeholder-[var(--text-disabled)]"
        />

        <button
          onClick={handleCreateTask}
          className="w-full px-3 py-2 text-sm bg-[var(--primary-button)] hover:bg-[var(--primary-button-hover)] rounded-md flex items-center gap-2 justify-center"
        >
          <span className="material-symbols-outlined text-[20px]">add</span>
          New Task
        </button>
      </div>

      <div className="space-y-1">
        {filteredTasks.map(task => (
          <div
            key={task.id}
            draggable
            onDragStart={(e) => handleDragStart(e, task)}
            onDragOver={(e) => handleDragOver(e, task)}
            onDrop={(e) => handleDrop(e, task)}
            onDragEnd={handleDragEnd}
            className={`relative ${getDragStyles(task)}`}
          >
            <div className={`
              flex items-center justify-between rounded-md cursor-move
              ${(activeTask?.id === task.id || taskId === task.id)
                ? 'bg-[var(--selection-bg)] text-[var(--focus-ring)]'
                : 'text-[var(--text-secondary)] hover:bg-[var(--surface-bg)]'
              }
            `}>
              <button
                onClick={() => handleSelectTask(task)}
                className="flex-1 px-3 py-2 text-left text-sm"
              >
                <div className="sidebar-item-content">
                  <span className="material-symbols-outlined text-[20px]">
                    calendar_month
                  </span>
                  <span>{task.title}</span>
                </div>
              </button>
              {deleteConfirmId === task.id ? (
                <div className="flex -space-x-2 pr-2">
                  <button
                    onClick={(e) => handleDelete(e, task.id)}
                    className="px-2 py-2 text-[var(--error)] hover:text-[var(--error-hover)]"
                  >
                    <span className="material-symbols-outlined text-[20px]">
                      check
                    </span>
                  </button>
                  <button
                    onClick={handleCancelDelete}
                    className="px-2 py-2 text-[var(--text-muted)] hover:text-[var(--text-secondary)]"
                  >
                    <span className="material-symbols-outlined text-[20px]">
                      close
                    </span>
                  </button>
                </div>
              ) : (
                <button
                  onClick={(e) => handleDelete(e, task.id)}
                  className="px-2 py-2 text-[var(--error)] hover:text-[var(--error-hover)]"
                >
                  <span className="material-symbols-outlined text-[20px]">
                    delete
                  </span>
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RecurringTasksSidebar; 
import React from 'react';

function LogoutButton({ onLogout }) {
    return (
        <button
            onClick={onLogout}
            className={`w-8 h-8 sm:w-10 sm:h-10 rounded
                bg-red-800 text-white hover:bg-red-900 border border-red-700 flash-button transition duration-150 ease-in-out flex items-center justify-center`}
            title="Logout"
        >
            <span className="material-symbols-outlined text-lg sm:text-xl">power_settings_new</span>
        </button>
    );
}

export default LogoutButton; 
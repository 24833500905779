import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../services/api';
import { themeApi } from '../services/api';
import { darkTheme } from '../themes/themeInit';

const API_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3001/api';

export const fetchSettings = createAsyncThunk(
    'settings/fetchSettings',
    async () => {
        const response = await api.get(`${API_URL}/settings`);
        return response.data;
    }
);

export const fetchThemeData = createAsyncThunk(
    'settings/fetchThemeData',
    async () => {
        const [userThemeResponse, customThemesResponse] = await Promise.all([
            themeApi.getUserTheme(),
            themeApi.getCustomThemes()
        ]);
        return {
            userTheme: userThemeResponse.data,
            customThemes: customThemesResponse.data || {}
        };
    }
);

export const updateSettings = createAsyncThunk(
    'settings/updateSettings',
    async (settings) => {
        const response = await api.put(`${API_URL}/settings`, settings);
        return response.data;
    }
);

export const updateSetting = createAsyncThunk(
    'settings/updateSetting',
    async ({ key, value, skipPersist }, { rejectWithValue }) => {
        if (skipPersist) {
            return { [key]: value };
        }

        try {
            const response = await api.patch(`${API_URL}/settings/${key}`, { value });
            return response.data;
        } catch (error) {
            console.error('Error updating setting:', error);
            return rejectWithValue(error.response?.data || { error: 'Failed to update setting' });
        }
    }
);

const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        settings: {
            selected_theme: 'dark',
            ...Object.entries(darkTheme).reduce((acc, [key, value]) => ({
                ...acc,
                [`theme_${key}`]: value
            }), {})
        },
        status: 'idle',
        error: null,
        themeData: {
            userTheme: null,
            customThemes: {},
            status: 'idle',
            error: null
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSettings.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSettings.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.settings = action.payload;
            })
            .addCase(fetchSettings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateSettings.fulfilled, (state, action) => {
                state.settings = action.payload;
            })
            .addCase(updateSetting.fulfilled, (state, action) => {
                if (action.meta.arg.skipPersist) {
                    const { key, value } = action.meta.arg;
                    state.settings = {
                        ...state.settings,
                        [key]: value
                    };
                } else {
                    state.settings = action.payload;
                }
            })
            .addCase(fetchThemeData.pending, (state) => {
                state.themeData.status = 'loading';
            })
            .addCase(fetchThemeData.fulfilled, (state, action) => {
                state.themeData.status = 'succeeded';
                state.themeData.userTheme = action.payload.userTheme;
                state.themeData.customThemes = action.payload.customThemes;
            })
            .addCase(fetchThemeData.rejected, (state, action) => {
                state.themeData.status = 'failed';
                state.themeData.error = action.error.message;
            });
    }
});

export default settingsSlice.reducer; 
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StyledMobileDateTimePicker, dateTimePickerSlotProps } from '../../../themes/MUIStyles';
import format from 'date-fns/format';
import { fetchWeeklyPlanner, addWeeklyEvent, deleteWeeklyEvent } from '../../../store/weeklyPlannerSlice';
import { addMeeting } from '../../../store/meetingsSlice';
import { createPortal } from 'react-dom';
import { fetchMeetings } from '../../../store/meetingsSlice';

function WeeklyPlannerSidebar() {
    const dispatch = useDispatch();
    const { events, status } = useSelector(state => state.weeklyPlanner);
    const meetings = useSelector(state => state.meetings.items);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newEventName, setNewEventName] = useState('');
    const [newEventDate, setNewEventDate] = useState(new Date());
    const [deleteConfirmId, setDeleteConfirmId] = useState(null);
    const [sortAscending, setSortAscending] = useState(true);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchWeeklyPlanner());
        }
    }, [status, dispatch]);

    useEffect(() => {
        dispatch(fetchMeetings());
    }, [dispatch]);

    const handleAddEvent = () => {
        setIsDialogOpen(true);
        setNewEventName('');
        setNewEventDate(new Date());
    };

    const handleSaveEvent = () => {
        if (newEventName.trim()) {
            dispatch(addWeeklyEvent({ name: newEventName, date: newEventDate }));
            setIsDialogOpen(false);
        }
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        if (deleteConfirmId === id) {
            dispatch(deleteWeeklyEvent(id));
            setDeleteConfirmId(null);
        } else {
            setDeleteConfirmId(id);
        }
    };

    const handleCancelDelete = (e) => {
        e.stopPropagation();
        setDeleteConfirmId(null);
    };

    const handleCreateMeeting = (e, event) => {
        e.stopPropagation();
        dispatch(addMeeting({ 
            name: event.name, 
            date: new Date(event.date),
            weeklyPlannerEventId: event.id
        }));
    };

    const hasMeeting = (eventId) => {
        const result = meetings.some(meeting => meeting.weeklyPlannerEventId === eventId);
        return result;
    };

    // Sort the events before rendering
    const sortedEvents = [...events].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return sortAscending ? dateA - dateB : dateB - dateA;
    });

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && newEventName.trim() && !e.shiftKey) {
            handleSaveEvent();
        }
    };

    return (
        <div className="space-y-4">
            <button
                onClick={handleAddEvent}
                className="w-full px-3 py-2 text-sm bg-[var(--primary-button)] hover:bg-[var(--primary-button-hover)] rounded-md flex items-center gap-2 justify-center"
            >
                <span className="material-symbols-outlined text-[20px]">add</span>
                New Event
            </button>

            <div className="flex justify-end">
                <button
                    onClick={() => setSortAscending(!sortAscending)}
                    className="px-2 py-1 text-xs bg-[var(--surface-bg)] hover:bg-[var(--surface-bg-lighter)] rounded-md flex items-center gap-1 text-[var(--text-disabled)] hover:text-[var(--text-secondary)] flash-button"
                    title={sortAscending ? "Earliest to Latest" : "Latest to Earliest"}
                >
                    <span className="material-symbols-outlined text-[16px]">
                        {sortAscending ? "arrow_upward" : "arrow_downward"}
                    </span>
                    Sort by date
                </button>
            </div>

            <div className="space-y-2">
                {sortedEvents.map((event) => (
                    <div
                        key={event.id}
                        className="relative bg-[var(--selection-bg)] hover:bg-[var(--surface-bg-lighter)] rounded-md"
                    >
                        <div className="flex items-center justify-between p-2">
                            <div className="flex-1 min-w-0">
                                <div className="text-sm text-[var(--text-secondary)] break-words">
                                    {event.name}
                                </div>
                                <div className="text-xs text-[var(--text-muted)]">
                                    {format(new Date(event.date), 'MMM dd, yyyy HH:mm')}
                                </div>
                            </div>
                            <div className="flex items-center space-x-1 flex-shrink-0">
                                <button
                                    onClick={(e) => handleCreateMeeting(e, event)}
                                    disabled={hasMeeting(event.id)}
                                    className={`p-1.5 ${
                                        hasMeeting(event.id)
                                            ? 'text-[var(--text-disabled)]'
                                            : 'text-[var(--focus-ring)] hover:text-[var(--primary-button-hover)] flash-button'
                                    }`}
                                    title={hasMeeting(event.id) ? "Meeting already created" : "Create meeting from event"}
                                >
                                    <span className="material-symbols-outlined text-[20px]">
                                        {hasMeeting(event.id) ? "task_alt" : "event_available"}
                                    </span>
                                </button>
                                {deleteConfirmId === event.id ? (
                                    <>
                                        <button
                                            onClick={(e) => handleDelete(e, event.id)}
                                            className="p-1.5 text-[var(--error)] hover:text-[var(--error-hover)]"
                                        >
                                            <span className="material-symbols-outlined text-[20px]">
                                                check
                                            </span>
                                        </button>
                                        <button
                                            onClick={handleCancelDelete}
                                            className="p-1.5 text-[var(--text-muted)] hover:text-[var(--text-secondary)]"
                                        >
                                            <span className="material-symbols-outlined text-[20px]">
                                                close
                                            </span>
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        onClick={(e) => handleDelete(e, event.id)}
                                        className="p-1.5 text-[var(--error)] hover:text-[var(--error-hover)]"
                                    >
                                        <span className="material-symbols-outlined text-[20px]">
                                            delete
                                        </span>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {isDialogOpen && createPortal(
                <div className="fixed inset-0 bg-black/50 z-[1200]" data-modal>
                    <div className="absolute inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <div className="relative bg-[var(--surface-bg)] rounded-lg w-full max-w-md shadow-lg">
                                <div className="flex items-center justify-between p-4">
                                    <h2 className="text-lg font-semibold text-[var(--text-primary)]">Add New Event</h2>
                                    <button
                                        onClick={() => setIsDialogOpen(false)}
                                        className="p-2 hover:bg-[var(--button-hover-bg)] rounded-full text-[var(--text-primary)] transition-colors"
                                    >
                                        <span className="material-symbols-outlined">close</span>
                                    </button>
                                </div>

                                <div className="p-4">
                                    <div className="space-y-4">
                                        <div className="flex items-center gap-4">
                                            <label className="text-sm text-[var(--text-muted)] whitespace-nowrap">Event Name:</label>
                                            <input
                                                type="text"
                                                value={newEventName}
                                                onChange={(e) => setNewEventName(e.target.value)}
                                                onKeyDown={handleKeyDown}
                                                placeholder="Event name"
                                                className="flex-1 px-3 py-2 text-sm bg-[var(--input-bg)] border border-[var(--input-border)] focus:border-[var(--text-primary)] focus:outline-none focus:ring-0 rounded-md text-[var(--text-secondary)] placeholder-[var(--text-disabled)]"
                                            />
                                        </div>
                                        <div className="flex items-center gap-4">
                                            <label className="text-sm text-[var(--text-muted)] whitespace-nowrap">Date/Time:</label>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <StyledMobileDateTimePicker
                                                    value={newEventDate}
                                                    onChange={setNewEventDate}
                                                    label=""
                                                    className="flex-1"
                                                    slotProps={{
                                                        ...dateTimePickerSlotProps,
                                                        popper: {
                                                            ...dateTimePickerSlotProps?.popper,
                                                            style: { zIndex: 1300 }
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-end gap-2 p-4">
                                    <button
                                        onClick={() => setIsDialogOpen(false)}
                                        className="px-4 py-2 text-sm base-button rounded-md"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleSaveEvent}
                                        disabled={!newEventName.trim()}
                                        className={`px-4 py-2 text-sm bg-primary hover:bg-primary-hover rounded-md disabled:opacity-50 disabled:cursor-not-allowed`}
                                    >
                                        Add Event
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </div>
    );
}

export default WeeklyPlannerSidebar; 
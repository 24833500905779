import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../services/api';

export const fetchWeeklyPlanner = createAsyncThunk(
    'weeklyPlanner/fetchWeeklyPlanner',
    async () => {
        const response = await api.get('/weekly-planner');
        return response.data;
    }
);

export const updateWeeklyNotes = createAsyncThunk(
    'weeklyPlanner/updateNotes',
    async ({ notes, notesHeight }) => {
        const response = await api.put('/weekly-planner/notes', { notes, notesHeight });
        return response.data;
    }
);

export const updateSortPreferences = createAsyncThunk(
    'weeklyPlanner/updateSortPreferences',
    async ({ sortByUrgent, sortByDay }) => {
        const response = await api.put('/weekly-planner/sort-preferences', { sortByUrgent, sortByDay });
        return response.data;
    }
);

export const addWeeklyEvent = createAsyncThunk(
    'weeklyPlanner/addWeeklyEvent',
    async ({ name, date }) => {
        const response = await api.post('/weekly-planner/events', {
            name: name.trim(),
            date: date.toISOString()
        });
        return response.data;
    }
);

export const deleteWeeklyEvent = createAsyncThunk(
    'weeklyPlanner/deleteWeeklyEvent',
    async (id) => {
        await api.delete(`/weekly-planner/events/${id}`);
        return id;
    }
);

const initialState = {
    notes: '',
    notesHeight: null,
    events: [],
    status: 'idle',
    error: null,
    sortByUrgent: false,
    sortByDay: false
};

export const weeklyPlannerSlice = createSlice({
    name: 'weeklyPlanner',
    initialState,
    reducers: {
        setWeeklyNotesState: (state, action) => {
            const { notes, notesHeight } = action.payload;
            if (notes !== undefined) state.notes = notes;
            if (notesHeight !== undefined) state.notesHeight = notesHeight;
        },
        setSortByUrgent: (state, action) => {
            state.sortByUrgent = action.payload;
        },
        setSortByDay: (state, action) => {
            state.sortByDay = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWeeklyPlanner.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWeeklyPlanner.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.notes = action.payload.notes || '';
                state.notesHeight = action.payload.notesHeight || 100;
                state.events = action.payload.events || [];
                state.sortByUrgent = action.payload.sortByUrgent || false;
                state.sortByDay = action.payload.sortByDay || false;
            })
            .addCase(fetchWeeklyPlanner.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(updateWeeklyNotes.rejected, (state, action) => {
                // On error, revert to the values from the action meta
                const { notes, notesHeight } = action.meta.arg;
                state.notes = notes;
                state.notesHeight = notesHeight;
            })
            .addCase(addWeeklyEvent.fulfilled, (state, action) => {
                state.events.push(action.payload);
            })
            .addCase(deleteWeeklyEvent.fulfilled, (state, action) => {
                state.events = state.events.filter(event => event.id !== action.payload);
            });
    }
});

export const { setWeeklyNotesState, setSortByUrgent, setSortByDay } = weeklyPlannerSlice.actions;

export default weeklyPlannerSlice.reducer; 
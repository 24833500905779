import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import DashboardContent from '../components/Dashboard/DashboardContent';
import Projects from '../components/Projects/Projects';
import WeeklyPlanner from '../components/WeeklyPlanner';
import Meetings from '../components/Meetings/Meetings';
import RecurringTasks from '../components/RecurringTasks/RecurringTasks';
import Login from '../components/Login';

export const router = createBrowserRouter([
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: <DashboardContent />,
                children: [
                    {
                        path: 'projects',
                        children: [
                            {
                                path: '',
                                element: <Projects />
                            },
                            {
                                path: ':projectId',
                                element: <Projects />
                            }
                        ]
                    },
                    {
                        path: 'weekly-planner',
                        element: <WeeklyPlanner />
                    },
                    {
                        path: 'meetings',
                        children: [
                            {
                                path: '',
                                element: <Meetings />
                            },
                            {
                                path: ':meetingId',
                                element: <Meetings />
                            }
                        ]
                    },
                    {
                        path: 'recurring-tasks',
                        children: [
                            {
                                path: '',
                                element: <RecurringTasks />
                            },
                            {
                                path: 'new',
                                element: <RecurringTasks />
                            },
                            {
                                path: ':taskId',
                                element: <RecurringTasks />
                            }
                        ]
                    },
                    {
                        path: '',
                        element: <WeeklyPlanner />
                    }
                ]
            }
        ]
    }
]); 
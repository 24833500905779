import { addProject } from './projectsSlice';
import { addTodo } from './todosSlice';
import { addMeeting } from './meetingsSlice';
import { addRecurringTask } from './recurringTasksSlice';
import { updateWeeklyNotes } from './weeklyPlannerSlice';
import { updateSettings } from './settingsSlice';
import { store } from './index';
import { updateSortPreferences } from './weeklyPlannerSlice';

export const exportStoreData = async () => {
    const state = store.getState();
    
    const backupData = {
        version: '1.0',
        timestamp: new Date().toISOString(),
        data: {
            projects: state.projects.items,
            todos: state.todos.items,
            meetings: state.meetings.items,
            recurringTasks: state.recurringTasks.items,
            weeklyPlanner: {
                notes: state.weeklyPlanner.notes,
                events: state.weeklyPlanner.events,
                sortByUrgent: state.weeklyPlanner.sortByUrgent,
                sortByDay: state.weeklyPlanner.sortByDay,
                notesHeight: state.weeklyPlanner.notesHeight
            },
            settings: state.settings.settings,
            themeData: state.settings.themeData
        }
    };

    const blob = new Blob([JSON.stringify(backupData, null, 2)], { type: 'application/json' });
    return blob;
};

export const importStoreData = async (jsonFile, dispatch) => {
    try {
        const backupData = JSON.parse(await jsonFile.text());

        if (!backupData.version) {
            throw new Error('Invalid backup file format');
        }

        // First, reset the state
        dispatch({ type: 'RESET_STATE' });

        // Then restore settings and theme data
        if (backupData.data.settings) {
            await dispatch(updateSettings(backupData.data.settings));
        }

        // Create maps to store project and meeting IDs
        const projectMap = new Map();
        const meetingMap = new Map();

        // Restore projects first, preserving their IDs
        if (backupData.data.projects) {
            for (const project of backupData.data.projects) {
                try {
                    const result = await dispatch(addProject({
                        ...project,
                        id: project.id
                    })).unwrap();
                    projectMap.set(`project-${project.id}`, result.id);
                } catch (error) {
                    console.error('Failed to import project:', project, error);
                }
            }
        }

        // Restore meetings, preserving their IDs
        if (backupData.data.meetings) {
            for (const meeting of backupData.data.meetings) {
                try {
                    const result = await dispatch(addMeeting({
                        ...meeting,
                        id: meeting.id
                    })).unwrap();
                    meetingMap.set(`meeting-${meeting.id}`, result.id);
                } catch (error) {
                    console.error('Failed to import meeting:', meeting, error);
                }
            }
        }

        if (backupData.data.recurringTasks) {
            for (const task of backupData.data.recurringTasks) {
                await dispatch(addRecurringTask(task));
            }
        }

        // Restore todos with proper relationship handling
        if (backupData.data.todos) {
            const todos = backupData.data.todos;
            
            const todoRelationships = new Map();
            todos.forEach(todo => {
                if (todo.projectId || todo.weeklyTodoId || todo.meetingId) {
                    todoRelationships.set(todo.id, {
                        projectId: todo.projectId,
                        weeklyTodoId: todo.weeklyTodoId,
                        meetingId: todo.meetingId
                    });
                }
            });

            // Import project todos first
            const projectTodos = todos.filter(todo => 
                todo.location.startsWith('project-') && !todo.weeklyTodoId
            );

            for (const todo of projectTodos) {
                try {
                    const newLocation = projectMap.get(todo.location) ? 
                        `project-${projectMap.get(todo.location)}` : 
                        todo.location;

                    const newSource = todo.source?.startsWith('project-') ? 
                        `project-${projectMap.get(todo.source)}` : 
                        todo.source;

                    const newMeetingId = todo.meetingId ? 
                        meetingMap.get(`meeting-${todo.meetingId}`) : 
                        todo.meetingId;

                    await dispatch(addTodo({
                        ...todo,
                        location: newLocation,
                        source: newSource,
                        meetingId: newMeetingId,
                        weeklyTodoId: todoRelationships.get(todo.id)?.weeklyTodoId
                    }));
                } catch (error) {
                    console.error('Failed to import todo:', todo, error);
                }
            }

            // Import weekly todos
            const weeklyTodos = todos.filter(todo => todo.location === 'weekly');
            for (const todo of weeklyTodos) {
                try {
                    const newProjectId = todo.projectId ? 
                        projectMap.get(`project-${todo.projectId}`) : 
                        todo.projectId;

                    const newSource = todo.source?.startsWith('project-') ? 
                        `project-${projectMap.get(todo.source)}` : 
                        todo.source;

                    const newMeetingId = todo.meetingId ? 
                        meetingMap.get(`meeting-${todo.meetingId}`) : 
                        todo.meetingId;

                    await dispatch(addTodo({
                        ...todo,
                        projectId: newProjectId,
                        source: newSource,
                        meetingId: newMeetingId
                    }));
                } catch (error) {
                    console.error('Failed to import todo:', todo, error);
                }
            }

            // Import remaining todos
            const remainingTodos = todos.filter(todo => 
                !todo.location.startsWith('project-') && 
                todo.location !== 'weekly'
            );
            for (const todo of remainingTodos) {
                try {
                    const newSource = todo.source?.startsWith('project-') ? 
                        `project-${projectMap.get(todo.source)}` : 
                        todo.source;

                    const newMeetingId = todo.meetingId ? 
                        meetingMap.get(`meeting-${todo.meetingId}`) : 
                        todo.meetingId;

                    await dispatch(addTodo({
                        ...todo,
                        source: newSource,
                        meetingId: newMeetingId
                    }));
                } catch (error) {
                    console.error('Failed to import todo:', todo, error);
                }
            }
        }

        // Restore weekly planner preferences
        if (backupData.data.weeklyPlanner) {
            await dispatch(updateWeeklyNotes({
                notes: backupData.data.weeklyPlanner.notes,
                notesHeight: backupData.data.weeklyPlanner.notesHeight
            }));

            if ('sortByUrgent' in backupData.data.weeklyPlanner || 'sortByDay' in backupData.data.weeklyPlanner) {
                await dispatch(updateSortPreferences({
                    sortByUrgent: backupData.data.weeklyPlanner.sortByUrgent || false,
                    sortByDay: backupData.data.weeklyPlanner.sortByDay || false
                }));
            }
        }

        return true;
    } catch (error) {
        console.error('Error importing backup:', error);
        throw error;
    }
}; 
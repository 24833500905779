import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import TodoList from '../TodoList/TodoList';
import { useTodoList } from '../../hooks/useTodoList';
import { useSelector, useDispatch } from 'react-redux';
import { updateProjectIcon, updateProjectStatus, editProject, updateProjectNotesAsync, updateProjectNotesState } from '../../store/projectsSlice';
import { updateSetting } from '../../store/settingsSlice';
import IconSelector from './IconSelector';
import StatusSelector from './StatusSelector';
import MoveToModal from '../TodoList/MoveToModal';
import AutoResizingTextarea from '../common/AutoResizingTextarea';

function Projects() {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const projects = useSelector(state => state.projects.items);
    const settings = useSelector(state => state.settings.settings);
    
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState('');
    const [iconAnchorEl, setIconAnchorEl] = useState(null);
    const inputRef = React.useRef(null);
    
    // Get project before useEffect
    const project = projectId ? projects.find(p => p.id === projectId) : null;

    // Create a stable debounced function using useRef
    const debouncedUpdate = React.useRef(
        debounce(async (projectId, notes, height) => {
            try {
                await dispatch(updateProjectNotesAsync({ 
                    projectId, 
                    notes, 
                    notesHeight: height 
                })).unwrap();
            } catch (error) {
                console.error('Failed to update notes:', error);
                // The error case in the slice will handle reverting the state
            }
        }, 1000)
    ).current;

    // Cleanup on unmount
    React.useEffect(() => {
        return () => {
            debouncedUpdate.cancel();
        };
    }, [debouncedUpdate]);

    const handleNotesChange = (e) => {
        if (projectId) {
            const newValue = e.target.value;
            dispatch(updateProjectNotesState({ 
                projectId, 
                notes: newValue,
                notesHeight: project.notesHeight
            }));
            debouncedUpdate(projectId, newValue, project.notesHeight);
        }
    };

    const handleNotesHeightChange = (height) => {
        if (projectId && height !== project.notesHeight) {
            dispatch(updateProjectNotesState({ 
                projectId, 
                notes: project.notes,
                notesHeight: height
            }));
            debouncedUpdate(projectId, project.notes, height);
        }
    };

    // If no projectId is provided, redirect to the last opened project or first project
    React.useEffect(() => {
        if (!projectId && projects.length > 0) {
            const lastOpenedProjectId = settings.lastOpenedProjectId;
            const targetProject = lastOpenedProjectId && projects.find(p => p.id === lastOpenedProjectId)
                ? lastOpenedProjectId 
                : projects[0].id;
            navigate(`/projects/${targetProject}`);
        }
    }, [projectId, projects, navigate, settings.lastOpenedProjectId]);

    // Store last opened project when project changes
    React.useEffect(() => {
        if (projectId) {
            dispatch(updateSetting({ key: 'lastOpenedProjectId', value: projectId }));
        }
    }, [projectId, dispatch]);

    const {
        todos,
        handlers: {
            handleAddTodo,
            handleToggleComplete,
            handleToggleUrgent,
            handleDelete,
            handleEdit,
            handleReorderTodos,
            handleClearCompleted,
            handleMove
        },
        moveModalOpen,
        setMoveModalOpen,
        selectedTodoForMove,
        allTodos,
        moveActions: defaultMoveActions
    } = useTodoList(`project-${projectId || 'none'}`);
    
    // Override the move action to skip modal for project todos
    const moveActions = defaultMoveActions.map(action => {
        if (action.icon === 'drive_file_move') {
            return {
                ...action,
                onClick: (todo) => {
                    // Directly move to weekly instead of opening modal
                    handleMove(todo.id, 'weekly');
                },
                label: 'Move to Weekly Planner'  // Update label to be more specific
            };
        }
        return action;
    });

    if (!project && projects.length === 0) {
        return (
            <div className="flex items-center justify-center h-full text-[var(--text-muted)]">
                Select a project from the sidebar or create a new one
            </div>
        );
    }

    if (!project) {
        return (
            <div className="space-y-6">
                <h2 className="text-2xl font-semibold mb-4">Projects</h2>
                <p className="text-gray-400">Select a project from the sidebar.</p>
            </div>
        );
    }

    const handleStartEdit = () => {
        setIsEditing(true);
        setEditValue(project.name);
        // Focus the input on next render
        setTimeout(() => inputRef.current?.focus(), 0);
    };

    const handleSubmitEdit = () => {
        if (editValue.trim() && editValue !== project.name) {
            dispatch(editProject({ projectId: project.id, name: editValue.trim() }));
        }
        setIsEditing(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmitEdit();
        } else if (e.key === 'Escape') {
            setIsEditing(false);
        }
    };

    const handleIconClick = (event) => {
        setIconAnchorEl(iconAnchorEl ? null : event.currentTarget);
    };

    const handleIconSelect = (icon) => {
        if (projectId) {
            dispatch(updateProjectIcon({ projectId, icon }));
        }
        setIconAnchorEl(null);
    };

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-start mb-4">
                <div className="flex items-start gap-2 min-w-0">
                    <button
                        onClick={handleIconClick}
                        className="base-button !p-1.5 !rounded-md shrink-0"
                        title="Change project icon"
                    >
                        <span className="material-symbols-outlined text-[20px]">{project.icon}</span>
                    </button>
                    {isEditing ? (
                        <input
                            ref={inputRef}
                            type="text"
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            onBlur={handleSubmitEdit}
                            onKeyDown={handleKeyDown}
                            className="text-2xl font-semibold bg-[var(--input-bg)] border border-[var(--input-border)] focus:border-[var(--input-border-hover)] focus:outline-none rounded-md px-2 py-1 w-full"
                        />
                    ) : (
                        <h2 className="text-2xl font-semibold break-words min-w-0 pr-2">{project.name}</h2>
                    )}
                    <button
                        onClick={() => isEditing ? handleSubmitEdit() : handleStartEdit()}
                        className="base-button !px-1.5 !py-1 flex items-center justify-center !rounded-md shrink-0"
                        title={isEditing ? "Save project name" : "Edit project name"}
                    >
                        <span className="material-symbols-outlined text-[18px]">
                            {isEditing ? 'check' : 'edit'}
                        </span>
                    </button>
                </div>
            </div>

            <IconSelector
                anchorEl={iconAnchorEl}
                open={Boolean(iconAnchorEl)}
                onClose={() => setIconAnchorEl(null)}
                onSelect={handleIconSelect}
                currentIcon={project.icon}
            />

            <div className="mb-6">
                <h3 className="text-lg font-medium mb-2 text-left">Project Notes</h3>
                <AutoResizingTextarea
                    value={project?.notes || ''}
                    onChange={handleNotesChange}
                    onHeightChange={handleNotesHeightChange}
                    savedHeight={project?.notesHeight}
                    className="w-full p-3 bg-[var(--input-bg)] border border-[var(--input-border)] focus:border-[var(--input-border-hover)] focus:outline-none rounded-md"
                    placeholder="Add your project notes here..."
                />
            </div>

            <StatusSelector
                currentStatus={project.status}
                onStatusChange={(status) => dispatch(updateProjectStatus({ projectId: project.id, status }))}
            />

            <div className="h-4" />

            <TodoList
                todos={todos}
                source={`project-${projectId}`}
                onAddTodo={handleAddTodo}
                onToggleComplete={handleToggleComplete}
                onToggleUrgent={handleToggleUrgent}
                onDelete={handleDelete}
                onEdit={handleEdit}
                onReorderTodos={handleReorderTodos}
                onClearCompleted={handleClearCompleted}
                customActions={moveActions}
                allTodos={allTodos}
            />

            {/* Only show MoveToModal for non-project todos */}
            {moveModalOpen && selectedTodoForMove && !selectedTodoForMove.location.startsWith('project-') && (
                <MoveToModal
                    todo={selectedTodoForMove}
                    onMove={handleMove}
                    onClose={() => setMoveModalOpen(false)}
                />
            )}
        </div>
    );
}

export default Projects; 
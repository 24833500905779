import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { createPortal } from 'react-dom';

// Memoized selector for active projects
const selectActiveProjects = createSelector(
  state => state.projects.items,
  items => items.filter(project => project.status !== 'COMPLETED')
);

function MoveToModal({ todo, onMove, onClose }) {
  const projects = useSelector(selectActiveProjects);
  const [searchQuery, setSearchQuery] = useState('');

  // Handle escape key press
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') onClose();
    };
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  // Create destinations based on current location
  const destinations = todo.location.startsWith('meeting-')
    ? [ // From meeting -> show both Weekly and Projects
        { id: 'weekly', name: 'Weekly Planner', icon: 'calendar_today', type: 'planner' },
        ...projects.map(project => ({
          id: `project-${project.id}`,
          name: project.name,
          icon: project.icon,
          type: 'project'
        }))
      ]
    : todo.location.startsWith('project-')
      ? [{ id: 'weekly', name: 'Weekly Planner', icon: 'calendar_today', type: 'planner' }]  // From project -> show ONLY Weekly
      : projects.map(project => ({                                          // From weekly -> show ONLY Projects
          id: `project-${project.id}`,
          name: project.name,
          icon: project.icon,
          type: 'project'
        }));

  // Group and filter destinations
  const plannerDestinations = destinations.filter(dest => dest.type === 'planner');
  const projectDestinations = destinations
    .filter(dest => dest.type === 'project')
    .filter(dest => dest.name.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name));

  const modalContent = (
    <>
      {/* Backdrop */}
      <div 
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[999]"
        onClick={onClose}
      />
      
      {/* Modal */}
      <div className="fixed inset-0 flex items-center justify-center z-[1000] p-4">
        <div 
          className="bg-[var(--surface-bg)] text-[var(--text-primary)] rounded-xl w-full max-w-lg shadow-xl flex flex-col max-h-[85vh]"
          onClick={e => e.stopPropagation()}
        >
          {/* Header */}
          <div className="px-6 py-4 flex justify-between items-center">
            <h2 className="text-xl font-semibold">Move Task To: </h2>
            <button
              onClick={onClose}
              className="flash-button p-1.5 hover:bg-[var(--button-hover-bg)] rounded-lg transition-colors duration-150"
              title="Close"
            >
              <span className="material-symbols-outlined text-[20px]">close</span>
            </button>
          </div>

          {/* Content */}
          <div className="p-6 flex-1 overflow-y-auto">
            <div className="space-y-6">
              {/* Weekly Planner Option */}
              {plannerDestinations.length > 0 && (
                <div className="space-y-2">
                  {plannerDestinations.map(dest => (
                    <button
                      key={dest.id}
                      onClick={() => {
                        onMove(todo.id, dest.id);
                        onClose();
                      }}
                      className="flash-button w-full px-4 py-3 text-left text-sm bg-[var(--primary-button)] hover:bg-[var(--primary-button-hover)] rounded-lg flex items-center gap-3 transition-colors duration-150"
                    >
                      <span className="material-symbols-outlined text-[22px]">
                        {dest.icon}
                      </span>
                      <span className="font-medium">{dest.name}</span>
                    </button>
                  ))}
                </div>
              )}

              {/* Only show Projects section if there are project destinations */}
              {projectDestinations.length > 0 && !todo.location.startsWith('project-') && (
                <>
                  {/* Separator */}
                  {plannerDestinations.length > 0 && (
                    <div className="border-t border-[var(--surface-border)]"></div>
                  )}

                  {/* Projects Section */}
                  <div>
                    <div className="flex items-center justify-between mb-3">
                      <h3 className="text-gray-400 text-m font-medium">Project:</h3>
                      <div className="relative">
                        <input
                          type="text"
                          placeholder="Search projects..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          className="bg-[var(--input-bg)] text-sm px-3 py-1.5 rounded-lg w-48 focus:outline-none focus:ring-1 focus:ring-[var(--input-border-hover)] border border-[var(--input-border)]"
                        />
                        <span className="material-symbols-outlined absolute right-2 top-1/2 -translate-y-1/2 text-[var(--text-muted)] text-lg">
                          search
                        </span>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 gap-2 max-h-[40vh] overflow-y-auto pr-2">
                      {projectDestinations.map(dest => (
                        <button
                          key={dest.id}
                          onClick={() => {
                            onMove(todo.id, dest.id);
                            onClose();
                          }}
                          className="flash-button w-full px-4 py-3 text-left text-sm bg-[var(--surface-bg-darker)] hover:bg-[var(--button-hover-bg)] rounded-lg flex items-center gap-3 transition-colors duration-150"
                        >
                          <span className="material-symbols-outlined text-[22px]">
                            {dest.icon}
                          </span>
                          <span className="font-medium">{dest.name}</span>
                        </button>
                      ))}
                      {projectDestinations.length === 0 && searchQuery && (
                        <div className="text-[var(--text-muted)] text-sm text-center py-4">
                          No projects match your search
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Footer */}
          <div className="px-6 py-4 flex justify-end gap-2">
            <button
              onClick={onClose}
              className="flash-button px-4 py-2 text-sm base-button rounded-lg transition-colors duration-150"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );

  return createPortal(modalContent, document.body);
}

export default MoveToModal; 
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import api from '../services/api';

const calculateNextValidDate = (date, scheduleType, dayOfWeek, dateOfMonth) => {
    const currentDate = date instanceof Date ? new Date(date) : new Date(date);
    
    if (scheduleType === 'week' && dayOfWeek !== null) {
        const targetDay = parseInt(dayOfWeek);
        const currentDay = currentDate.getDay();
        let daysToAdd = targetDay - currentDay;
        if (daysToAdd < 0) daysToAdd += 7;
        currentDate.setDate(currentDate.getDate() + daysToAdd);
    } else if (scheduleType === 'month' && dateOfMonth !== null) {
        const targetDate = parseInt(dateOfMonth);
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        
        // Get the last day of the current month
        const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
        
        // If target date exists in current month
        const actualTargetDate = Math.min(targetDate, lastDayOfMonth);
        currentDate.setDate(actualTargetDate);
        
        // If the resulting date is before our input date, move to next month
        if (currentDate < date) {
            currentDate.setMonth(currentMonth + 1);
            // Check last day of next month too
            const nextMonthLastDay = new Date(currentYear, currentMonth + 2, 0).getDate();
            currentDate.setDate(Math.min(targetDate, nextMonthLastDay));
        }
    }
    
    return currentDate;
};

export const calculateTaskDates = (task) => {
    if (!task) return null;
    
    const lastCompleted = task.lastCompleted ? 
        (task.lastCompleted instanceof Date ? task.lastCompleted : new Date(task.lastCompleted)) 
        : null;
    const nextDueDate = task.nextDueDate ? 
        (task.nextDueDate instanceof Date ? task.nextDueDate : new Date(task.nextDueDate)) 
        : null;
                        
    if (!nextDueDate) return null;
    
    let calculatedNextDueDate;

    if (task.scheduleMode === 'fixed') {
        if (task.isDateUpdate) {
            // If this is a manual date update, respect the provided nextDueDate
            calculatedNextDueDate = new Date(nextDueDate);
        } else {
            // For fixed schedule, calculate next occurrence based on the current nextDueDate
            // This ensures we maintain the fixed schedule regardless of completion date
            calculatedNextDueDate = calculateNextValidDate(
                nextDueDate, // Use nextDueDate instead of lastCompleted
                task.scheduleType,
                task.dayOfWeek,
                task.dateOfMonth
            );
            
            // If the calculated date is in the past (due to completion), move to next occurrence
            if (calculatedNextDueDate <= new Date()) {
                calculatedNextDueDate = calculateNextValidDate(
                    calculatedNextDueDate,
                    task.scheduleType,
                    task.dayOfWeek,
                    task.dateOfMonth
                );
            }
        }
    } else {
        // For relative schedule
        if (!lastCompleted) {
            calculatedNextDueDate = new Date(nextDueDate);
        } else {
            calculatedNextDueDate = new Date(lastCompleted);
            
            switch (task.frequency.toLowerCase()) {
                case 'daily':
                    calculatedNextDueDate.setDate(calculatedNextDueDate.getDate() + 1);
                    break;
                case 'weekly':
                    calculatedNextDueDate.setDate(calculatedNextDueDate.getDate() + 7);
                    break;
                case 'monthly':
                    calculatedNextDueDate.setMonth(calculatedNextDueDate.getMonth() + 1);
                    break;
                case 'every 6 months':
                    calculatedNextDueDate.setMonth(calculatedNextDueDate.getMonth() + 6);
                    break;
                case 'yearly':
                    calculatedNextDueDate.setFullYear(calculatedNextDueDate.getFullYear() + 1);
                    break;
                default:
                    calculatedNextDueDate.setDate(calculatedNextDueDate.getDate() + 1);
                    break;
            }
        }
    }

    // Ensure nextDueDate is not the same as completion date
    if (lastCompleted && calculatedNextDueDate <= lastCompleted && !task.isDateUpdate) {
        // Add one interval based on frequency
        switch (task.frequency.toLowerCase()) {
            case 'daily':
                calculatedNextDueDate.setDate(calculatedNextDueDate.getDate() + 1);
                break;
            case 'weekly':
                calculatedNextDueDate.setDate(calculatedNextDueDate.getDate() + 7);
                break;
            case 'monthly':
                calculatedNextDueDate.setMonth(calculatedNextDueDate.getMonth() + 1);
                break;
            case 'every 6 months':
                calculatedNextDueDate.setMonth(calculatedNextDueDate.getMonth() + 6);
                break;
            case 'yearly':
                calculatedNextDueDate.setFullYear(calculatedNextDueDate.getFullYear() + 1);
                break;
            default:
                calculatedNextDueDate.setDate(calculatedNextDueDate.getDate() + 1);
                break;
        }
    }

    const gracePeriodDays = parseInt(task.gracePeriod) || 0;
    const preparationDays = parseInt(task.preparationTime) || 0;

    const graceEndDate = new Date(calculatedNextDueDate);
    graceEndDate.setDate(graceEndDate.getDate() + gracePeriodDays);

    const prepStartDate = new Date(calculatedNextDueDate);
    prepStartDate.setDate(prepStartDate.getDate() - preparationDays);

    return {
        lastCompleted: lastCompleted ? lastCompleted.toISOString() : null,
        nextDueDate: calculatedNextDueDate.toISOString(),
        gracePeriodRange: {
            start: calculatedNextDueDate.toISOString(),
            end: graceEndDate.toISOString()
        },
        preparationRange: {
            start: prepStartDate.toISOString(),
            end: calculatedNextDueDate.toISOString()
        },
        isOverdue: new Date() > graceEndDate
    };
};

export const fetchRecurringTasks = createAsyncThunk(
    'recurringTasks/fetchRecurringTasks',
    async () => {
        const response = await api.get('/recurring-tasks');
        return response.data;
    }
);

export const addRecurringTask = createAsyncThunk(
    'recurringTasks/addRecurringTask',
    async (task) => {
        // Convert string numbers to integers
        const preparationTime = parseInt(task.preparationTime || '0');
        const gracePeriod = parseInt(task.gracePeriod || '0');
        const dayOfWeek = task.dayOfWeek !== undefined ? parseInt(task.dayOfWeek) : new Date().getDay();
        const dateOfMonth = task.dateOfMonth !== undefined ? parseInt(task.dateOfMonth) : new Date().getDate();

        const taskData = {
            title: task.title?.trim() || 'New Task',
            scheduleMode: task.scheduleMode || 'fixed',
            scheduleType: task.scheduleType || 'week',
            dayOfWeek,
            dateOfMonth,
            frequency: task.frequency || 'weekly',
            preparationTime,
            gracePeriod,
            nextDueDate: task.nextDueDate || new Date().toISOString(),
            status: task.status || 'NOT_STARTED',
            icon: task.icon || 'calendar_month'
        };
        
        try {
            const response = await api.post('/recurring-tasks', taskData);
            return response.data;
        } catch (error) {
            console.error('Error creating task:', error.response?.data || error.message);
            throw error;
        }
    }
);

export const updateRecurringTask = createAsyncThunk(
    'recurringTasks/updateRecurringTask',
    async ({ taskId, updates }) => {
        // Calculate new dates if needed
        if (updates.lastCompleted || 
            updates.scheduleMode || 
            updates.scheduleType || 
            updates.dayOfWeek !== undefined || 
            updates.dateOfMonth !== undefined || 
            updates.frequency || 
            updates.preparationTime || 
            updates.gracePeriod) {
            const dates = calculateTaskDates({ ...updates });
            if (dates) {
                updates.nextDueDate = dates.nextDueDate;
            }
        }
        
        const response = await api.put(`/recurring-tasks/${taskId}`, updates);
        return response.data;
    }
);

export const deleteRecurringTask = createAsyncThunk(
    'recurringTasks/deleteRecurringTask',
    async (id) => {
        await api.delete(`/recurring-tasks/${id}`);
        return id;
    }
);

export const completeRecurringTask = createAsyncThunk(
    'recurringTasks/completeRecurringTask',
    async ({ taskId, completionDate }, { getState }) => {
        const state = getState();
        const task = state.recurringTasks.items.find(t => t.id === taskId);
        
        if (!task) {
            throw new Error('Task not found');
        }

        const dateObj = new Date(completionDate);
        const updatedTask = {
            ...task,
            lastCompleted: dateObj
        };

        const dates = calculateTaskDates(updatedTask);
        

        if (!dates) {
            throw new Error('Failed to calculate next due date');
        }
        
        try {
            const response = await api.post(`/recurring-tasks/${taskId}/complete`, {
                completionDate: dateObj.toISOString(),
                nextDueDate: dates.nextDueDate,
                // Include all calculated dates in the update
                ...dates
            });
            return response.data;
        } catch (error) {
            console.error('Error completing task:', error);
            throw error;
        }
    }
);

// Add reorder thunk
export const reorderRecurringTasksThunk = createAsyncThunk(
  'recurringTasks/reorderTasks',
  async (tasks) => {
    const response = await api.put('/recurring-tasks/reorder', tasks);
    return response.data;
  }
);

// Add selector for sorted tasks
export const selectAllRecurringTasks = state => state.recurringTasks.items;

export const selectSortedRecurringTasks = createSelector(
  [selectAllRecurringTasks],
  (tasks) => {
    if (!tasks) return []; // Add null check
    return [...tasks].sort((a, b) => {
      // First sort by order
      if (a.order !== b.order) {
        return a.order - b.order;
      }
      // Then by creation date if orders are equal
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
  }
);

// Add order field to initial state and handle ordering in reducers
const initialState = {
    items: [],
    activeRecurringTask: null,
};

const recurringTasksSlice = createSlice({
    name: 'recurringTasks',
    initialState,
    reducers: {
        setActiveRecurringTask: (state, action) => {
            state.activeRecurringTask = action.payload;
        },
        updateLastCompleted: (state, action) => {
            const { taskId, date } = action.payload;
            const taskIndex = state.items.findIndex(task => task.id === taskId);
            if (taskIndex !== -1) {
                state.items[taskIndex].lastCompleted = date instanceof Date ? 
                    date.toISOString() : date;
            }
        },
        setRecurringTasks: (state, action) => {
            state.items = action.payload;
        },
        // Add reorder reducer
        reorderTasks: (state, action) => {
            state.items = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRecurringTasks.fulfilled, (state, action) => {
                state.items = action.payload;
            })
            .addCase(addRecurringTask.fulfilled, (state, action) => {
                state.items.push(action.payload);
                state.activeRecurringTask = action.payload;
            })
            .addCase(updateRecurringTask.fulfilled, (state, action) => {
                const index = state.items.findIndex(task => task.id === action.payload.id);
                if (index !== -1) {
                    state.items[index] = action.payload;
                    if (state.activeRecurringTask?.id === action.payload.id) {
                        state.activeRecurringTask = action.payload;
                    }
                }
            })
            .addCase(deleteRecurringTask.fulfilled, (state, action) => {
                state.items = state.items.filter(task => task.id !== action.payload);
                if (state.activeRecurringTask?.id === action.payload) {
                    state.activeRecurringTask = null;
                }
            })
            .addCase(completeRecurringTask.fulfilled, (state, action) => {
                const index = state.items.findIndex(task => task.id === action.payload.id);
                if (index !== -1) {
                    state.items[index] = action.payload;
                    if (state.activeRecurringTask?.id === action.payload.id) {
                        state.activeRecurringTask = action.payload;
                    }
                }
            })
            .addCase(reorderRecurringTasksThunk.fulfilled, (state, action) => {
                state.items = action.payload;
            });
    }
});

export const { 
    setActiveRecurringTask, 
    updateLastCompleted,
    setRecurringTasks,
    reorderTasks // Export the reorder action
} = recurringTasksSlice.actions;

export default recurringTasksSlice.reducer; 
import { configureStore } from '@reduxjs/toolkit';
import projectsReducer, { updateProject } from './projectsSlice';
import meetingsReducer, { updateMeeting } from './meetingsSlice';
import todosReducer from './todosSlice';
import recurringTasksReducer from './recurringTasksSlice';
import weeklyPlannerReducer from './weeklyPlannerSlice';
import settingsReducer from './settingsSlice';
import { localStorageMiddleware } from './localStorageMiddleware';

// Add new middleware to handle project updates
const projectsAPIMiddleware = store => next => action => {
  const result = next(action);
  
  // Handle project updates
  const projectActions = [
    'projects/updateProjectNotes',
    'projects/editProject',
    'projects/updateProjectIcon',
    'projects/updateProjectStatus'
  ];

  if (projectActions.includes(action.type)) {
    const state = store.getState();
    const project = state.projects.items.find(p => p.id === action.payload.projectId);
    
    if (project) {
      store.dispatch(updateProject(project));
    }
  }

  // Handle reordering separately since it needs to update all projects
  if (action.type === 'projects/reorderProjects') {
    // Only update the local state, let reorderProjectsThunk handle the API update
    return result;
  }
  
  return result;
};

// Middleware to handle meeting updates
const meetingsAPIMiddleware = store => next => action => {
  const result = next(action);
  
  const meetingActions = [
    'meetings/updateMeetingNotes',
    'meetings/editMeeting'
  ];

  if (meetingActions.includes(action.type)) {
    const state = store.getState();
    const meetingId = action.payload.meetingId;
    const meeting = state.meetings.items.find(m => m.id === meetingId);
    
    if (meeting) {
      store.dispatch(updateMeeting(meeting));
    }
  }
  
  return result;
};

// Create a root reducer that can handle state reset
const rootReducer = {
  projects: (state, action) => {
    if (action.type === 'RESET_STATE') return projectsReducer(undefined, action);
    return projectsReducer(state, action);
  },
  meetings: (state, action) => {
    if (action.type === 'RESET_STATE') return meetingsReducer(undefined, action);
    return meetingsReducer(state, action);
  },
  todos: (state, action) => {
    if (action.type === 'RESET_STATE') return todosReducer(undefined, action);
    return todosReducer(state, action);
  },
  recurringTasks: (state, action) => {
    if (action.type === 'RESET_STATE') return recurringTasksReducer(undefined, action);
    return recurringTasksReducer(state, action);
  },
  weeklyPlanner: (state, action) => {
    if (action.type === 'RESET_STATE') return weeklyPlannerReducer(undefined, action);
    return weeklyPlannerReducer(state, action);
  },
  settings: (state, action) => {
    if (action.type === 'RESET_STATE') return settingsReducer(undefined, action);
    return settingsReducer(state, action);
  },
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      localStorageMiddleware,
      projectsAPIMiddleware,
      meetingsAPIMiddleware
    ),
}); 
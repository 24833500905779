import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { useMeetings } from '../../hooks/useMeetings';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StyledMobileDateTimePicker, dateTimePickerSlotProps } from '../../themes/MUIStyles';
import TodoList from '../TodoList';
import { useTodoList } from '../../hooks/useTodoList';
import MoveToModal from '../TodoList/MoveToModal';
import { useDispatch, useSelector } from 'react-redux';
import { updateMeetingNotesAsync, setMeetingNotesState, summarizeMeetingNotes } from '../../store/meetingsSlice';
import { updateSetting } from '../../store/settingsSlice';
import api from '../../services/api';
import AutoResizingTextarea from '../common/AutoResizingTextarea';

function Meetings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { meetingId } = useParams();
  const settings = useSelector(state => state.settings.settings);
  const { 
    meetings,
    handleEditMeeting: editMeeting
  } = useMeetings();

  // Keep track of current meetings
  const currentMeetingsRef = React.useRef(meetings);
  useEffect(() => {
    currentMeetingsRef.current = meetings;
  }, [meetings]);

  const activeMeeting = meetingId ? meetings.find(m => m.id === meetingId) : null;

  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState('');
  const [editDate, setEditDate] = useState(null);

  // Create a stable debounced function using useRef
  const debouncedUpdate = React.useRef(
    debounce(async (meetingId, notes, height) => {
      try {
        await dispatch(updateMeetingNotesAsync({ 
          meetingId, 
          notes, 
          notesHeight: height 
        })).unwrap();
      } catch (error) {
        console.error('Failed to update notes:', error);
      }
    }, 1000)
  ).current;

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  const handleNotesChange = (e) => {
    if (activeMeeting) {
      const newValue = e.target.value;
      dispatch(setMeetingNotesState({ 
        meetingId: activeMeeting.id, 
        notes: newValue
      }));
      debouncedUpdate(activeMeeting.id, newValue, activeMeeting.notesHeight);
    }
  };

  const handleNotesHeightChange = (height) => {
    if (activeMeeting && height !== activeMeeting.notesHeight) {
      dispatch(setMeetingNotesState({ 
        meetingId: activeMeeting.id, 
        notesHeight: height
      }));
      debouncedUpdate(activeMeeting.id, activeMeeting.notes, height);
    }
  };

  const { 
    todos, 
    handlers, 
    moveActions,
    moveModalOpen,
    setMoveModalOpen,
    selectedTodoForMove,
    allTodos
  } = useTodoList(activeMeeting ? `meeting-${activeMeeting.id}` : null);

  useEffect(() => {
    if (activeMeeting) {
      setEditValue(activeMeeting.name);
      setEditDate(new Date(activeMeeting.date));
    }
  }, [activeMeeting]);

  useEffect(() => {
    if (!meetingId && meetings.length > 0) {
      const lastOpenedMeetingId = settings.lastOpenedMeetingId;
      const targetMeeting = lastOpenedMeetingId && meetings.find(m => m.id === lastOpenedMeetingId)
        ? lastOpenedMeetingId
        : meetings[0].id;
      navigate(`/meetings/${targetMeeting}`);
    }
  }, [meetingId, meetings, navigate, settings.lastOpenedMeetingId]);

  // Store last opened meeting when meeting changes
  useEffect(() => {
    if (meetingId) {
      dispatch(updateSetting({ key: 'lastOpenedMeetingId', value: meetingId }));
    }
  }, [meetingId, dispatch]);

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  const handleSubmitEdit = (force = false) => {
    if ((editValue.trim() && activeMeeting) || force) {
      editMeeting(activeMeeting.id, { 
        name: editValue.trim(),
        date: editDate.toISOString()
      });
      setIsEditing(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmitEdit();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
    }
  };

  const handleBlur = (e) => {
    const isDatePicker = e.relatedTarget?.closest('.MuiDialog-root, .MuiTextField-root');
    if (!isDatePicker) {
      handleSubmitEdit(true);
    }
  };

  const summarizing = useSelector(state => state.meetings.summarizing);
  const summaryError = useSelector(state => state.meetings.summaryError);
  const [originalNotes, setOriginalNotes] = useState('');
  const [showOriginal, setShowOriginal] = useState(false);

  // Reset original notes states when active meeting changes
  useEffect(() => {
    setOriginalNotes('');
    setShowOriginal(false);
  }, [activeMeeting?.id]);

  const handleSummarize = async () => {
    if (activeMeeting && activeMeeting.notes) {
      try {
        setOriginalNotes(activeMeeting.notes);
        const result = await dispatch(summarizeMeetingNotes(activeMeeting.id)).unwrap();
        dispatch(setMeetingNotesState({ 
          meetingId: activeMeeting.id, 
          notes: result.summary,
          notesHeight: activeMeeting.notesHeight 
        }));
        // Save the summarized notes to the backend
        debouncedUpdate(activeMeeting.id, result.summary, activeMeeting.notesHeight);
        setShowOriginal(true);
      } catch (error) {
        console.error('Failed to summarize notes:', error);
      }
    }
  };

  const handleUndoSummary = () => {
    if (originalNotes && activeMeeting) {
      dispatch(setMeetingNotesState({ 
        meetingId: activeMeeting.id, 
        notes: originalNotes,
        notesHeight: activeMeeting.notesHeight 
      }));
      debouncedUpdate(activeMeeting.id, originalNotes, activeMeeting.notesHeight);
      setShowOriginal(false);
    }
  };

  const [generatingTodos, setGeneratingTodos] = useState(false);
  const [todosError, setTodosError] = useState(null);

  const handleGenerateTodos = async () => {
    if (activeMeeting && activeMeeting.notes) {
      try {
        console.log('Starting todo generation...');
        console.log('Meeting notes:', activeMeeting.notes);
        setGeneratingTodos(true);
        setTodosError(null);
        const { data } = await api.post(`/meetings/${activeMeeting.id}/extract-todos`);
        
        console.log('Extracted todos:', data.todos);
        
        // Add the extracted todos with the correct location
        data.todos.forEach(todo => {
          handlers.handleAddTodo({
            text: todo.text,
            urgent: todo.urgent,
            completed: false,
            location: `meeting-${activeMeeting.id}`
          });
        });
      } catch (error) {
        console.error('Failed to generate todos:', error);
        setTodosError(error.response?.data?.error || error.message);
      } finally {
        setGeneratingTodos(false);
      }
    } else {
      console.log('Cannot generate todos:', {
        hasMeeting: !!activeMeeting,
        hasNotes: !!(activeMeeting?.notes)
      });
    }
  };

  if (!activeMeeting) {
    return (
      <div className="flex items-center justify-center h-full text-[var(--text-muted)]">
        Select a meeting from the sidebar or create a new one
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <div className="flex flex-col sm:flex-row justify-start items-start gap-4 w-full">
          {isEditing ? (
            <>
              <div className="w-full sm:w-fit">
                <input
                  type="text"
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  className="w-full text-2xl font-semibold bg-[var(--surface-bg)] border border-[var(--border-light)] rounded px-2 py-1 focus:outline-none focus:border-[var(--text-primary)]"
                />
              </div>
              <div className="flex items-center gap-4 shrink-0">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StyledMobileDateTimePicker
                    value={editDate}
                    onChange={setEditDate}
                    label=""
                    slotProps={dateTimePickerSlotProps}
                    className="shrink-0"
                  />
                </LocalizationProvider>
                <button
                  onClick={handleSubmitEdit}
                  className="base-button !px-1.5 !py-1 flex items-center justify-center !rounded-md shrink-0"
                  title="Save meeting details"
                >
                  <span className="material-symbols-outlined text-[18px]">
                    check
                  </span>
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="w-full sm:w-fit">
                <h2 className="text-2xl font-semibold text-[var(--text-primary)] break-words text-left">
                  {activeMeeting.name}
                </h2>
              </div>
              <div className="flex items-center gap-4 shrink-0">
                <div className="text-[var(--text-muted)]">
                  {new Date(activeMeeting.date).toLocaleString()}
                </div>
                <button
                  onClick={handleStartEdit}
                  className="base-button !px-1.5 !py-1 flex items-center justify-center !rounded-md shrink-0"
                  title="Edit meeting details"
                >
                  <span className="material-symbols-outlined text-[18px]">
                    edit
                  </span>
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium mb-2 text-left">Notes</h3>
          <AutoResizingTextarea
            value={activeMeeting.notes || ''}
            onChange={handleNotesChange}
            onHeightChange={handleNotesHeightChange}
            savedHeight={activeMeeting.notesHeight}
            className="w-full p-3 bg-[var(--input-bg)] border border-[var(--input-border)] focus:border-[var(--text-primary)] focus:outline-none rounded-md mb-2"
            placeholder="Add meeting notes here..."
          />
          <div className="flex justify-end gap-2 mb-2">
            <button
              onClick={handleGenerateTodos}
              disabled={generatingTodos || !activeMeeting?.notes}
              className={`flex items-center justify-center px-4 py-2 rounded-md base-button flash-button transition duration-150 ease-in-out ${
                generatingTodos ? 'opacity-50 cursor-not-allowed' : ''
              } ${!activeMeeting?.notes ? 'opacity-50 cursor-not-allowed' : ''}`}
              title="Generate Todos from Action Items"
            >
              {generatingTodos ? (
                <span className="material-symbols-outlined text-[20px] animate-spin">
                  hourglass_empty
                </span>
              ) : (
                <span className="material-symbols-outlined text-[20px]">
                  add_task
                </span>
              )}
              <span className="text-sm text-[var(--text-secondary)] ml-2">
                {generatingTodos ? 'Generating...' : 'Generate Todos'}
              </span>
            </button>
            <button
              onClick={handleSummarize}
              disabled={summarizing || !activeMeeting?.notes}
              className={`flex items-center justify-center px-4 py-2 rounded-md base-button flash-button transition duration-150 ease-in-out ${
                summarizing ? 'opacity-50 cursor-not-allowed' : ''
              } ${!activeMeeting?.notes ? 'opacity-50 cursor-not-allowed' : ''}`}
              title="Summarize Meeting Notes"
            >
              {summarizing ? (
                <span className="material-symbols-outlined text-[20px] animate-spin">
                  hourglass_empty
                </span>
              ) : (
                <span className="material-symbols-outlined text-[20px]">
                  auto_fix_high
                </span>
              )}
              <span className="text-sm text-[var(--text-secondary)] ml-2">
                {summarizing ? 'Summarizing...' : 'Summarize'}
              </span>
            </button>
          </div>
          {todosError && (
            <div className="mt-2 text-red-500 text-sm">
              Error: {todosError}
            </div>
          )}
          {summaryError && (
            <div className="mt-2 text-red-500 text-sm">
              Error: {summaryError}
            </div>
          )}
          {showOriginal && (
            <div className="mt-4 p-4 bg-[var(--surface-bg)] border border-[var(--surface-border)] rounded-md">
              <div className="flex justify-between items-center mb-2">
                <h4 className="text-md font-medium">Original Notes</h4>
                <div className="flex gap-2">
                  <button
                    onClick={handleUndoSummary}
                    className="text-blue-400 hover:text-blue-300 flex items-center gap-1"
                    title="Restore original notes"
                  >
                    <span className="material-symbols-outlined text-[20px]">undo</span>
                    Undo Summary
                  </button>
                  <button
                    onClick={() => setShowOriginal(false)}
                    className="text-[var(--text-muted)] hover:text-[var(--text-secondary)]"
                  >
                    <span className="material-symbols-outlined text-[20px]">close</span>
                  </button>
                </div>
              </div>
              <div className="max-h-[200px] overflow-y-auto">
                <p className="text-[var(--text-secondary)] whitespace-pre-wrap text-left">{originalNotes}</p>
              </div>
            </div>
          )}
        </div>

        <div>
          <h3 className="text-lg font-medium mb-4 text-left">Action Items</h3>
          <TodoList
            todos={todos}
            source={`meeting-${activeMeeting.id}`}
            onAddTodo={handlers.handleAddTodo}
            onToggleComplete={handlers.handleToggleComplete}
            onToggleUrgent={handlers.handleToggleUrgent}
            onDelete={handlers.handleDelete}
            onEdit={handlers.handleEdit}
            onReorderTodos={handlers.handleReorderTodos}
            onClearCompleted={handlers.handleClearCompleted}
            customActions={moveActions}
            allTodos={allTodos}
          />
        </div>
      </div>

      {/* Move To Modal */}
      {moveModalOpen && selectedTodoForMove && (
        <MoveToModal
          todo={selectedTodoForMove}
          onMove={handlers.handleMove}
          onClose={() => setMoveModalOpen(false)}
        />
      )}
    </div>
  );
}

export default Meetings; 

import { useSelector } from 'react-redux';
import ExcelJS from 'exceljs';

const ExportSettings = () => {
    const projects = useSelector(state => state.projects.items);
    const meetings = useSelector(state => state.meetings.items);
    const weeklyPlanner = useSelector(state => state.weeklyPlanner);
    const todos = useSelector(state => state.todos.items);
    const recurringTasks = useSelector(state => state.recurringTasks.items);

    const handleExportAll = async () => {
        const workbook = new ExcelJS.Workbook();
        
        // Helper function to style header rows
        const styleHeaderRow = (row) => {
            row.eachCell((cell) => {
                cell.font = { bold: true };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFE0E0E0' }  // Light gray background
                };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        };

        // Projects
        const projectsSheet = workbook.addWorksheet('Projects');
        projectsSheet.columns = [{ width: 20 }, { width: 40 }];

        projects.forEach(project => {
            const headerRow = projectsSheet.addRow(['Project Name', project.name]);
            styleHeaderRow(headerRow);
            projectsSheet.addRow(['Notes', project.notes]);
            projectsSheet.addRow(['Created', new Date(project.createdAt).toLocaleString()]);
            projectsSheet.addRow(['Status', project.status]);
            
            const projectTodos = todos.filter(todo => todo.location === `project-${project.id}`);
            if (projectTodos.length > 0) {
                const todoHeaderRow = projectsSheet.addRow(['Todo', 'Status']);
                styleHeaderRow(todoHeaderRow);
                projectTodos.forEach(todo => {
                    projectsSheet.addRow([todo.text, todo.completed ? 'Completed' : 'Pending']);
                });
            }
            projectsSheet.addRow([]);
            projectsSheet.addRow([]); // Add extra space between projects
        });

        // Meetings
        const meetingsSheet = workbook.addWorksheet('Meetings');
        meetingsSheet.columns = [{ width: 20 }, { width: 40 }];

        console.log('Meetings data:', meetings);

        meetings.forEach(meeting => {
            console.log('Individual meeting:', meeting);
            const headerRow = meetingsSheet.addRow(['Meeting Title', meeting.name]);
            styleHeaderRow(headerRow);
            meetingsSheet.addRow(['Date', new Date(meeting.date).toLocaleString()]);
            meetingsSheet.addRow(['Notes', meeting.notes]);
            meetingsSheet.addRow(['Status', meeting.status]);
            
            const meetingTodos = todos.filter(todo => todo.location === `meeting-${meeting.id}`);
            if (meetingTodos.length > 0) {
                const todoHeaderRow = meetingsSheet.addRow(['Todo', 'Status']);
                styleHeaderRow(todoHeaderRow);
                meetingTodos.forEach(todo => {
                    meetingsSheet.addRow([todo.text, todo.completed ? 'Completed' : 'Pending']);
                });
            }
            meetingsSheet.addRow([]);
            meetingsSheet.addRow([]); // Add extra space between meetings
        });

        // Weekly Planner
        const weeklySheet = workbook.addWorksheet('Weekly Planner');
        weeklySheet.columns = [{ width: 40 }, { width: 20 }];

        // Events
        const eventsHeaderRow = weeklySheet.addRow(['Events']);
        styleHeaderRow(eventsHeaderRow);
        const eventDetailsHeaderRow = weeklySheet.addRow(['Event', 'Date']);
        styleHeaderRow(eventDetailsHeaderRow);
        weeklyPlanner.events.forEach(event => {
            weeklySheet.addRow([event.name, new Date(event.date).toLocaleString()]);
        });

        // Weekly Todos
        weeklySheet.addRow([]);
        const todosHeaderRow = weeklySheet.addRow(['Weekly Tasks']);
        styleHeaderRow(todosHeaderRow);
        const todoDetailsHeaderRow = weeklySheet.addRow(['Todo', 'Status']);
        styleHeaderRow(todoDetailsHeaderRow);
        todos.filter(todo => todo.location === 'weekly')
            .forEach(todo => {
                weeklySheet.addRow([
                    todo.text,
                    todo.completed ? 'Completed' : 'Pending'
                ]);
            });

        // Pings
        weeklySheet.addRow([]);
        const pingsHeaderRow = weeklySheet.addRow(['Pings']);
        styleHeaderRow(pingsHeaderRow);
        const pingDetailsHeaderRow = weeklySheet.addRow(['Ping', 'Status', 'Source']);
        styleHeaderRow(pingDetailsHeaderRow);
        todos.filter(todo => todo.location === 'ping')
            .forEach(todo => {
                weeklySheet.addRow([
                    todo.text,
                    todo.completed ? 'Completed' : 'Pending',
                    todo.source || 'N/A'
                ]);
            });

        // Recurring Tasks
        const recurringSheet = workbook.addWorksheet('Recurring Tasks');
        recurringSheet.columns = [{ width: 20 }, { width: 40 }];

        recurringTasks.forEach(task => {
            const headerRow = recurringSheet.addRow(['Task Name', task.title]);
            styleHeaderRow(headerRow);
            recurringSheet.addRow(['Schedule Mode', task.scheduleMode]);
            recurringSheet.addRow(['Frequency', task.frequency]);
            if (task.scheduleMode === 'fixed') {
                if (task.scheduleType === 'week') {
                    recurringSheet.addRow(['Day of Week', ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'][task.dayOfWeek]]);
                } else if (task.scheduleType === 'month') {
                    recurringSheet.addRow(['Date of Month', task.dateOfMonth]);
                }
            }
            recurringSheet.addRow(['Next Due', new Date(task.nextDueDate).toLocaleString()]);
            if (task.lastCompleted) {
                recurringSheet.addRow(['Last Completed', new Date(task.lastCompleted).toLocaleString()]);
            }
            recurringSheet.addRow(['Status', task.status]);
            
            const taskTodos = todos.filter(todo => todo.location === `recurring-${task.id}`);
            if (taskTodos.length > 0) {
                const todoHeaderRow = recurringSheet.addRow(['Todo', 'Status']);
                styleHeaderRow(todoHeaderRow);
                taskTodos.forEach(todo => {
                    recurringSheet.addRow([todo.text, todo.completed ? 'Completed' : 'Pending']);
                });
            }
            recurringSheet.addRow([]);
            recurringSheet.addRow([]); // Add extra space between tasks
        });

        // Save file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { 
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `planner-export-${new Date().toISOString().split('T')[0]}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <div className="p-4 sm:p-6 space-y-6">
            <div className="space-y-4 border border-[var(--surface-border)] rounded-lg p-3 sm:p-4">
                <h4 className="text-sm font-bold text-[var(--text-primary)] text-left">Create Excel Export</h4>
                <p className="text-sm text-[var(--text-secondary)]">
                    Export your data to Excel format for backup or analysis.
                </p>
                <div className="space-y-4">
                    <button
                        onClick={handleExportAll}
                        className="base-button w-full px-4 py-3 rounded flex items-center gap-2 justify-center"
                    >
                        <span className="material-symbols-outlined">description</span>
                        Create Excel Export
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ExportSettings; 
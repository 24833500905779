import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isWithinInterval, startOfWeek, endOfWeek, parseISO, isToday } from 'date-fns';
import { calculateTaskDates, completeRecurringTask } from '../../store/recurringTasksSlice';

function RecurringTaskWeekly({ tasks: propTasks }) {
    const dispatch = useDispatch();
    const selectorTasks = useSelector(state => state.recurringTasks.items);
    
    const recurringTasks = propTasks || selectorTasks;
    
    const relevantTasks = useMemo(() => {
        const now = new Date();
        const weekStart = startOfWeek(now, { weekStartsOn: 0 });
        const weekEnd = endOfWeek(now, { weekStartsOn: 0 });

        const filteredTasks = recurringTasks.filter(task => {
            // If the task was completed today, filter it out
            if (task.lastCompleted && isToday(parseISO(task.lastCompleted))) {
                return false;
            }

            const dates = calculateTaskDates({
                ...task,
                nextDueDate: task.nextDueDate ? parseISO(task.nextDueDate) : null,
                isDateUpdate: true
            });
            if (!dates) return false;

            const nextDueDate = parseISO(dates.nextDueDate);
            const graceEnd = parseISO(dates.gracePeriodRange.end);

            // Include tasks that are either within this week OR overdue
            if (task.scheduleMode === 'fixed') {
                return isWithinInterval(nextDueDate, { start: weekStart, end: weekEnd }) || 
                       now > graceEnd; // Include overdue tasks
            } else {
                const prepStart = parseISO(dates.preparationRange.start);
                
                return (
                    isWithinInterval(prepStart, { start: weekStart, end: weekEnd }) ||
                    isWithinInterval(graceEnd, { start: weekStart, end: weekEnd }) ||
                    (prepStart <= weekStart && graceEnd >= weekEnd) ||
                    now > graceEnd // Include overdue tasks
                );
            }
        });

        // Sort tasks by due date
        return filteredTasks.sort((a, b) => {
            const datesA = calculateTaskDates({
                ...a,
                nextDueDate: a.nextDueDate ? parseISO(a.nextDueDate) : null,
                isDateUpdate: true
            });
            const datesB = calculateTaskDates({
                ...b,
                nextDueDate: b.nextDueDate ? parseISO(b.nextDueDate) : null,
                isDateUpdate: true
            });

            return parseISO(datesA.nextDueDate) - parseISO(datesB.nextDueDate);
        });
    }, [recurringTasks]);

    const handleComplete = (taskId) => {
        dispatch(completeRecurringTask({ 
            taskId, 
            completionDate: new Date().toISOString() 
        }));
    };

    if (relevantTasks.length === 0) {
        return null;
    }

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold text-[var(--text-secondary)] text-left">Recurring Tasks</h3>
            </div>
            <div className="space-y-2">
                {relevantTasks.map(task => {
                    const dates = calculateTaskDates({
                        ...task,
                        nextDueDate: task.nextDueDate ? parseISO(task.nextDueDate) : null,
                        isDateUpdate: true
                    });
                    const nextDueDate = parseISO(dates.nextDueDate);
                    const now = new Date();
                    
                    let statusInfo = '';
                    let statusClass = '';
                    const graceEnd = parseISO(dates.gracePeriodRange.end);

                    if (task.scheduleMode === 'interval') {
                        if (dates.isOverdue) {
                            statusInfo = 'Overdue';
                            statusClass = 'text-red-500 font-medium';
                        } else {
                            const prepStart = parseISO(dates.preparationRange.start);
                            
                            if (now < prepStart) {
                                statusInfo = 'Not Started';
                            } else if (now < nextDueDate) {
                                statusInfo = 'Preparation Phase';
                            } else if (now <= graceEnd) {
                                statusInfo = 'Grace Period';
                            }
                        }
                    } else if (task.scheduleMode === 'fixed') {
                        if (isToday(nextDueDate)) {
                            statusInfo = 'Due Today';
                            statusClass = 'text-yellow-500 font-medium';
                        } else if (now > nextDueDate) {
                            if (now <= graceEnd) {
                                statusInfo = 'Grace Period';
                            } else {
                                statusInfo = 'Overdue';
                                statusClass = 'text-red-500 font-medium';
                            }
                        }
                    }

                    let scheduleChip = null;
                    if (task.scheduleMode === 'interval') {
                        scheduleChip = (
                            <div className="text-xs px-2 py-1 rounded-full bg-blue-900/30 border border-blue-800/30 text-blue-400 flex items-center gap-1">
                                <span className="material-symbols-outlined text-[14px] leading-none">
                                    cycle
                                </span>
                                <span className="leading-none">{task.frequency}</span>
                            </div>
                        );
                    } else if (task.scheduleMode === 'fixed') {
                        let chipText;
                        if (task.scheduleType === 'week') {
                            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                            chipText = days[parseInt(task.dayOfWeek)];
                        } else {
                            chipText = task.dateOfMonth;
                        }
                            
                        scheduleChip = (
                            <div className="text-xs px-2 py-1 rounded-full bg-purple-900/30 border border-purple-800/30 text-purple-400 flex items-center gap-1">
                                <span className="material-symbols-outlined text-[14px] leading-none">
                                    cycle
                                </span>
                                <span className="leading-none">{chipText}</span>
                            </div>
                        );
                    }
                    
                    return (
                        <div 
                            key={task.id}
                            className="flex items-start gap-2 p-2 bg-[var(--surface-bg-darker)] border border-[var(--surface-border)] rounded-md group"
                        >
                            <input
                                type="checkbox"
                                checked={false}
                                onChange={() => handleComplete(task.id)}
                                className="w-5 h-5 mt-1 rounded appearance-none border-2 border-[var(--border-dark)] bg-[var(--input-bg)] checked:bg-[var(--input-bg)] checked:border-[var(--border-dark)] hover:border-[var(--border-light)] transition-colors cursor-pointer relative
                                checked:after:content-['✓'] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:text-[var(--text-muted)] checked:after:text-sm"
                            />
                            
                            <div className="flex-1 min-w-0">
                                <div className="font-medium text-left break-words">{task.title}</div>
                                <div className="text-sm text-[var(--text-muted)] flex flex-wrap gap-2">
                                    <span>Due: {nextDueDate.toLocaleDateString()}</span>
                                    {statusInfo && (
                                        <>
                                            <span>•</span>
                                            <span className={statusClass}>{statusInfo}</span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="shrink-0 mt-0.5">
                                {scheduleChip}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default RecurringTaskWeekly; 
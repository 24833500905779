import axios from 'axios';

const API_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3001/api';

const api = axios.create({
    baseURL: API_URL
});

// Add a request interceptor
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401 || error.response?.status === 403) {
            // Clear all storage
            localStorage.clear();
            sessionStorage.clear();
            
            // Redirect to login only if not already on login page
            if (!window.location.pathname.includes('/login')) {
                window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    }
);

// Theme-related API endpoints
export const themeApi = {
    // Get user's theme settings
    getUserTheme: () => api.get('/themes/user'),
    
    // Save user's theme settings
    saveUserTheme: (themeData) => api.post('/themes/user', themeData),
    
    // Get user's custom themes
    getCustomThemes: () => api.get('/themes/custom'),
    
    // Save a custom theme
    saveCustomTheme: (name, themeData) => api.post('/themes/custom', { name, theme: themeData }),
    
    // Delete a custom theme
    deleteCustomTheme: (name) => api.delete(`/themes/custom/${name}`)
};

export default api; 
import { useState } from 'react';
import { createPortal } from 'react-dom';
import ThemeSettings from './tabs/ThemeSettings';
import DateSettings from './tabs/DateSettings';
import ExportSettings from './tabs/ExportSettings';
import ProjectIcons from './tabs/ProjectIcons';
import BackupSettings from './tabs/BackupSettings';

const SettingsModal = ({ isOpen, onClose }) => {
    const [activeTab, setActiveTab] = useState('dates');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const tabs = [
        { id: 'dates', label: 'Dates', icon: 'calendar_month' },
        { id: 'theme', label: 'Theme', icon: 'palette' },
        { id: 'icons', label: 'Project Icons', icon: 'emoji_symbols' },
        { id: 'export', label: 'Excel Export', icon: 'description' },
        { id: 'backup', label: 'Backups', icon: 'backup' }
    ];

    const renderTabContent = () => {
        switch (activeTab) {
            case 'theme':
                return <ThemeSettings />;
            case 'dates':
                return <DateSettings />;
            case 'icons':
                return <ProjectIcons />;
            case 'export':
                return <ExportSettings />;
            case 'backup':
                return <BackupSettings />;
            default:
                return <ThemeSettings />;
        }
    };

    if (!isOpen) return null;

    return (
        <>
            {createPortal(
                <div className="fixed inset-0 bg-black/50 z-[9000] flex items-center justify-center">
                    <div className="bg-[var(--surface-bg)] rounded-lg w-full max-w-2xl h-[90vh] flex flex-col shadow-lg mx-4">
                        {/* Header */}
                        <div className="flex items-center justify-between p-4">
                            <div className="flex-1 md:hidden">
                                <button
                                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                                    className="p-2 hover:bg-[var(--button-hover-bg)] rounded-full text-[var(--text-primary)] transition-colors"
                                >
                                    <span className="material-symbols-outlined">menu</span>
                                </button>
                            </div>
                            <h2 className="flex-1 text-xl font-semibold text-[var(--text-primary)] text-center">Settings</h2>
                            <div className="flex-1 flex justify-end">
                                <button
                                    onClick={onClose}
                                    className="p-2 hover:bg-[var(--button-hover-bg)] rounded-md text-[var(--text-primary)] transition-colors"
                                >
                                    <span className="material-symbols-outlined">close</span>
                                </button>
                            </div>
                        </div>

                        {/* Content */}
                        <div className="flex flex-1 min-h-0">
                            {/* Sidebar - Hidden on mobile */}
                            <div className="hidden md:block w-48 p-2 bg-[var(--surface-bg)]">
                                {tabs.map(tab => (
                                    <button
                                        key={tab.id}
                                        onClick={() => setActiveTab(tab.id)}
                                        className={`w-full text-left px-3 py-2 rounded flex items-center gap-2 transition-colors
                                            ${activeTab === tab.id 
                                                ? 'bg-[var(--selection-bg)] text-[var(--text-primary)]'
                                                : 'text-[var(--text-secondary)] hover:bg-[var(--button-hover-bg)]'
                                            }`}
                                    >
                                        <span className="material-symbols-outlined">{tab.icon}</span>
                                        {tab.label}
                                    </button>
                                ))}
                            </div>

                            {/* Tab Content */}
                            <div className="flex-1 overflow-y-auto">
                                {renderTabContent()}
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )}

            {isMobileMenuOpen && createPortal(
                <div className="md:hidden fixed inset-0 bg-black/50 z-[9999]">
                    <div className="bg-[var(--surface-bg)] w-64 h-full p-4 animate-slide-in relative rounded-r-lg">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-lg font-semibold text-[var(--text-primary)]">Menu</h3>
                            <button
                                onClick={() => setIsMobileMenuOpen(false)}
                                className="p-2 hover:bg-[var(--button-hover-bg)] rounded-md"
                            >
                                <span className="material-symbols-outlined">close</span>
                            </button>
                        </div>
                        {tabs.map(tab => (
                            <button
                                key={tab.id}
                                onClick={() => {
                                    setActiveTab(tab.id);
                                    setIsMobileMenuOpen(false);
                                }}
                                className={`w-full text-left px-3 py-2 rounded flex items-center gap-2 transition-colors mb-2
                                    ${activeTab === tab.id 
                                        ? 'bg-[var(--selection-bg)] text-[var(--text-primary)]'
                                        : 'text-[var(--text-secondary)] hover:bg-[var(--button-hover-bg)]'
                                    }`}
                            >
                                <span className="material-symbols-outlined">{tab.icon}</span>
                                {tab.label}
                            </button>
                        ))}
                    </div>
                </div>,
                document.body
            )}
        </>
    );
};

export default SettingsModal; 
export const localStorageMiddleware = store => next => action => {
  const result = next(action);
  const state = store.getState();

  // Save to localStorage based on action types
  if (action.type.startsWith('projects/')) {
    localStorage.setItem('projects', JSON.stringify(state.projects.items));
  }
  if (action.type.startsWith('meetings/')) {
    localStorage.setItem('meetings', JSON.stringify(state.meetings.items));
  }
  if (action.type.startsWith('todos/')) {
    localStorage.setItem('todos', JSON.stringify(state.todos.items));
  }
  if (action.type.startsWith('recurringTasks/')) {
    localStorage.setItem('recurringTasks', JSON.stringify(state.recurringTasks.items));
  }
  if (action.type.startsWith('weeklyEvents/')) {
    localStorage.setItem('weeklyEvents', JSON.stringify(state.weeklyEvents.items));
  }

  return result;
}; 
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StyledMobileDatePicker, dateTimePickerSlotProps, getCommonPickerStyles } from '../../themes/MUIStyles';
import { format } from 'date-fns';
import { useRecurringTasks } from '../../hooks/useRecurringTasks';
import { useDispatch, useSelector } from 'react-redux';
import { addRecurringTask } from '../../store/recurringTasksSlice';
import { updateSetting } from '../../store/settingsSlice';

function DaySelector({ type, value, onChange }) {
  const days = type === 'week' 
    ? [
        { value: 0, label: 'Sun' },
        { value: 1, label: 'Mon' },
        { value: 2, label: 'Tue' },
        { value: 3, label: 'Wed' },
        { value: 4, label: 'Thu' },
        { value: 5, label: 'Fri' },
        { value: 6, label: 'Sat' }
      ]
    : Array.from({ length: 31 }, (_, i) => ({
        value: i + 1,
        label: String(i + 1)
      }));

  return (
    <div className={`grid ${type === 'week' 
      ? 'grid-cols-4 sm:grid-cols-7' 
      : 'grid-cols-4 sm:grid-cols-7 md:grid-cols-8'} gap-1 w-full max-w-[400px]`}>
      {days.map(day => (
        <button
          key={day.value}
          type="button"
          onClick={() => onChange(day.value)}
          className={`p-1 sm:p-2 rounded-md text-xs sm:text-sm transition-colors
            ${Number(value) === day.value 
              ? 'bg-[var(--primary-button-active)] text-[var(--text-primary)]' 
              : 'bg-[var(--surface-bg)] text-[var(--text-disabled)] hover:bg-[var(--surface-bg-lighter)] hover:text-[var(--text-primary)]'
            }`}
        >
          {day.label}
        </button>
      ))}
    </div>
  );
}

function TaskHistory({ task, onMarkDone, onLastCompletedChange }) {
  const { calculateTaskDates } = useRecurringTasks();
  const dates = useMemo(() => calculateTaskDates(task), [task, calculateTaskDates]);

  const handleLastDoneChange = (newDate) => {
    if (newDate) {
      // Normalize the date to midnight
      const normalizedDate = new Date(newDate);
      normalizedDate.setHours(0, 0, 0, 0);
      
      // Call the parent handler with the normalized date
      onLastCompletedChange(normalizedDate);
    }
  };

  if (!dates) return null;

  return (
    <div className="bg-[var(--surface-bg-darker)] p-6 rounded-lg space-y-4 mt-8 text-left">
      <h3 className="text-lg font-medium text-[var(--text-secondary)]">Task History</h3>
      <div className="space-y-2">
        <div className="flex items-center flex-wrap gap-2">
          <span className="text-[var(--text-muted)] w-[100px] shrink-0">Last done:</span>
          <div className="flex items-center gap-2 min-w-[200px]">
            <div className="shrink-0">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StyledMobileDatePicker
                  value={dates.lastCompleted ? new Date(dates.lastCompleted) : null}
                  onChange={handleLastDoneChange}
                  slotProps={{
                    ...dateTimePickerSlotProps,
                    textField: getCommonPickerStyles('auto')
                  }}
                />
              </LocalizationProvider>
            </div>
            <button
              onClick={() => onMarkDone(new Date())}
              className="base-button flash-button rounded-md !py-1 !px-2 !text-sm shrink-0"
            >
              Mark Done Today
            </button>
          </div>
        </div>

        {task.scheduleMode !== 'fixed' && dates.gracePeriodRange && dates.preparationRange && (
          <>
            <div className="flex items-center gap-4">
              <span className="text-[var(--text-muted)] w-[100px]">Grace Period:</span>
              <span className="text-[var(--text-primary)]">
                {format(new Date(dates.gracePeriodRange.start), 'MM/dd/yyyy')} to{' '}
                {format(new Date(dates.gracePeriodRange.end), 'MM/dd/yyyy')}
              </span>
            </div>

            <div className="flex items-center gap-4">
              <span className="text-[var(--text-muted)] w-[100px]">Preparation:</span>
              <span className="text-[var(--text-primary)]">
                {format(new Date(dates.preparationRange.start), 'MM/dd/yyyy')} to{' '}
                {format(new Date(dates.preparationRange.end), 'MM/dd/yyyy')}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function RecurringTasks() {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings.settings);
  const { 
    tasks: recurringTasks,
    handleUpdateTask: updateRecurringTask,
    handleCompleteTask,
    handleSetActiveTask
  } = useRecurringTasks();

  const activeRecurringTask = taskId ? recurringTasks.find(t => t.id === taskId) : null;

  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState('');
  const inputRef = useRef(null);
  const [formData, setFormData] = useState({
    title: '',
    scheduleMode: 'fixed',
    scheduleType: 'week',
    dayOfWeek: null,
    dateOfMonth: null,
    frequency: 'Weekly',
    preparationTime: 0,
    gracePeriod: 0,
    nextDueDate: new Date(),
  });

  // Add update timer refs
  const updateTimerRef = useRef(null);
  const lastUpdateRef = useRef({});

  // Effect to handle numeric field updates
  useEffect(() => {
    if (taskId && taskId !== 'new' && activeRecurringTask) {
      const numericFields = ['preparationTime', 'gracePeriod'];
      const updates = {};
      let hasUpdates = false;

      const MAX_DAYS = 9999; // Maximum number of days allowed

      numericFields.forEach(field => {
        const currentValue = formData[field];
        const lastValue = lastUpdateRef.current[field];
        
        if (currentValue !== lastValue && 
            (currentValue === '' || /^\d+$/.test(currentValue))) {
          // Parse and clamp the value
          const parsedValue = currentValue === '' ? 0 : parseInt(currentValue, 10);
          const clampedValue = Math.min(Math.max(parsedValue, 0), MAX_DAYS);
          
          // Only update if the value is different from the last update
          if (clampedValue !== lastUpdateRef.current[field]) {
            updates[field] = clampedValue;
            lastUpdateRef.current[field] = currentValue;
            hasUpdates = true;
          }
        }
      });

      if (hasUpdates) {
        if (updateTimerRef.current) {
          clearTimeout(updateTimerRef.current);
        }

        updateTimerRef.current = setTimeout(() => {
          updateRecurringTask(taskId, { ...updates, forceRecalculate: true });
        }, 500);
      }
    }

    return () => {
      if (updateTimerRef.current) {
        clearTimeout(updateTimerRef.current);
      }
    };
  }, [taskId, formData.preparationTime, formData.gracePeriod, updateRecurringTask, activeRecurringTask, formData]);

  useEffect(() => {
    if (!taskId && recurringTasks.length > 0) {
      const lastOpenedTaskId = settings.lastOpenedRecurringTaskId;
      const targetTask = lastOpenedTaskId && recurringTasks.find(t => t.id === lastOpenedTaskId)
        ? lastOpenedTaskId 
        : recurringTasks[0].id;
      navigate(`/recurring-tasks/${targetTask}`);
    } else if (taskId && recurringTasks.length === 0 && taskId !== 'new') {
      navigate('/recurring-tasks');
    } else if (taskId && taskId !== 'new') {
      const task = recurringTasks.find(t => t.id === taskId);
      if (task) {
        handleSetActiveTask(task);
        
        if (!formData.title || task.title !== formData.title) {
          setFormData(prevData => ({
            ...prevData,
            title: task.title,
            scheduleMode: task.scheduleMode || 'fixed',
            scheduleType: task.scheduleType || 'week',
            dayOfWeek: task.dayOfWeek || null,
            dateOfMonth: task.dateOfMonth || null,
            frequency: task.frequency,
            preparationTime: task.preparationTime,
            gracePeriod: task.gracePeriod,
            nextDueDate: task.nextDueDate ? new Date(task.nextDueDate) : new Date(),
          }));
        }
      }
    }
  }, [taskId, recurringTasks, navigate, formData.title, handleSetActiveTask, settings.lastOpenedRecurringTaskId]);

  useEffect(() => {
    if (taskId === 'new') {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      setFormData({
        title: '',
        scheduleMode: 'fixed',
        scheduleType: 'week',
        dayOfWeek: null,
        dateOfMonth: null,
        frequency: 'Weekly',
        preparationTime: 0,
        gracePeriod: 0,
        nextDueDate: today,
      });
      setIsEditing(false);
      setEditValue('');
    } else if (activeRecurringTask) {
      const nextDueDate = activeRecurringTask.nextDueDate ? new Date(activeRecurringTask.nextDueDate) : new Date();
      nextDueDate.setHours(0, 0, 0, 0);
      
      setFormData({
        title: activeRecurringTask.title,
        scheduleMode: activeRecurringTask.scheduleMode || 'fixed',
        scheduleType: activeRecurringTask.scheduleType || 'week',
        dayOfWeek: activeRecurringTask.dayOfWeek || null,
        dateOfMonth: activeRecurringTask.dateOfMonth || null,
        frequency: activeRecurringTask.frequency,
        preparationTime: activeRecurringTask.preparationTime,
        gracePeriod: activeRecurringTask.gracePeriod,
        nextDueDate: nextDueDate,
      });
      setEditValue(activeRecurringTask.title);
    }
  }, [activeRecurringTask, taskId]);

  const handleStartEdit = () => {
    setIsEditing(true);
    setEditValue(formData.title);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleSubmitEdit = () => {
    if (editValue.trim() && editValue !== formData.title) {
      setFormData(prev => ({ ...prev, title: editValue.trim() }));
      if (taskId && taskId !== 'new') {
        updateRecurringTask(taskId, { 
          title: editValue.trim(),
          forceRecalculate: false
        });
      }
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmitEdit();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
    }
  };

  const getNextOccurrence = (scheduleType, value) => {
    const today = new Date();
    let nextDate = new Date(today);
    nextDate.setHours(0, 0, 0, 0);
    
    if (scheduleType === 'week' && value !== null) {
      const currentDay = today.getDay();
      const targetDay = Number(value);
      let daysUntilNext = targetDay - currentDay;
      if (daysUntilNext <= 0) daysUntilNext += 7;
      nextDate.setDate(today.getDate() + daysUntilNext);
    } else if (scheduleType === 'month' && value !== null) {
      const targetDate = Number(value);
      nextDate.setDate(targetDate);
      if (nextDate < today) {
        nextDate.setMonth(nextDate.getMonth() + 1);
      }
      
      // Handle months with fewer days
      const lastDayOfMonth = new Date(nextDate.getFullYear(), nextDate.getMonth() + 1, 0).getDate();
      if (targetDate > lastDayOfMonth) {
        nextDate.setDate(lastDayOfMonth);
      }
    }
    
    return nextDate;
  };

  const handleDateChange = (newDate) => {
    if (!newDate) return;
    
    // Normalize the date to midnight
    const normalizedDate = new Date(newDate);
    normalizedDate.setHours(0, 0, 0, 0);
    
    if (taskId && taskId !== 'new') {
      const updateData = {
        nextDueDate: normalizedDate.toISOString(),
        forceRecalculate: true,
        scheduleMode: formData.scheduleMode,
        scheduleType: formData.scheduleType,
        dayOfWeek: formData.dayOfWeek,
        dateOfMonth: formData.dateOfMonth,
        frequency: formData.frequency,
        preparationTime: formData.preparationTime,
        gracePeriod: formData.gracePeriod,
        isDateUpdate: true
      };

      updateRecurringTask(taskId, updateData)
        .then(response => {
          // Update form data with the response
          setFormData(prevData => ({
            ...prevData,
            nextDueDate: new Date(response.nextDueDate)
          }));
          // Update the active task
          handleSetActiveTask(response);
        })
        .catch(error => {
          console.error('Failed to update date:', error);
          // Revert form data if update fails
          setFormData(prevData => ({
            ...prevData,
            nextDueDate: prevData.nextDueDate
          }));
        });
    } else {
      // For new tasks, just update the form data
      setFormData(prevData => ({
        ...prevData,
        nextDueDate: normalizedDate
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    const newFormData = { ...formData };
    let nextDueDate = null;
    
    if (name === 'dayOfWeek' && value !== null) {
      newFormData.dayOfWeek = Number(value);
      newFormData.dateOfMonth = null;
      newFormData.scheduleType = 'week';
      nextDueDate = getNextOccurrence('week', value);
      
      if (taskId && taskId !== 'new') {
        updateRecurringTask(taskId, {
          dayOfWeek: Number(value),
          dateOfMonth: null,
          scheduleType: 'week',
          nextDueDate: nextDueDate.toISOString(),
          forceRecalculate: true
        });
      }
    } else if (name === 'dateOfMonth' && value !== null) {
      newFormData.dateOfMonth = Number(value);
      newFormData.dayOfWeek = null;
      newFormData.scheduleType = 'month';
      nextDueDate = getNextOccurrence('month', value);
      
      if (taskId && taskId !== 'new') {
        updateRecurringTask(taskId, {
          dateOfMonth: Number(value),
          dayOfWeek: null,
          scheduleType: 'month',
          nextDueDate: nextDueDate.toISOString(),
          forceRecalculate: true
        });
      }
    } else if (name === 'preparationTime' || name === 'gracePeriod') {
      // Only allow numbers and empty string
      if (value === '' || /^\d{0,4}$/.test(value)) {
        newFormData[name] = value;
      }
    } else if (name === 'title') {
      // Special handling for title updates
      newFormData.title = value;
      
      if (taskId && taskId !== 'new') {
        const trimmedTitle = value.trim();
        if (trimmedTitle) {
          updateRecurringTask(taskId, {
            title: trimmedTitle
          });
        }
      }
    } else {
      newFormData[name] = value;
      
      if (taskId && taskId !== 'new') {
        const updates = {
          [name]: value,
          forceRecalculate: true
        };
        if (nextDueDate) {
          updates.nextDueDate = nextDueDate.toISOString();
        }
        updateRecurringTask(taskId, updates);
      }
    }

    if (nextDueDate) {
      newFormData.nextDueDate = nextDueDate;
    }
    setFormData(newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedTitle = formData.title.trim();
    if (trimmedTitle) {
      const newTask = {
        title: trimmedTitle,
        scheduleMode: formData.scheduleMode,
        scheduleType: formData.scheduleType,
        dayOfWeek: formData.dayOfWeek,
        dateOfMonth: formData.dateOfMonth,
        frequency: formData.frequency,
        preparationTime: formData.preparationTime === '' ? 0 : parseInt(formData.preparationTime, 10),
        gracePeriod: formData.gracePeriod === '' ? 0 : parseInt(formData.gracePeriod, 10),
        nextDueDate: formData.nextDueDate,
      };
      
      try {
        const result = await dispatch(addRecurringTask(newTask)).unwrap();
        if (result?.id) {
          navigate(`/recurring-tasks/${result.id}`);
        }
      } catch (error) {
        console.error('Failed to create task:', error);
      }
    }
  };

  const handleScheduleModeChange = (mode) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const newFormData = {
      ...formData,
      scheduleMode: mode,
      scheduleType: mode === 'fixed' ? 'week' : undefined,
      dayOfWeek: null,
      dateOfMonth: null,
      frequency: mode === 'interval' ? 'Weekly' : undefined,
      nextDueDate: today
    };
    setFormData(newFormData);
    
    if (taskId && taskId !== 'new') {
      const updates = {
        scheduleMode: mode,
        scheduleType: mode === 'fixed' ? 'week' : undefined,
        dayOfWeek: null,
        dateOfMonth: null,
        frequency: mode === 'interval' ? 'Weekly' : undefined,
        nextDueDate: today.toISOString(),
        forceRecalculate: true
      };
      updateRecurringTask(taskId, updates);
    }
  };

  const handleScheduleTypeChange = (type) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const newFormData = {
      ...formData,
      scheduleType: type,
      dayOfWeek: type === 'week' ? null : null,
      dateOfMonth: type === 'month' ? null : null,
      nextDueDate: today
    };
    setFormData(newFormData);
    
    if (taskId && taskId !== 'new') {
      const updates = {
        scheduleType: type,
        dayOfWeek: type === 'week' ? null : null,
        dateOfMonth: type === 'month' ? null : null,
        nextDueDate: today.toISOString(),
        forceRecalculate: true
      };
      updateRecurringTask(taskId, updates);
    }
  };

  const shouldDisableDate = (date) => {
    if (formData.scheduleMode !== 'fixed') return false;
    
    if (formData.scheduleType === 'week') {
      return date.getDay() !== Number(formData.dayOfWeek);
    }
    
    if (formData.scheduleType === 'month' && formData.dateOfMonth !== null) {
      const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      const targetDate = Number(formData.dateOfMonth);
      
      if (targetDate > lastDayOfMonth) {
        return date.getDate() !== lastDayOfMonth;
      }
      
      return date.getDate() !== targetDate;
    }
    
    return false;
  };

  const shouldShowDaySelector = () => {
    if (formData.scheduleMode !== 'fixed') return false;
    if (formData.scheduleType === 'week') return true;
    if (formData.scheduleType === 'month') return true;
    return false;
  };

  const handleLastCompletedChange = (date) => {
    if (!taskId || taskId === 'new') return;

    const updateData = {
      lastCompleted: date.toISOString(),
      forceRecalculate: true,
      scheduleMode: formData.scheduleMode,
      scheduleType: formData.scheduleType,
      dayOfWeek: formData.dayOfWeek,
      dateOfMonth: formData.dateOfMonth,
      frequency: formData.frequency,
      preparationTime: formData.preparationTime,
      gracePeriod: formData.gracePeriod
    };

    updateRecurringTask(taskId, updateData)
      .then(response => {
        // Update the active task with the response
        handleSetActiveTask(response);
      })
      .catch(error => {
        console.error('Failed to update last completed date:', error);
      });
  };

  // Store last opened task when task changes
  useEffect(() => {
    if (taskId && taskId !== 'new') {
      dispatch(updateSetting({ key: 'lastOpenedRecurringTaskId', value: taskId }));
    }
  }, [taskId, dispatch]);

  if (!taskId) {
    return (
      <div className="flex items-center justify-center h-full text-[var(--text-muted)]">
        Select a recurring task from the sidebar or create a new one
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="w-full">
        <div className="flex flex-col sm:flex-row justify-start items-start gap-4">
          {taskId === 'new' ? (
            <div className="w-full">
              <h2 className="text-2xl font-semibold text-gray-200 mb-4 text-left">New Recurring Task</h2>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Enter task title..."
                className="w-full px-3 py-2 text-lg bg-[var(--input-bg)] border border-[var(--input-border)] focus:border-[var(--input-border-hover)] focus:outline-none rounded-md text-[var(--text-primary)] placeholder-[var(--text-muted)]"
              />
            </div>
          ) : (
            <div className="flex flex-col sm:flex-row items-start gap-4 w-full">
              {isEditing ? (
                <>
                  <div className="w-full sm:w-fit">
                    <input
                      ref={inputRef}
                      type="text"
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      onBlur={handleSubmitEdit}
                      onKeyDown={handleKeyDown}
                      className="w-full text-2xl font-semibold bg-[var(--surface-bg)] border border-[var(--border-light)] rounded px-2 py-1 focus:outline-none focus:border-[var(--text-primary)]"
                    />
                  </div>
                  <div className="flex items-center gap-4 shrink-0">
                    <button
                      onClick={handleSubmitEdit}
                      className="base-button !px-1.5 !py-1 flex items-center justify-center !rounded-md shrink-0"
                      title="Save task name"
                    >
                      <span className="material-symbols-outlined text-[18px]">
                        check
                      </span>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-full sm:w-fit">
                    <h2 className="text-2xl font-semibold text-[var(--text-primary)] break-words text-left">
                      {formData.title}
                    </h2>
                  </div>
                  <div className="flex items-center gap-4 shrink-0">
                    <button
                      onClick={handleStartEdit}
                      className="base-button !px-1.5 !py-1 flex items-center justify-center !rounded-md shrink-0"
                      title="Edit task name"
                    >
                      <span className="material-symbols-outlined text-[18px]">
                        edit
                      </span>
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <form onSubmit={handleSubmit} className="mt-6 space-y-6">
        <div className="space-y-6">
          <div className="text-left">
            <h3 className="text-lg font-medium mb-4">Schedule Type</h3>
            <div className="slider-container">
              <div
                className="slider-track"
                style={{
                  transform: `translateX(${formData.scheduleMode === 'interval' ? '100%' : '0'})`
                }}
              />
              <div className="slider-buttons">
                <button
                  type="button"
                  onClick={() => handleScheduleModeChange('fixed')}
                  className={`slider-button ${formData.scheduleMode === 'fixed' ? 'active' : ''}`}
                >
                  Fixed Schedule
                </button>
                <button
                  type="button"
                  onClick={() => handleScheduleModeChange('interval')}
                  className={`slider-button ${formData.scheduleMode === 'interval' ? 'active' : ''}`}
                >
                  Interval Schedule
                </button>
              </div>
            </div>
          </div>

          {formData.scheduleMode === 'fixed' && (
            <div className="space-y-4">
              <div className="text-left">
                <h3 className="text-lg font-medium mb-4">Schedule Every:</h3>
                <div className="slider-container">
                  <div
                    className="slider-track"
                    style={{
                      transform: `translateX(${formData.scheduleType === 'month' ? '100%' : '0'})`
                    }}
                  />
                  <div className="slider-buttons">
                    <button
                      type="button"
                      onClick={() => handleScheduleTypeChange('week')}
                      className={`slider-button ${formData.scheduleType === 'week' ? 'active' : ''}`}
                    >
                      Day of Week
                    </button>
                    <button
                      type="button"
                      onClick={() => handleScheduleTypeChange('month')}
                      className={`slider-button ${formData.scheduleType === 'month' ? 'active' : ''}`}
                    >
                      Date of Month
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-4 text-left">
                {shouldShowDaySelector() && (
                  <DaySelector
                    type={formData.scheduleType}
                    value={formData.scheduleType === 'week' ? formData.dayOfWeek : formData.dateOfMonth}
                    onChange={(value) => handleChange({
                      target: {
                        name: formData.scheduleType === 'week' ? 'dayOfWeek' : 'dateOfMonth',
                        value
                      }
                    })}
                  />
                )}
              </div>
            </div>
          )}

          {formData.scheduleMode === 'interval' && (
            <div className="space-y-4 max-w-[600px] text-left">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-2 text-gray-300">Frequency</label>
                  <select
                    name="frequency"
                    value={formData.frequency}
                    onChange={handleChange}
                    className="w-[300px] p-2 bg-[#2a2a2a] border border-[#3a3a3a] focus:border-white focus:outline-none rounded-md text-sm"
                  >
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Every 6 Months">Every 6 Months</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium mb-2 text-gray-300">Preparation Time (days)</label>
                  <input
                    type="text"
                    name="preparationTime"
                    value={formData.preparationTime}
                    onChange={handleChange}
                    onFocus={(e) => {
                      if (e.target.value === '0') {
                        e.target.select();
                      }
                    }}
                    className="w-[300px] p-2 bg-[#2a2a2a] border border-[#3a3a3a] focus:border-white focus:outline-none rounded-md text-sm"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-2 text-gray-300">Grace Period (days)</label>
                  <input
                    type="text"
                    name="gracePeriod"
                    value={formData.gracePeriod}
                    onChange={handleChange}
                    onFocus={(e) => {
                      if (e.target.value === '0') {
                        e.target.select();
                      }
                    }}
                    className="w-[300px] p-2 bg-[#2a2a2a] border border-[#3a3a3a] focus:border-white focus:outline-none rounded-md text-sm"
                  />
                </div>
              </div>
            </div>
          )}

          <div className="pt-2 text-left">
            <label className="block text-sm font-medium mb-2 text-gray-300">Next Due Date:</label>
            <div className="w-[300px]">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StyledMobileDatePicker
                  value={formData.nextDueDate}
                  onChange={handleDateChange}
                  slotProps={{
                    ...dateTimePickerSlotProps,
                    textField: {
                      ...getCommonPickerStyles('300px'),
                    }
                  }}
                  shouldDisableDate={shouldDisableDate}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          {taskId === 'new' && (
            <button
              type="submit"
              className="px-4 py-2 base-button rounded-md text-white"
            >
              Create Task
            </button>
          )}
        </div>
      </form>

      {taskId && taskId !== 'new' && activeRecurringTask && (
        <TaskHistory
          task={activeRecurringTask}
          onMarkDone={(date) => handleCompleteTask(taskId, date)}
          onLastCompletedChange={handleLastCompletedChange}
        />
      )}
    </div>
  );
}

export default RecurringTasks; 
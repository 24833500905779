import { themeApi } from '../services/api';

// Default theme values (Dark Theme)
export const darkTheme = {
    // Theme Colors
    'app-bg': '#121212',
    'surface-bg': '#1e1e1e',
    'surface-border': '#323232',
    'button-bg': '#2a2a2a',
    'button-hover-bg': '#323232',
    
    // Input Colors
    'input-bg': '#2a2a2a',
    'input-border': '#323232',
    'input-border-hover': '#ffffff',

    'text-primary': '#ffffff',
    'text-secondary': '#e0e0e0',
    'text-muted': '#ababab',
    'text-disabled': '#9ca3af',

    'primary-button': '#1976d2',
    'primary-button-hover': '#1565c0',
    'primary-button-active': '#0d47a1',

    'scrollbar-thumb': '#666666',
    'scrollbar-thumb-hover': '#888888',

    'surface-bg-lighter': '#3a3a3a',
    'surface-bg-darker': '#242424',
    'surface-bg-transparent': 'rgba(42, 42, 42, 0.5)',
    
    'border-light': 'rgba(255, 255, 255, 0.2)',
    'border-dark': '#424242',

    'focus-ring': '#90caf9',

    'error': '#ef4444',
    'error-hover': '#dc2626',

    'selection-bg': 'rgba(21, 101, 192, 0.2)',

    'slider-bg': '#2a2a2a',
    'slider-track': '#0d47a1',
    'slider-text': '#9ca3af',
    'slider-text-hover': '#ffffff',
    'slider-text-active': '#ffffff',

    // Status Colors
    'status-not-started': '#6c757d',    // Gray
    'status-in-progress': '#0d6efd',    // Blue
    'status-in-review': '#ffc107',      // Yellow/Amber
    'status-completed': '#198754',       // Green
    'status-ready': '#fd7e14',      // Orange
};

// Red theme based on Material Design
export const redTheme = {
    'app-bg': '#1a0505',  // Very dark red background
    'surface-bg': '#2d0a0a',  // Dark red surface
    'surface-border': '#4a1515',
    'button-bg': '#3d0f0f',
    'button-hover-bg': '#4a1515',
    
    'input-bg': '#3d0f0f',
    'input-border': '#4a1515',
    'input-border-hover': '#d32f2f',

    'text-primary': '#ffffff',
    'text-secondary': '#ffcdd2',  // Light red text
    'text-muted': '#ef9a9a',      // Muted red text
    'text-disabled': '#b71c1c',

    'primary-button': '#d32f2f',
    'primary-button-hover': '#b71c1c',
    'primary-button-active': '#7f0000',

    'scrollbar-thumb': '#4a1515',
    'scrollbar-thumb-hover': '#5c1919',

    'surface-bg-lighter': '#4a1515',
    'surface-bg-darker': '#200707',
    'surface-bg-transparent': 'rgba(61, 15, 15, 0.5)',
    
    'border-light': 'rgba(255, 205, 210, 0.2)',  // Light red border
    'border-dark': '#4a1515',

    'focus-ring': '#ff5252',

    'error': '#ff1744',
    'error-hover': '#d50000',

    'selection-bg': 'rgba(211, 47, 47, 0.2)',

    'slider-bg': '#3d0f0f',
    'slider-track': '#d32f2f',
    'slider-text': '#ef9a9a',
    'slider-text-hover': '#ffffff',
    'slider-text-active': '#ffffff',

    // Status Colors - Red theme variations
    'status-not-started': '#9e9e9e',    // Gray
    'status-in-progress': '#ef5350',    // Light red
    'status-in-review': '#ff8a65',      // Light orange/coral
    'status-completed': '#d32f2f',      // Deep red
    'status-ready': '#ff8c00',      // Dark orange for red theme
};

// Export all available themes
export const presetThemes = {
    dark: darkTheme,
    red: redTheme
};

// Initialize theme function
export async function initializeTheme(themeName = 'dark') {
    let theme = darkTheme; // Default fallback
    let selectedTheme = 'dark'; // Always default to 'dark'

    // Check if user is authenticated
    const token = localStorage.getItem('token');
    
    if (token) {
        try {
            // Try to get the theme from the backend
            const response = await themeApi.getUserTheme();
            if (response.data) {
                const { theme: savedTheme, selectedTheme: savedThemeName } = response.data;
                
                // If we have a saved theme with custom colors, use it
                if (savedTheme && Object.keys(savedTheme).length > 0) {
                    theme = savedTheme;
                    selectedTheme = savedThemeName || 'dark'; // Default to dark if no selection
                }
                // If we have a selected preset theme, use that
                else if (savedThemeName && presetThemes[savedThemeName]) {
                    theme = presetThemes[savedThemeName];
                    selectedTheme = savedThemeName;
                }
                // If we have a theme name passed in and it's a valid preset, use that
                else if (themeName && presetThemes[themeName]) {
                    theme = presetThemes[themeName];
                    selectedTheme = themeName;
                }

                // Save the theme selection back to the backend if it's different
                if (selectedTheme !== savedThemeName) {
                    await themeApi.saveUserTheme({
                        theme,
                        selectedTheme
                    });
                }
            } else {
                // If no data, use dark theme
                theme = darkTheme;
                selectedTheme = 'dark';
                
                // Save the default theme to backend
                await themeApi.saveUserTheme({
                    theme,
                    selectedTheme
                });
            }
        } catch (error) {
            console.error('Error loading theme from backend:', error);
            // Use preset theme if backend fails
            if (themeName && presetThemes[themeName]) {
                theme = presetThemes[themeName];
                selectedTheme = themeName;
            }
        }
    } else {
        // Not authenticated, use preset theme
        if (themeName && presetThemes[themeName]) {
            theme = presetThemes[themeName];
            selectedTheme = themeName;
        }
    }

    // Apply the theme
    Object.entries(theme).forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--${key}`, value);
    });

    return { theme, selectedTheme };
} 
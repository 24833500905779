import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TodoItem from './TodoItem';
import { useTodoDragAndDrop } from '../../hooks/useTodoDragAndDrop';
import doodlesProud from '../../images/doodles_proud.png';
import { setSortByUrgent, setSortByDay, updateSortPreferences } from '../../store/weeklyPlannerSlice';

// Helper function to get the day order (Sunday = 0, Saturday = 6, no day = 7)
const getDayOrder = (days) => {
    if (!days || days.length === 0) return 7;
    const dayOrder = {
        'sunday': 0,
        'monday': 1,
        'tuesday': 2,
        'wednesday': 3,
        'thursday': 4,
        'friday': 5,
        'saturday': 6
    };
    return dayOrder[days[0]] ?? 7;
};

function TodoList({ 
    todos,
    source,
    onAddTodo,
    onToggleComplete,
    onToggleUrgent,
    onDelete,
    onEdit,
    onReorderTodos,
    onClearCompleted,
    customActions = [],
    allTodos = [],
    showSendAll = false,
    onSendAll = () => {},
}) {
    const dispatch = useDispatch();
    const [newTodoText, setNewTodoText] = useState('');
    const { sortByUrgent, sortByDay } = useSelector(state => state.weeklyPlanner);
    
    const {
        handleDragStart,
        handleDragOver,
        handleDrop,
        handleDragEnd,
        getDragStyles
    } = useTodoDragAndDrop({ todos, onReorderTodos });

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newTodoText.trim()) return;
        
        try {
            await onAddTodo({
                text: newTodoText,
                completed: false,
                urgent: false,
                source: source,
                location: source,
                projectId: null,
                createdAt: new Date().toISOString(),
            });
            
            setNewTodoText('');
        } catch (error) {
            console.error('Failed to add todo:', error);
            // The optimistic update will be automatically reverted by the thunk
        }
    };

    const handleSortByUrgentToggle = () => {
        dispatch(setSortByUrgent(!sortByUrgent));
        dispatch(updateSortPreferences({ sortByUrgent: !sortByUrgent, sortByDay }));
    };

    const handleSortByDayToggle = () => {
        dispatch(setSortByDay(!sortByDay));
        dispatch(updateSortPreferences({ sortByUrgent, sortByDay: !sortByDay }));
    };

    const handleDelete = async (todoId) => {
        try {
            await onDelete(todoId);
        } catch (error) {
            console.error('Failed to delete todo:', error);
            // Error handling is now done in the thunk
        }
    };

    const incompleteTodos = todos
        .filter(todo => !todo.completed)
        .sort((a, b) => {
            // First sort by urgency
            if (sortByUrgent && a.urgent !== b.urgent) {
                return b.urgent - a.urgent;
            }
            
            // Then sort by day of week if enabled and in weekly view
            if (sortByDay && source === 'weekly') {
                const aDay = getDayOrder(a.days);
                const bDay = getDayOrder(b.days);
                return aDay - bDay;
            }
            
            return 0;
        });
    const completedTodos = todos.filter(todo => todo.completed);

    return (
        <div className="space-y-4">
            {/* Add Todo Form */}
            <div className="w-full">
                <form onSubmit={handleSubmit} className="flex w-full gap-2">
                    <input
                        type="text"
                        value={newTodoText}
                        onChange={(e) => setNewTodoText(e.target.value)}
                        placeholder="Add a new task..."
                        className="flex-1 min-w-0 bg-[var(--input-bg)] border border-[var(--input-border)] focus:border-[var(--input-border-hover)] focus:outline-none rounded-md px-3 py-2"
                    />
                    <button
                        type="submit"
                        className="px-2 py-1 bg-[var(--primary-button-hover)] hover:bg-[var(--primary-button-active)] rounded-md flex items-center gap-1 whitespace-nowrap"
                        title="Add Task"
                    >
                        <span className="material-symbols-outlined text-[18px]">add_task</span>
                        Add
                    </button>
                </form>
            </div>

            {/* Todo List */}
            <div className="space-y-6">
                {/* Active Todos */}
                <div className="space-y-2">
                    <div className="flex justify-between items-center">
                        <h1 className="text-lg font-semibold text-[var(--text-muted)] text-left">Active Tasks</h1>
                        <div className="flex items-center gap-2">
                            {showSendAll && incompleteTodos.length > 0 && (
                                <button
                                    onClick={onSendAll}
                                    className="p-2 rounded-md flex items-center justify-center base-button"
                                    title="Send All Tasks"
                                >
                                    <span className="material-symbols-outlined text-[20px]">forward</span>
                                </button>
                            )}
                            {source === 'weekly' && (
                                <button
                                    onClick={handleSortByDayToggle}
                                    className={`p-1.5 rounded-md flex items-center justify-center min-w-[32px] min-h-[32px] ${
                                        sortByDay 
                                            ? 'bg-blue-800 hover:bg-blue-900 border border-blue-700' 
                                            : 'base-button'
                                    }`}
                                    title="Sort by Day"
                                >
                                    <span className="material-symbols-outlined text-[18px]">calendar_today</span>
                                </button>
                            )}
                            <button
                                onClick={handleSortByUrgentToggle}
                                className={`p-1.5 rounded-md flex items-center justify-center min-w-[32px] min-h-[32px] ${
                                    sortByUrgent 
                                        ? 'bg-red-800 hover:bg-red-900 border border-red-700' 
                                        : 'base-button'
                                }`}
                                title="Sort by Urgency"
                            >
                                <span className="material-symbols-outlined text-[18px]">priority_high</span>
                            </button>
                        </div>
                    </div>
                    {incompleteTodos.length > 0 ? (
                        incompleteTodos.map(todo => (
                            <TodoItem
                                key={todo.id}
                                todo={todo}
                                onToggleComplete={onToggleComplete}
                                onToggleUrgent={onToggleUrgent}
                                onDelete={handleDelete}
                                onEdit={onEdit}
                                onReorderTodos={onReorderTodos}
                                customActions={customActions}
                                draggable={true}
                                onDragStart={handleDragStart}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                onDragEnd={handleDragEnd}
                                dragStyles={getDragStyles(todo)}
                                allTodos={allTodos}
                            />
                        ))
                    ) : (
                        completedTodos.length > 0 ? (
                            <p className="text-[var(--text-disabled)] italic text-left flex items-center gap-2">
                                No active tasks left! Doodles is proud of you!
                                <img 
                                    src={doodlesProud} 
                                    alt="Doodles is proud" 
                                    className="w-20 h-20 object-contain"
                                />
                            </p>
                        ) : (
                            <p className="text-[var(--text-disabled)] italic text-left">No active tasks.</p>
                        )
                    )}
                </div>

                {/* Completed Todos */}
                {completedTodos.length > 0 && (
                    <div className="space-y-2 opacity-75">
                        <div className="flex justify-between items-center">
                            <h1 className="text-lg font-semibold text-[var(--text-muted)] text-left">Completed Tasks</h1>
                            <button
                                onClick={() => onClearCompleted && onClearCompleted(source)}
                                className="px-2 py-1 bg-red-800 hover:bg-red-900 border border-red-700 rounded-md flex items-center gap-1 text-sm"
                                title="Clear Completed Tasks"
                            >
                                <span className="material-symbols-outlined text-[18px]">cleaning_services</span>
                                Clear Completed
                            </button>
                        </div>
                        {completedTodos.map(todo => (
                            <TodoItem
                                key={todo.id}
                                todo={todo}
                                onToggleComplete={onToggleComplete}
                                onToggleUrgent={onToggleUrgent}
                                onDelete={onDelete}
                                onEdit={onEdit}
                                onReorderTodos={onReorderTodos}
                                customActions={customActions}
                                draggable={true}
                                onDragStart={handleDragStart}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                onDragEnd={handleDragEnd}
                                dragStyles={getDragStyles(todo)}
                                allTodos={allTodos}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default TodoList; 
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import loginLogo from '../images/login_logo.png';
import api from '../services/api';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [collapseHeader, setCollapseHeader] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await api.post('/auth/login', {
                username: username.toLowerCase(),
                password
            });

            const { token, username: loggedInUsername } = response.data;
            
            // Save to localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('username', loggedInUsername);
            localStorage.setItem('collapseHeader', collapseHeader);

            // Navigate to the home page
            navigate('/');
        } catch (error) {
            console.error('Login error:', error);
            setError(error.response?.data?.error || 'An error occurred during login');
            setIsLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-[var(--app-bg)] px-4 py-8 font-sans">
            <form onSubmit={handleSubmit} className="bg-[var(--input-bg)] p-6 sm:p-8 rounded-lg shadow-md w-full max-w-md border border-surface">
                <div className="flex flex-col items-center mb-6">
                    <img src={loginLogo} alt="Login Logo" className="w-24 sm:w-32 h-auto mb-4" />
                    <h2 className="text-xl sm:text-2xl font-bold text-[var(--text-primary)]">Login</h2>
                </div>
                {error && <p className="text-red-500 mb-4 text-sm sm:text-base">{error}</p>}
                <div className="mb-4">
                    <label htmlFor="username" className="block text-sm font-medium text-[var(--text-secondary)] mb-2 text-left">
                        Username
                    </label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="w-full px-3 py-2 bg-[var(--surface-bg-darker)] border-[var(--surface-border)] text-[var(--text-primary)] rounded-md focus:outline-none focus:ring-2 focus:ring-[var(--focus-ring)] text-sm sm:text-base border"
                        disabled={isLoading}
                        autoComplete="off"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block text-sm font-medium text-[var(--text-secondary)] mb-2 text-left">
                        Password
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full px-3 py-2 bg-[var(--surface-bg-darker)] border-[var(--surface-border)] text-[var(--text-primary)] rounded-md focus:outline-none focus:ring-2 focus:ring-[var(--focus-ring)] text-sm sm:text-base border"
                        disabled={isLoading}
                    />
                </div>
                <div className="flex items-center justify-between mb-4">
                    <label htmlFor="collapseHeader" className="text-xs sm:text-sm font-medium text-[var(--text-secondary)] mr-2">Collapse Header</label>
                    <div
                        className={`w-10 sm:w-12 h-5 sm:h-6 flex items-center rounded-full p-1 cursor-pointer border flash-button ${
                            collapseHeader ? 'bg-[var(--primary-button)] border-[var(--primary-button)]' : 'bg-[var(--input-bg)] border-[var(--border-dark)]'
                        }`}
                        onClick={() => setCollapseHeader(!collapseHeader)}
                    >
                        <div
                            className={`bg-white border border-[var(--surface-border)] w-3 sm:w-4 h-3 sm:h-4 rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${collapseHeader ? 'translate-x-5 sm:translate-x-6' : ''}`}
                        ></div>
                    </div>
                </div>
                <button
                    type="submit"
                    className="w-full p-2 bg-[var(--primary-button)] text-[var(--text-primary)] rounded-md hover:bg-[var(--primary-button-hover)] disabled:opacity-50 flex items-center justify-center focus:outline-none focus:ring-1 focus:ring-[var(--text-primary)] flash-button text-sm sm:text-base border border-[var(--primary-button)]"
                    disabled={isLoading}
                >
                    <span className="material-symbols-outlined text-lg sm:text-xl mr-1">login</span>
                    <span className="font-medium">Login</span>
                </button>
            </form>
        </div>
    );
};

export default Login;

import React from 'react';
import headerLogo from '../../images/doodles_mascot.png';
import LogoutButton from './buttons/LogoutButton';
import SettingsButton from './buttons/SettingsButton';

const getNameAlias = (username) => {
  const aliases = {
    username: 'Terry',
    gdaterry: 'Terry',
    cy090: 'Tine',
  };
  
  return aliases[username.toLowerCase()] || username.charAt(0).toUpperCase() + username.slice(1);
};

function Header({ onLogout, collapseHeader, username }) {
  return (
    <header className={`fixed top-0 left-0 right-0 z-30 ${collapseHeader ? 'h-12' : 'h-20'} transition-all duration-300`}>
      <div className={`bg-[var(--app-bg)] shadow-md ${collapseHeader ? 'p-1' : 'p-2'} h-full`}>
        <div className="container mx-auto flex items-center justify-between h-full">
          {/* Center content */}
          <div className="flex-grow flex items-center justify-center">
            {!collapseHeader && (
              <div className="flex items-center">
                <img 
                  src={headerLogo} 
                  alt="Doodles"
                  title="Doodles"
                  className="w-14 h-14 sm:w-16 sm:h-16 md:w-18 md:h-18 lg:w-20 lg:h-20 mr-2 sm:mr-3 object-contain"
                />
                <div className="flex flex-col items-center">
                  <h1 
                    className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold chewy-regular text-white"
                    style={{
                      textShadow: '2px 2px 4px rgba(0,0,0,0.8), 0 0 10px rgba(0,0,0,0.7), 1px 1px 2px rgba(0,0,0,0.9)'
                    }}
                  >
                    Bearly Functional
                  </h1>
                  <span className="text-white text-sm sm:text-base">"Hi, {getNameAlias(username)}!"</span>
                </div>
              </div>
            )}
          </div>
          
          {/* Buttons section */}
          <div className="absolute right-2 sm:right-4 md:right-6 lg:right-8 flex items-center space-x-2">
            <SettingsButton onClick={() => {/* Add your settings handler here */}} />
            <LogoutButton onLogout={onLogout} />
          </div>
        </div>
      </div>
      <div className={`bg-gradient-to-b from-[var(--app-bg)] to-transparent ${collapseHeader ? 'h-2' : 'h-4'}`}></div>
    </header>
  );
}

export default Header; 
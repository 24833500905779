import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import IconSelector from '../../Projects/IconSelector';
import { updateSetting } from '../../../store/settingsSlice';

const defaultFavoriteIcons = ['work', 'pets', 'science', 'bloodtype', 'pill', 'people', 'circle', 'family_home'];

const selectSettingsState = state => state.settings.settings;

const selectFavoriteIcons = createSelector(
    [selectSettingsState],
    (settings) => {
        return settings.favorite_icons ? [...settings.favorite_icons] : [...defaultFavoriteIcons];
    }
);

const ProjectIcons = () => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const favoriteIcons = useSelector(selectFavoriteIcons);

    const handleOpenIconSelector = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleCloseIconSelector = () => {
        setAnchorEl(null);
    };

    const handleAddIcon = async (icon) => {
        if (favoriteIcons.includes(icon)) return;
        
        const newFavorites = [...favoriteIcons, icon];
        
        // Optimistically update the UI
        dispatch(updateSetting({ 
            key: 'favorite_icons', 
            value: newFavorites,
            // Skip the API call for now
            skipPersist: true 
        }));

        try {
            // Persist the change
            await dispatch(updateSetting({ 
                key: 'favorite_icons', 
                value: newFavorites 
            })).unwrap();
        } catch (error) {
            console.error('Failed to update favorite icons:', error);
            // Revert to original state on error
            dispatch(updateSetting({ 
                key: 'favorite_icons', 
                value: favoriteIcons,
                skipPersist: true 
            }));
        }
    };

    const handleRemoveIcon = async (iconToRemove) => {
        const newFavorites = favoriteIcons.filter(icon => icon !== iconToRemove);
        
        // Optimistically update the UI
        dispatch(updateSetting({ 
            key: 'favorite_icons', 
            value: newFavorites,
            skipPersist: true 
        }));

        try {
            // Persist the change
            await dispatch(updateSetting({ 
                key: 'favorite_icons', 
                value: newFavorites 
            })).unwrap();
        } catch (error) {
            console.error('Failed to update favorite icons:', error);
            // Revert to original state on error
            dispatch(updateSetting({ 
                key: 'favorite_icons', 
                value: favoriteIcons,
                skipPersist: true 
            }));
        }
    };

    return (
        <div className="p-4 sm:p-6 space-y-6">
            <div className="space-y-4 border border-[var(--surface-border)] rounded-lg p-3 sm:p-4">
                <h4 className="text-sm font-bold text-[var(--text-primary)] text-left">Favorite Project Icons</h4>
                <p className="text-sm text-[var(--text-secondary)]">
                    Customize your favorite icons for quick access when creating projects.
                </p>
                
                <div className="grid grid-cols-5 sm:grid-cols-8 gap-2">
                    {favoriteIcons.map((icon) => (
                        <div
                            key={icon}
                            className="relative group"
                        >
                            <button
                                className="w-full p-2 rounded-md bg-[var(--surface-bg-darker)] hover:bg-[var(--button-hover-bg)] flex items-center justify-center"
                                title={icon}
                            >
                                <span className="material-symbols-outlined text-[20px]">{icon}</span>
                            </button>
                            <button
                                onClick={() => handleRemoveIcon(icon)}
                                className="absolute -top-2 -right-2 w-5 h-5 rounded-full 
                                    bg-[var(--surface-bg)] hover:bg-[var(--surface-bg-darker)]
                                    hover:text-[var(--error)] text-[var(--text-secondary)] 
                                    opacity-0 group-hover:opacity-100 transition-opacity
                                    flex items-center justify-center
                                    border border-[var(--surface-border)]"
                                title="Remove icon"
                            >
                                <span className="material-symbols-outlined text-[12px]">close</span>
                            </button>
                        </div>
                    ))}
                    <div className="relative">
                        <button
                            onClick={handleOpenIconSelector}
                            className="w-full p-2 rounded-md bg-[var(--surface-bg-darker)] hover:bg-[var(--button-hover-bg)] flex items-center justify-center"
                            title="Add new favorite icon"
                            type="button"
                        >
                            <span className="material-symbols-outlined text-[20px]">add</span>
                        </button>
                    </div>
                </div>
            </div>

            <IconSelector
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseIconSelector}
                onSelect={handleAddIcon}
                currentIcon={null}
            />
        </div>
    );
};

export default ProjectIcons; 